import {
  Divider,
  InputDate,
  Button,
} from "../../../components";
import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
  Select,
} from "native-base";
import { useNavigatorContext, useSupplierContext } from "../../../contexts";

import { Modal, Pressable, StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { formatDate, platformWeb } from "../../../utils";
import { useContext, useEffect, useState } from "react";
import homeScreenHooks from "./../../../screens/Home/hooks/homeScreen";
import moment from "moment";
import { useGlobalContext } from "./../../../contexts/globalContext";
import managementHook from "../hook/useAssetManagement";


export const Content = () => {
  const { colors } = useTheme();
  const { navigateTo } = useNavigatorContext();
  const [status, setStatus] = useState<string>('');
  const { currentSupplier } = useSupplierContext();
  const { getListManagement, _managementList, postHeaderManagement } = managementHook();
  const { setManagementHeaderCDate, _flatListManagement } = useGlobalContext()

  const [solicitando, setSolicitando] = useState<boolean>(true)
  const [modalVisible, setModalVisible] = useState(false);
  const [_movimentFiltered, _setMovimentFiltered] = useState();

  useEffect(() => {
    getListManagement(currentSupplier)
  }, [currentSupplier, _flatListManagement])


  function handleRedirectPage(item: any) {
    if (item.status.includes("Enviada")) {
      setManagementHeaderCDate(item)
      return navigateTo("ManagementApprovedScreen");
    } else if (item.status.includes("Aprovada")) {
      setManagementHeaderCDate(item)
      return navigateTo("ManagementApprovedScreen");
    } else if (item.status.includes("Parada")) {
      setManagementHeaderCDate(item)
      return navigateTo("ManagementApprovedScreen");
    } else if (item.status.includes("Em preenchimento")) {
      setManagementHeaderCDate(item)
      return navigateTo("ManagementApprovedScreen");
    } else if (item.status.includes("Realizada")) {
      setManagementHeaderCDate(item)
      return navigateTo("ManagementApprovedScreen");
    }
  }
  /*
    function solicitar() {
      if (solicitando === false) {
        setModalVisible(true)
        //setSolicitando(true)
        //console.log('ir para próxima página')
        //navigateTo("RetoolingItemsScreen")
      }
      else {
        setModalVisible(true)
      }
    }
  */
  function requestMoviment() {
    if (_managementList.some(item => item.communication_order_header_status_id === 1)) {
      setModalVisible(true)
    } else {
      postHeaderManagement(currentSupplier)
    }
  }

  function modalManter() {
    setModalVisible(false)

  }

  function modalCriar() {
    setModalVisible(false)
    postHeaderManagement(currentSupplier)
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, maxW: "100%" }} bg="#fff">

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          _web={{ w: "100%" }}
        >
          <Box minWidth={20} _web={{ w: 100 }}>
            <Text color={colors.white}>Data</Text>
          </Box>
          <Box minWidth={160} _web={{ w: 60 }}>
            <Text color={colors.white}>Status</Text>
          </Box>
          <Box minWidth={38} _web={{ w: 100 }}>
            <Text color={colors.white}>Qtd</Text>
          </Box>
          <Box minWidth={35} _web={{ w: 100 }}>
            <Text color={colors.white}>Id</Text>
          </Box>
        </Stack>
        <FlatList
          extraData={_flatListManagement}
          nestedScrollEnabled
          data={_managementList}
          contentContainerStyle={{
            marginBottom: !platformWeb ? 100 : 10,
          }}
          renderItem={({ item, index }) => {
            return (
              <Pressable onPress={() => handleRedirectPage(item)}>
                <Box px={4} py={2} _web={{ px: 1 }}>
                  <VStack direction="row" space={2}>
                    <Box minWidth={20} _web={{ w: 110 }}>
                      <Text>{formatDate(item?.creation_date)}</Text>
                    </Box>
                    <Box minWidth={160} _web={{ w: 640 }}>
                      <Text>{item?.status}</Text>
                    </Box>
                    <Box minWidth={30} _web={{ w: 90 }}>
                      <Text>{item?.qty}</Text>
                    </Box>
                    <Box minWidth={45} _web={{ w: 90 }}>
                      <Text>{item?.id}</Text>
                    </Box>
                  </VStack>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                </Box>
              </Pressable>
            );
          }}
        />

        <Stack px={10} py={2}>
          <Button
            label="CRIAR COMUNICAÇÃO"
            onPress={() => requestMoviment()}
          />
        </Stack>
      </VStack>


      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Já existe uma solicitação</Text>
            <Text style={styles.modalText}>"EM PREENCHIMENTO".</Text>
            <Text style={styles.modalText}>Deseja criar uma nova?</Text>
            <View style={styles.buttons}>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => modalManter()}>
                <Text style={styles.textStyle}>Manter Atual</Text>
              </Pressable>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => modalCriar()}>
                <Text style={styles.textStyle}>Criar Nova</Text>
              </Pressable>
            </View>

          </View>
        </View>
      </Modal>

    </>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    paddingLeft: 20,
    paddingRight: 20,
    padding: 10,
    elevation: 2,
    marginLeft: 10,
    marginRight: 10,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  }
});