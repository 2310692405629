import { useContext, useState } from "react";
import { getRetoolingHeaderStatus, getRetoolingList, getRetoolingSelectItens, postRetoolingHeader } from "./../../../services/retoolingService";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { useNavigatorContext } from "../../../contexts";

export default function retoolingHook() {
  const { addRetoolingSelectItens, _setRetoolingHeaderId, setHeaderCDate } = useGlobalContext();
  const { navigateTo } = useNavigatorContext();
  const [isLoading, setIsLoading] = useState(true);
  const [_headerStatus, _setHeaderStatus] = useState([])
  const [_retoolingList, _setRetoolingList] = useState([])


  async function getHeaderStatusRetooling() {
    const data = await getRetoolingHeaderStatus();
    if (data?.status) {
      _setHeaderStatus(data.result)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getListRetooling(supplier_id: number,) {
    const data = await getRetoolingList(supplier_id);
    if (data?.status) {
      _setRetoolingList(data.result)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function postHeaderRetooling(supplier_id: number,) {
    const data = await postRetoolingHeader(supplier_id);
    if (data?.status) {
      console.log('header', data.result)
      setHeaderCDate({ "creation_date": (new Date()), "id": data.result.retoolingHeaderId, "status": "Em Preenchimento" })
      _setRetoolingHeaderId(data.result)
      getSelectItensRetooling(supplier_id)
      navigateTo("RetoolingApprovedScreen");
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }


  async function getSelectItensRetooling(supplier_id: number,) {
    const data = await getRetoolingSelectItens(supplier_id);
    if (data?.status) {
      addRetoolingSelectItens(data.result)
      navigateTo('RetoolingItemsScreen')
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }



  return {
    getHeaderStatusRetooling,
    _headerStatus,
    getListRetooling,
    _retoolingList,
    postHeaderRetooling,
    getSelectItensRetooling,
  }
}