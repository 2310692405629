import { createContext, useContext, useEffect, useState } from "react";
import { getInventoryService } from "../services/inventoryService";
import { useSupplierContext } from "./supplierContext";
interface IInventoryContext {
  inventoryData: any;
  chartData: any;
  searchTerm: string;
  showAlert: boolean;
  setShowAlert: (showAlert: boolean) => void;
  updateSearchTerm: (term: string) => void;
  updateInventoryData: () => void;
}

const InventoryContext = createContext({} as IInventoryContext);

const InventoryContextProvider = ({ children }: any) => {
  const [inventoryData, setInventoryData] = useState<any>();
  const [searchTerm, setSearchTerm] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);
  const { currentSupplier } = useSupplierContext();
  const chartData = [
    {
      quantity: 100 - (inventoryData?.done / inventoryData?.total) * 100,
      color: "#F00",
    },
    {
      quantity: (inventoryData?.done / inventoryData?.total) * 100,
      color: "#00B050",
    },
  ];

  function updateInventoryData() {
    setUpdateCounter(updateCounter + 1);
  }

  function updateSearchTerm(term: string) {
    setSearchTerm(term);
  }

  useEffect(() => {
    async function getInventoryData() {
      const supplier_id = currentSupplier;
      try {
        const data = await getInventoryService(supplier_id);
        if (data?.status) setInventoryData(data?.result);
      } catch (error: any) {
        console.error(error);
        return [];
      }
    }
    getInventoryData();
  }, [currentSupplier, searchTerm, updateCounter]);

  return (
    <InventoryContext.Provider
      value={{
        inventoryData,
        chartData,
        searchTerm,
        showAlert,
        setShowAlert,
        updateInventoryData,
        updateSearchTerm,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};

const useInventoryContext = () => {
  return useContext(InventoryContext);
};

export { InventoryContextProvider, useInventoryContext };
