import { InputDate, Button, TextInputSearch } from "../../../.././../components";
import { Text, Stack, Box, Select } from "native-base";
import { useEffect, useState } from "react";
import Spinner from "react-native-loading-spinner-overlay";

export interface IHeaderStatusItem {
    id: string;
    name: string;
}

export const SearchAssetHistory = ({ handleSearch }) => {
    const [asset_number, setAssetNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <Stack
                direction="row"
                space={1}
                alignItems="center"
                px={1}
                py={1}
                _web={{ pt: 20, pb: 5, w: "100%", justifyContent: "space-around" }}
            >

                <Stack direction="row" w={"full"}>
                    <Stack direction="row" w={'full'} space={1} alignItems="center">
                        <TextInputSearch
                            value={asset_number}
                            onChangeText={setAssetNumber}
                            placeholder="Digite o número do ativo"
                        />

                        <Box>
                            <Button
                                label="Pesquisar"
                                onPress={() => handleSearch(asset_number)}
                            />
                        </Box>
                    </Stack>
                </Stack>

            </Stack>
        </>
    );
};
