import { platformAndroid, platformWidth, platformWeb } from "../../utils";
import { Box, Center, FlatList, Stack, Text, useTheme } from "native-base";
import { PieChart as Chart } from "react-native-chart-kit";
import { Dimensions } from "react-native";
import { ResponsiveValue } from "native-base/lib/typescript/components/types";

const screenWidth = Dimensions.get('window').width;
const chartWidth = platformAndroid ? screenWidth * 0.40 : screenWidth * 0.30;

type DataType = {
  name?: string;
  quantity: number;
  percentage: number | string;
  color: string;
};
interface IPieChart {
  data: DataType[];
  title?: string;
  border?: boolean;
  accessor: string;
  justifyContent?: string;
  flexDirection?: ResponsiveValue<"row" | "column" | "row-reverse" | "column-reverse" | undefined>;
}
export const PieChart = ({
  data,
  border,
  title,
  accessor,
  justifyContent,
  flexDirection,
  ...props
}: IPieChart) => {
  const { colors } = useTheme();
  return (
    <>
      <Box py={3}>
        <Text textAlign={'center'} fontSize={16} fontWeight={'bold'} textTransform={'uppercase'}>{title}</Text>
      </Box>
      <Stack flex={1} justifyContent={'center'} >

        <Stack
          flexDirection={flexDirection || 'row'}
          justifyContent={'space-evenly'}
          alignItems="center"
          w={'full'}
          maxW={platformWidth}
          style={
            border && {
              borderColor: colors.success[50],
              borderWidth: 1,
              borderRadius: 10,
            }
          }
        >

          <Box flex={1} >
            <Chart
              data={data}
              chartConfig={{
                decimalPlaces: 2,
                color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
              }}
              style={{
                backgroundColor: "white",
              }}
              width={chartWidth}
              backgroundColor="white"
              height={platformAndroid ? 170 : 300}
              paddingLeft={platformAndroid ? "10" : "5"}
              center={[30, 0]}
              hasLegend={false}
              accessor={accessor}
              absolute
              {...props}
            />
          </Box>

          <Box w={'50%'} maxW={'50%'} flex={1}  >
            <Center>
              <FlatList
                nestedScrollEnabled
                data={data}
                renderItem={({ item }) => (
                  <Stack flexDirection="row" alignItems="center" py={2} ml={2}>
                    <Box w={4} h={4} bg={item?.color} />
                    <Text fontSize={platformAndroid ? 12 : 14}
                      ml={2}
                      w={platformWeb ? '100%' : 120}
                      flexWrap="wrap">
                      {`${item?.percentage}% ${item?.name}`}
                    </Text>
                  </Stack>
                )}
              />
            </Center>
          </Box>
        </Stack>
      </Stack >
    </>
  );
};
