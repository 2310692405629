import {
  Inventariar,
  Movimentacao,
  Desinvestimento,
  Retooling,
  GestaoDeAtivos,
  Notificacoes,
  Dashboard,
  HistoricoAtivo,
  HistoricoFornecedor,
  Ativos,
  Sair,
  Home,
} from "../../assets/images";

export const menuOptions = [
  {
    label: "Inventariar",
    icon: Inventariar,
    screenName: "InventoryScreen",
  },
  {
    label: "Solicitar Movimentação",
    icon: Movimentacao,
    screenName: "MovimentScreen",
  },
  {
    label: "Solicitar Desinvestimento",
    icon: Desinvestimento,
    screenName: "DivestmentScreen",
  },
  {
    label: "Solicitar Retooling",
    icon: Retooling,
    screenName: "RetoolingScreen",
  },
  {
    label: "Comunicação",
    icon: GestaoDeAtivos,
    screenName: "CommunicationScreen",
  },
  {
    label: "Notificações",
    icon: Notificacoes,
    screenName: "Notifications",
  },
  {
    label: "Relatórios",
    icon: Dashboard,
    screenName: "ReportsScreen",
    webOnly: true,
  },
  {
    label: "Meu Dashboard",
    icon: Dashboard,
    screenName: "Dashboard",
  },
  {
    label: "Histórico do Ativo",
    icon: HistoricoAtivo,
    screenName: "AssetHistory",
  },
  {
    label: "Histórico do Fornecedor",
    icon: HistoricoFornecedor,
    screenName: "SupplierHistory",
  },
  {
    label: "Lista de Ativos / Cadastros",
    icon: Ativos,
    screenName: "ActiveDrawer",
  },
  {
    label: "Sair (Log Off)",
    icon: Sair,
    screenName: "Logoff",
  },
];

export const menuSecondaryOptions = [
  {
    label: "Retooling - Home",
    icon: Retooling,
    screenName: "RetoolingScreen",
  },
  {
    label: "Retooling - Selecionar Itens",
    icon: null,
    screenName: "RetoolingItemsScreen",
  },
  {
    label: "Retooling - Selecionar Itens",
    icon: null,
    screenName: "RetoolingItemsReviewScreen",
  },
  {
    label: "Retooling - Detalhe do Item",
    icon: null,
    screenName: "RetoolingDetailsScreen",
  },
  {
    label: "Retooling - Consulta Itens Selecionados - A",
    icon: null,
    screenName: "RetoolingApprovedScreen",
  },
  {
    label: "Retooling - Consulta Itens Selecionados - P",
    icon: null,
    screenName: "RetoolingInProgressScreen",
  },
  {
    label: "Digitar Código",
    icon: null,
    screenName: "EnterCodeScreen",
  },
  {
    label: "Encerramento do Inventário",
    icon: null,
    screenName: "InventoryClosureScreen",
  },
  {
    label: "Detalhamento do CAF",
    icon: null,
    screenName: "ActivesDetailsScreen",
  },
  {
    label: "Lista de Part Numbers",
    icon: null,
    screenName: "PartNumbers",
  },
  {
    label: "Desinvestimento - Selecionar Itens",
    icon: null,
    screenName: "DivestmentItems",
  },
  {
    label: "Desinvest. - Consulta Itens Selecionados I",
    icon: null,
    screenName: "DivestmentInProgress",
  },
  {
    label: "Desinvest. - Consulta Itens Selecionados A",
    icon: null,
    screenName: "DivestmentApprovedScreen",
  },
  {
    label: "Desinvest. - Detalhes dos Itens",
    icon: null,
    screenName: "DivestmentDetailsScreen",
  },
  {
    label: "Desinvest. - Consulta Itens Selecionados E",
    icon: null,
    screenName: "DivestmentPendingScreen",
  },
  {
    label: "Desinvest. - Consulta Itens Selecionados R",
    icon: null,
    screenName: "DivestmentSummary",
  },
  {
    label: "Movimentação - Home",
    icon: null,
    screenName: "MovimentScreen",
  },
  {
    label: "Movimentação - Selecionar Itens",
    icon: null,
    screenName: "MovimentInProgress",
  },
  {
    label: "Movimentação - Selecionar Itens I",
    icon: null,
    screenName: "MovimentInProgressScreen",
  },
  {
    label: "Movimentação - Selecionar Itens A",
    icon: null,
    screenName: "MovimentApprovedScreen",
  },
  {
    label: "Movimentação - Sumário",
    icon: null,
    screenName: "MovimentSummary",
  },
];
