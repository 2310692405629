import { api } from "./api";

export interface ISupplierItem {
  supplier_id: string,
  name: string
}

interface IGetSupplierResponse {
  status: boolean,
  result: ISupplierItem[],
}

export const getSuppliersService = async (): Promise<IGetSupplierResponse> => {
  return api
    .get(`/v1/suppliers_user`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
