import { Box, Icon, Image, Stack, Text, useTheme } from "native-base";
import { LogoHeader } from "../../../assets/images";
import { FontAwesome } from "@expo/vector-icons";
import { Platform, Pressable } from "react-native";
import { DrawerActions } from "@react-navigation/routers";
import { platformWeb } from "../../utils/platformTypes";
import { DrawerHeaderProps } from "@react-navigation/drawer";
import { useAuthContext } from "../../contexts";
import { useState } from "react";
import { NotificationModal } from "../NotificationModal";


const mockNotifications = [
  { message: 'Nova notificação 1' },
  { message: 'Nova notificação 2' },
];
export const HeaderApp = ({ navigation, route }: DrawerHeaderProps) => {
  const { colors } = useTheme();
  const { accountType } = useAuthContext();
  const { loggedUsername } = useAuthContext();
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  return (
    <Stack
      direction="row"
      px={5}
      // mt={Platform.OS === "web" ? 0 : 7}
      pt={5}
      pb={5}
      space={3}
      bg={colors.primary[50]}
      alignItems="center"
      justifyContent="space-between"
    >
      {!platformWeb ? (
        <Pressable
          onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        >
          <Icon as={FontAwesome} name="bars" color={colors.white} size={8} />
        </Pressable>
      ) : null}

      <Box style={{ flex: 1, justifyContent: "center" }}>
        <Image
          source={LogoHeader}
          alt={"Alt Image"}
          w={Platform.OS === "web" ? 300 : "100%"}
          h={35}
        />
        <Text color={colors.white}>{route.name}-{accountType}</Text>
      </Box>

      {/* <Pressable onPress={() => setNotificationModalOpen(true)}>
        <Icon as={FontAwesome} name="bell" color={colors.white} size={8} />
      </Pressable>

      <NotificationModal
        visible={notificationModalOpen}
        onClose={() => setNotificationModalOpen(false)}
        notifications={mockNotifications}
      /> */}
    </Stack>
  );
};
