import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  Center,
  FlatList,
  Checkbox,
  View,
  ScrollView,
  Icon,
  Image,
  Select,
} from "native-base";
import {
  Divider,
  Button,
  TextInputSearch,
  TextInput,
  InputDate,
} from "../../../components";
import { useAuthContext, useFormContext, useNavigatorContext } from "../../../contexts";

import { useContext, useEffect, useState } from "react";
import { platformWeb } from "../../../utils";

import { FontAwesome } from "@expo/vector-icons";
import { Pressable } from "react-native";
import { postMovimentRecDocuments, putMovimentRec, putMovimentRecDocuments } from "./../../../services/movimentService";
import * as DocumentPicker from 'expo-document-picker';
//import MovimentSummaryHook from "./hook/useMovimentSummary";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { Union } from "./../../../../assets/images";
import ManagementSummaryHook from "../hook/useManagementSummary";
import { postManagementRecDocuments, putManagementRecDocuments } from "./../../../services/managementService";

export const Content = () => {
  const { accountType } = useAuthContext();
  const { colors } = useTheme();
  const { navigateTo } = useNavigatorContext();
  const [_sublocado, _setSublocado] = useState<boolean>(false)
  const [_sublocado2, _setSublocado2] = useState<boolean>(false)
  const [_image, _setImage] = useState(null);
  const [ativarBotao, setAtivarBotao] = useState<boolean>(true);
  const [files, set_files] = useState<string>('');
  //const {_sendRecSummary} = MovimentSummaryHook();
  const { _managementHeaderCDate } = useGlobalContext();
  const { getSummarySelectManagement, summarySelect, _sendRecSummary } = ManagementSummaryHook()
  const [_summarySelect, _setSummarySelect] = useState()
  const [_description, _setDescription] = useState()

  const uploadFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === 'success') {
      recDoc(result)
    }
  }

  useEffect(() => {
    getSummarySelectManagement()
  }, [])


  async function recDoc(file: any) {
    const data = await postManagementRecDocuments(file.name);
    if (data?.status) {
      sendRec(data.result, file.uri)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  async function sendRec(url: string, file: any) {
    const data = await putManagementRecDocuments(url, file);
    if (data?.status) {
      const _indexOf = url.indexOf('?')
      const urlDiminuida = url.substring(0, _indexOf)
      console.log(urlDiminuida)
      set_files(urlDiminuida)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  function finishSummary() {
    const data = {
      communication_order_header_status_id: 2,
      communication_order_header_classification_id: _summarySelect,
      message: _description,
      file: files,
    }
    _sendRecSummary(_managementHeaderCDate.id, data)
    navigateTo('AssetManagementScreen')
  }

  function updateManagementHeader(statusId: number) {
    const data = {
      communication_order_header_status_id: statusId,
    }
    _sendRecSummary(_managementHeaderCDate.id, data)
    navigateTo('AssetManagementScreen')
  }

  return (
    <>
      <View flex={1} _web={{ px: 10, w: "100%", mt: 50 }} bg="#fff">
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
        >
          <Text color='#fff'>Área:</Text>
          <Select
            selectedValue={_summarySelect}
            minWidth="150"
            placeholder="Todos"
            _selectedItem={{
              bg: "teal.600",
            }}
            color='#fff'
            isDisabled={_managementHeaderCDate.status === "Em preenchimento" ? false : true}
            onValueChange={(itemValue) => _setSummarySelect(itemValue)}
          >
            {summarySelect && summarySelect.map(item => (
              <Select.Item key={item.id} label={item.name} value={item.id} />
            ))}
          </Select>
        </Stack>
        <ScrollView>

          <Stack direction="column" justifyContent="space-between" p={2} space={1} >
            <Box w="45%">
              <Text>Descrição: </Text>
            </Box>
            <Box w="100%">
              <TextInput isDisabled={_managementHeaderCDate.status === "Em preenchimento" ? false : true} multiline={true} fontSize={'13px'} textAlign={'left'} textAlignVertical={'top'} h={300} onChangeText={_setDescription} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>

            <Box w="45%">
              <Text>Anexo: </Text>
            </Box>
            {files ? <Image
              source={Union}
              alt="Update provider icon"
              resizeMode="contain"
              size='15px'
            /> : ''}
            <Box>
              {_managementHeaderCDate.status === "Em preenchimento" ? (
                <Pressable onPress={uploadFile}>
                  <Icon
                    as={FontAwesome}
                    name="pencil-square-o"
                    size={8}
                    color={colors.success[50]}
                  />
                </Pressable>
              ) : (
                <View>
                  <Icon
                    as={FontAwesome}
                    name="pencil-square-o"
                    size={8}
                    color={colors.success[50]}
                  />
                </View>
              )}

            </Box>


          </Stack>
          <Divider />

        </ScrollView>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-between" }}
        >
          {
            accountType === "ga" && _managementHeaderCDate.status === "Enviada" ? (
              <>
                <Button
                  _web={{ w: 500 }}
                  w="48%"
                  label="CANCELAR"
                  onPress={() => navigateTo('ManagementApprovedScreen')}
                />
                <Button
                  _web={{ w: 400 }}
                  w="48%"
                  label="Marcar como lida"
                  onPress={() => updateManagementHeader(4)}
                />
              </>
            ) : _managementHeaderCDate.status === "Em Preenchimento" ? (
              <>
                <Button
                  _web={{ w: 500 }}
                  w="48%"
                  label="CANCELAR"
                  onPress={() => navigateTo('ManagementApprovedScreen')}
                />
                <Button
                  _web={{ w: 500 }}
                  w="48%"
                  label="COMUNICAR"
                  onPress={() => finishSummary()}
                />
              </>
            ) : (
              <>
                <Button
                  _web={{ w: 500 }}
                  w="48%"
                  label="CANCELAR"
                  onPress={() => navigateTo('ManagementApprovedScreen')}
                />
                <Button
                  _web={{ w: 500 }}
                  w="48%"
                  label="COMUNICAR"
                  isDisabled
                />
              </>
            )
          }
        </Stack>
      </View>
    </>
  );
};

