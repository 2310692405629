import {
  Button,
  CardItems,
  Divider,
  Loader,
  TextInputSearch,
} from "../../../../components";
import { Box, Center, Image, Stack, Text, View } from "native-base";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useFormContext, useInventoryContext } from "../../../../contexts";
import { CafNotFound, CancelRight, Disinvestment, Duplicated, NotIsBase, Third, Transfer } from "../../../../../assets/images";
import { ImageSourcePropType, Modal, Pressable, StyleSheet, VirtualizedList } from "react-native";
import inventoryHook from "../../hook/useInventory";
import { putInventoryItemService } from "../../../../services/inventoryService";
import { DrawerItem } from "@react-navigation/drawer";
import homeScreenHooks from "./../../../../screens/Home/hooks/homeScreen";
import Spinner from 'react-native-loading-spinner-overlay';


interface IListProps {
  caf: string;
  name: string;
  status: string;
  inventoryId: number;
  image?: ImageSourcePropType;
  asset_number: string;
  asset_description: string;
  id: number;
}

export const Analysis = ({ _mudou, _flatListInventory }) => {
  const drawerOptions = [
    {
      label: "CAF não localizado",
      icon: CafNotFound,
      screenName: null,
    },
    {
      label: "CAF não está na base",
      icon: NotIsBase,
      screenName: null,
    },
    {
      label: "CAF duplicado",
      icon: Duplicated,
      screenName: null,
    },
    {
      label: "CAF Terceirizado",
      icon: Third,
      screenName: null,
    },
    {
      label: "Desinvestido no Ano",
      icon: Disinvestment,
      screenName: null,
    },
    {
      label: "Comodatário Transferido",
      icon: Transfer,
      screenName: null,
    },
  ];

  const { setValue } = useFormContext();
  const { filteredAnalysisData, getInventoryAnalysisData, inventoryData, attStatus, isLoading } = inventoryHook();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [paramsApi, setParamsApi] = useState<number>(0)
  const { getInventoryData } = useInventoryContext();
  const { filteredProvider } = homeScreenHooks();



  useEffect(() => {
    attStatus();
  }, [_mudou, _flatListInventory])

  useEffect(() => {
    attStatus();
  }, [])


  function openModal(id: number) {
    setModalVisible(true)
    setParamsApi(id)
  }

  async function putApi(id: number, func: number) {
    const data = await putInventoryItemService(id, (func + 3));
    if (data?.status) {
      attStatus()
      setModalVisible(false)
      getInventoryData(filteredProvider.company_id, filteredProvider.supplier_id)
    }
  }

  return (
    <View
      style={{
        backgroundColor: "#fff",
      }}
      flex={1}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        space={1}
        px={1}
        my={2}
      >
        <TextInputSearch
          placeholder="Pesquisar"
          onChangeText={(value: string) =>
            setValue("searchAnalysis", value?.toUpperCase())
          }
        />
      </Stack>
      <Divider />

      {inventoryData !== null ? (
        <VirtualizedList
          data={filteredAnalysisData}
          initialNumToRender={4}
          getItemCount={(data: IListProps[]) => data?.length}
          getItem={(data, index) => data[index]}
          keyExtractor={(item: IListProps) => item?.caf}
          renderItem={({ item }) => (
            <>
              <Box px={4} py={2}>
                <CardItems {...item} tab='Analysis' openModal={() => openModal(item.id)} />
              </Box>
              <Divider />
            </>
          )}
        />
      ) : (
        <Center flex={1}>
          <Text fontWeight="bold">
            Nenhuma informação de inventário encontrada!
          </Text>
        </Center>
      )}


      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
        <Pressable style={styles.container} onPress={() => setModalVisible(false)}>
          <Pressable style={styles.subcontainer} onPress={() => { }}>
            {drawerOptions?.map((item, index) => (
              <DrawerItem
                key={index}
                label={() => (
                  <Text fontWeight="bold" fontSize={13}>
                    {item?.label}
                  </Text>
                )}
                icon={() => (
                  <Image
                    source={item?.icon}
                    alt="Update provider icon"
                    resizeMode="center"
                    w={30}
                    h={33}
                  />
                )}
                style={{
                  marginTop: -10,
                  marginRight: -25,
                }}
                onPress={() => putApi(paramsApi, index)}
              />
            ))}
          </Pressable>
        </Pressable>
      </Modal>
      <Spinner visible={isLoading} />
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  subcontainer: {
    width: 290,
    height: 340,
    backgroundColor: '#BFBFFF',
    display: 'flex',
    justifyContent: 'center',
    borderBottomLeftRadius: 22,
    borderTopLeftRadius: 22,
  },
  subcontainerinfos: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 30,
    alignItems: 'center'
  },
  subcontainertext: {
    marginLeft: 9,
  },
  subtext: {
    fontWeight: '600',
    fontSize: 13,
  }
})