import { Divider, Button, InputDate } from "../../../../components";
import { Text, VStack, Stack, Box, Icon, useTheme, Pressable, Select, View, Switch, Input } from "native-base";
import { FontAwesome } from "@expo/vector-icons";
import { TextInput } from "../../../../components/TextInput/index";
import { useEffect, useState } from "react";
import { getMovementHeader, postMovimentRecDocuments, putMovimentRecDocuments, putMovimentRec, getMovimentRecDocuments } from "./../../../../services/movimentService";
import * as DocumentPicker from "expo-document-picker";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import Spinner from "react-native-loading-spinner-overlay";
import moment from "moment";
import { useAuthContext } from "../../../../contexts";
import { Linking, StyleSheet } from "react-native";
import { getSuppliersService } from "../../../../services/suppliersService";

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase> }) => {
  const { colors } = useTheme();
  const { accountType } = useAuthContext();
  const { headerId } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState<any>();
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [_image, _setImage] = useState(null);
  const [do_not_communicate_before_date, setDoNotCommunicateBeforeDate] = useState<string>("");
  const [expected_return_date, setExpectedReturnDate] = useState<string>("");
  const [files, setFiles] = useState<any[]>([]);

  const options = [
    {
      label: "Troca de Fornecedor",
      value: "Troca de Fornecedor",
    },
    {
      label: "Troca de Localidade",
      value: "Troca de Localidade",
    },
    {
      label: "Retooling",
      value: "Retooling",
    },
    {
      label: "Manutenção Corretiva",
      value: "Manutenção Corretiva",
    },
    {
      label: "Manutenção Preventiva",
      value: "Manutenção Preventiva",
    },
    {
      label: "Modificação",
      value: "Modificação",
    },
    {
      label: "Insourcing",
      value: "Insourcing",
    },
    {
      label: "Outros, detalhado em Justificativa",
      value: "Outros, detalhado em Justificativa",
    },
  ];

  async function getItemDetail() {
    setIsLoading(true);
    if (headerId) {
      const data = await getMovementHeader(headerId);
      if (data?.status) {
        setItemDetails(data.result);

        if (data.result.do_not_communicate_before_date) {
          setDoNotCommunicateBeforeDate(
            moment(data.result.do_not_communicate_before_date)
              .utcOffset(0)
              .format("DD/MM/YYYY")
          );
        }
        if (data.result.expected_return_date) {
          setExpectedReturnDate(
            moment(data.result.expected_return_date)
              .utcOffset(0)
              .format("DD/MM/YYYY")
          );
        }
        if (route.params?.status !== "Em Preenchimento") {
          const res = await getMovimentRecDocuments(headerId);
          if (res?.status) {
            setItemDetails({
              ...data.result,
              files: [res?.result],
            });
          }
        }
      }
    }
    setIsLoading(false);
  }

  async function getSupplierList() {
    const data = await getSuppliersService();
    if (data?.status) setSupplierList(data?.result);
  }

  useEffect(() => {
    getItemDetail();
    getSupplierList();
  }, [route.params]);

  async function updateMovementHeader(statusId: number) {
    const data = {
      movement_order_header_status_id: statusId,
    };
    await putMovimentRec(itemDetails.id, data);

    navigation.navigate("MovementItemListScreen", {
      headerId,
    });
  }

  async function saveDetail() {
    setIsLoading(true);
    const data = {
      ...itemDetails,
      movement_order_header_status_id: 2,
      do_not_communicate_before_date: moment(
        do_not_communicate_before_date,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD"),
      expected_return_date: moment(expected_return_date, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
    };
    delete data["completion_date"];
    delete data["creation_date"];
    delete data["id"];
    delete data["supplier_id"];
    delete data["status"];
    if (files.length) {
      await postMovimentRecDocuments(itemDetails.id, files[0].name)
        .then(async (res: any) => {
          const url = res.result;
          await putMovimentRecDocuments(url, files[0]);
          const indexOf = url.indexOf("?");
          const reducedUrl = url.substring(0, indexOf);
          data.files = [reducedUrl];
        })
        .catch((err: any) => console.log(err));
    }
    putMovimentRec(itemDetails.id, data)
      .catch((error: any) => console.log(error))
      .finally(() => {
        setIsLoading(false);
        navigation.navigate("MovementItemListScreen", {
          headerId,
        });
      });
  }

  const uploadFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === "success") {
      const file = {
        ...result,
        name:
          itemDetails.id +
          "-" +
          moment().format("YYYYMMDDHmmss") +
          "_" +
          result.name,
      };
      // const concat = files.concat([file])
      setFiles([file]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles([]);
  };

  const downloadFile = (fileUrl: string) => {
    Linking.openURL(fileUrl).catch((error) => {
      console.error("Error opening URL:", error);
    });
  };

  return (
    <>
      <View flex={1} _web={{ px: 10, w: "100%", mt: 50 }} bg="#fff">

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="39%">
            <Text>Não Comunicar Antes de:</Text>
          </Box>
          <Box w="54%">
            <InputDate
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              placeholder="DD/MM/AA"
              width={160}
              value={do_not_communicate_before_date}
              onChangeText={(value: string) =>
                setDoNotCommunicateBeforeDate(value)
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="39%">
            <Text>Data Prevista de Retorno:</Text>
          </Box>
          <Box w="54%">
            <InputDate
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              placeholder="DD/MM/AA"
              width={160}
              value={expected_return_date}
              onChangeText={(value: string) => setExpectedReturnDate(value)}
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"

        >
          <Box w="45%">
            <Text>Fornecedor Destino: </Text>
          </Box>
          <Box w="54%" >
            <Select
              selectedValue={itemDetails?.destination_supplier}
              minWidth="150"
              height={8}
              accessibilityLabel="Selecione um fornecedor"
              placeholder="Selecione"
              _selectedItem={{
                bg: "teal.600",
              }}
              mt={1}
              isDisabled={
                itemDetails?.status === "Em Preenchimento" ? false : true
              }
              onValueChange={(value: string) =>
                setItemDetails({
                  ...itemDetails,
                  destination_supplier: value,
                })
              }
            >
              {supplierList?.map((item) => (
                <Select.Item
                  key={item?.supplier_id}
                  label={item?.name}
                  value={`${item?.name}`}
                />
              ))}
            </Select>
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Sublocado:</Text>
          </Box>
          <Box w="54%">
            <Switch
              trackColor={{ false: "#7F7F7F", true: "#008B9C" }}
              value={itemDetails?.sublet_y_n}
              onValueChange={(value: boolean) =>
                setItemDetails({ ...itemDetails, sublet_y_n: value })
              }
              disabled={
                itemDetails?.status === "Em Preenchimento" ? false : true
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Nome do Contato Técnico:</Text>
          </Box>
          <Box w="54%">
            <TextInput
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              my={2}
              _web={{ my: 0 }}
              style={styles.textInput}
              defaultValue={itemDetails?.technical_contact_name}
              onChangeText={(value: string) =>
                setItemDetails({
                  ...itemDetails,
                  technical_contact_name: value,
                })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>E-mail do Contato: </Text>
          </Box>
          <Box w="54%">
            <TextInput
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              my={2}
              _web={{ my: 0 }}
              style={styles.textInput}
              defaultValue={itemDetails?.contact_email}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, contact_email: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Telefone do Contato: </Text>
          </Box>
          <Box w="54%">
            <TextInput
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              my={2}
              _web={{ my: 0 }}
              style={styles.textInput}
              defaultValue={itemDetails?.contact_phone}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, contact_phone: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>CNPJ do Destino: </Text>
          </Box>
          <Box w="54%">
            <TextInput
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              my={2}
              _web={{ my: 0 }}
              style={styles.textInput}
              defaultValue={itemDetails?.destination_tax_id_number}
              onChangeText={(value: string) =>
                setItemDetails({
                  ...itemDetails,
                  destination_tax_id_number: value,
                })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Razão Social: </Text>
          </Box>
          <Box w="54%">
            <TextInput
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              my={2}
              _web={{ my: 0 }}
              style={styles.textInput}
              defaultValue={itemDetails?.destination_razao_social}
              onChangeText={(value: string) =>
                setItemDetails({
                  ...itemDetails,
                  destination_razao_social: value,
                })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Cidade: </Text>
          </Box>
          <Box w="54%">
            <TextInput
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              my={2}
              _web={{ my: 0 }}
              style={styles.textInput}
              defaultValue={itemDetails?.destination_city}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, destination_city: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Estado: </Text>
          </Box>
          <Box w="54%">
            <TextInput
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              my={2}
              _web={{ my: 0 }}
              style={styles.textInput}
              defaultValue={itemDetails?.destination_state_or_province}
              onChangeText={(value: string) =>
                setItemDetails({
                  ...itemDetails,
                  destination_state_or_province: value,
                })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Motivo:</Text>
          </Box>
          <Box w="54%" _web={{ minW: 500 }}>
            <Select
              selectedValue={itemDetails?.reason?.toString()}
              minWidth="150"
              placeholder="Todos"
              my={2}
              _web={{ my: 0 }}
              _selectedItem={{
                bg: "teal.600",
              }}
              defaultValue={itemDetails?.reason?.toString()}
              onValueChange={(value: string) =>
                setItemDetails({ ...itemDetails, reason: value })
              }
              isDisabled={
                itemDetails?.status === "Em Preenchimento" ? false : true
              }
            >
              {options.map((item) => (
                <Select.Item
                  key={item.value}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </Select>
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="column"
          justifyContent="space-between"
          p={2}
          space={1}
        >
          <Box w="45%">
            <Text>Justificativa: </Text>
          </Box>
          <Box w="100%">
            <TextInput
              editable={
                itemDetails?.status === "Em Preenchimento" ? true : false
              }
              multiline={true}
              fontSize={"13px"}
              textAlign={"left"}
              textAlignVertical={"top"}
              h={140}
              defaultValue={itemDetails?.justification}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, justification: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        {(itemDetails?.files?.length > 0 ||
          itemDetails?.status === "Em Preenchimento") && (
            <>
              <Stack
                _web={{ px: 10 }}
                direction="row"
                space={3}
                justifyContent="space-between"
                px={2}
                py={3}
              ></Stack>
              <Box px={2} py={2} _web={{ px: 10 }}>
                <Pressable
                  onPress={uploadFile}
                  isDisabled={itemDetails?.status !== "Em Preenchimento"}
                >
                  <VStack
                    direction="row"
                    space={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" space={2} alignItems="center">
                      <Text fontWeight="bold" w={240}>
                        {itemDetails?.status === "Em Preenchimento"
                          ? "Anexo (Inserir Documento)"
                          : "Anexo"}
                      </Text>
                    </Stack>
                    {itemDetails?.status === "Em Preenchimento" && (
                      <Box>
                        <Icon
                          as={FontAwesome}
                          name="pencil-square-o"
                          size={8}
                          color={colors.success[50]}
                        />
                      </Box>
                    )}
                  </VStack>
                </Pressable>

                {files?.map((file: any, index) => (
                  <>
                    <Divider />
                    <VStack
                      direction="row"
                      space={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row" space={2} alignItems="center">
                        <Text w={240}>{file.name}</Text>
                      </Stack>
                      <Pressable onPress={() => handleRemoveFile(index)}>
                        <Icon
                          as={FontAwesome}
                          name="trash"
                          size={8}
                          color={colors.red[600]}
                        />
                      </Pressable>
                    </VStack>
                  </>
                ))}
                {itemDetails?.files?.map((fileUrl: string) => (
                  <>
                    <Divider />
                    <VStack
                      direction="row"
                      space={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row" space={2} alignItems="center">
                        <Text w={240}>
                          {fileUrl.split("?")[0].split("/").pop()}
                        </Text>
                      </Stack>
                      <Pressable onPress={() => downloadFile(fileUrl)}>
                        <Icon
                          as={FontAwesome}
                          name="download"
                          size={8}
                          color={colors.success[50]}
                        />
                      </Pressable>
                    </VStack>
                  </>
                ))}
              </Box>
            </>
          )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-between" }}
        >
          {accountType === "ga" && itemDetails?.status === "Solicitado" ? (
            <>
              <Button
                _web={{ w: "30% " }}
                w="33%"
                label="VER SOLICITAÇÃO"
                onPress={() =>
                  navigation.navigate("MovementItemListScreen", {
                    headerId,
                  })
                }
              />
              <Button
                _web={{ w: "30%" }}
                w="33%"
                label="Não Autorizar"
                onPress={() => updateMovementHeader(5)}
              />
              <Button
                _web={{ w: "30%" }}
                w="33%"
                label="Autorizar"
                onPress={() => updateMovementHeader(3)}
              />
            </>
          ) : itemDetails?.status === "Em Preenchimento" ? (
            <>
              <Button
                _web={{ w: "40%" }}
                w="48%"
                label="VER SOLICITAÇÃO"
                onPress={() =>
                  navigation.navigate("MovementItemListScreen", {
                    headerId,
                  })
                }
              />
              <Button
                _web={{ w: "40%" }}
                w="48%"
                label="GRAVAR"
                onPress={() => saveDetail()}
              />
            </>
          ) : (
            <>
              <Button
                _web={{ w: "50%" }}
                w="48%"
                label="VOLTAR"
                onPress={navigation.goBack}
              />
              <Button
                _web={{ w: "50%" }}
                w="48%"
                label="GRAVAR"
                isDisabled={
                  itemDetails?.status === "Em Preenchimento" ? false : true
                }
                onPress={() => saveDetail()}
              />
            </>
          )}
        </Stack>

        <Spinner visible={isLoading} />
      </View >
    </>
  );
};

const styles = StyleSheet.create({

  textInput: {
    fontSize: 13,
    textAlign: "left",
  }
})