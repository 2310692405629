import { api } from "./api";

export const getLogsList = async (supplier_id: number, asset_number: string, offset?: number, limit?: number) => {
  let url = `/v1/log?supplier_id=${supplier_id}`;

  if (asset_number !== undefined) {
    url += `&asset_number=${asset_number}`;
  }

  if (offset !== undefined) {
    url += `&offset=${offset}`;
  }

  if (limit !== undefined) {
    url += `&limit=${limit}`;
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};