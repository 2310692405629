import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  TextInput,
  ZoomableImage,
} from "../../../../components";
import { HStack, Radio, Select, Stack, Text, useTheme } from "native-base";
import { useAuthContext, useInventoryContext } from "../../../../contexts";
import {
  deleteInventoryItem,
  getInventoryGenerateUrls,
  putInventoryItemService,
} from "../../../../services/inventoryService";
import { platformWeb } from "../../../../utils";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";
import { CAM } from "../../../../../assets/images";
export interface IInventoryItem {
  asset_description: string;
  asset_id: number;
  asset_number: string;
  equipment_base_condition_id: any;
  inventory_number_imo_osf: any;
  number_of_cycles_performed: any;
  there_is_label_y_n: any;
  production_status: any;
  id: number;
  inventory_order_detail_status_id: number;
  status: string;
  info_asset_number: string;
}

export interface IListPhotos {
  uri: string;
  namePhoto: string;
  icon: any;
}

export function DetailForm({
  navigation,
  itemData,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
  itemData: IInventoryItem;
}) {
  const { colors } = useTheme();
  const { updateInventoryData } = useInventoryContext();
  const { accountType } = useAuthContext();
  const [baseConditionId, setBaseConditionId] = useState("");
  const [inventoryNumber, setInventoryNumber] = useState("");
  const [cyclesPerformed, setCyclesPerformed] = useState("");
  const [label, setLabel] = useState("");
  const [productionStatus, setProductionStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [listPhotos, setListPhotos] = useState<IListPhotos[]>([
    {
      namePhoto: "Foto CAF",
      uri: "",
      icon: CAM,
    },
    {
      namePhoto: "Frontal",
      uri: "",
      icon: CAM,
    },
    {
      namePhoto: "Lateral",
      uri: "",
      icon: CAM,
    },
    {
      namePhoto: "Superior",
      uri: "",
      icon: CAM,
    },
  ]);

  const handleGetItemPhotos = async (id: number, asset_id: number) => {
    const Urls = await getInventoryGenerateUrls(id, asset_id);
    if (Urls?.status) {
      const photos = Urls.result.map((item: any, index: any) => ({
        namePhoto: listPhotos[index].namePhoto,
        uri: item,
        icon: CAM,
      }));

      setListPhotos(photos);
    }
  };

  useEffect(() => {
    setBaseConditionId(itemData.equipment_base_condition_id);
    setInventoryNumber(itemData.inventory_number_imo_osf);
    setCyclesPerformed(itemData.number_of_cycles_performed);
    setLabel(itemData.there_is_label_y_n);
    setProductionStatus(itemData.production_status);
    handleGetItemPhotos(itemData.id, itemData.asset_id);
  }, [itemData]);

  async function handleUpdateItem(statusId: number) {
    setIsLoading(true);
    const data = await putInventoryItemService(itemData.id, statusId);
    if (data?.status) {
      updateInventoryData();
    }
    setIsLoading(false);
  }

  async function handleDeleteItem() {
    setIsLoading(true);
    const data = await deleteInventoryItem(itemData.id);
    if (data?.status) {
      updateInventoryData();
    }
    setIsLoading(false);
  }

  return (
    <>
      <Stack alignItems="center" my={2} space={1}>
        <Text fontSize={13}>
          CAF -{" "}
          {itemData.asset_number === "000000000000"
            ? itemData.info_asset_number
            : itemData.asset_number}
        </Text>
        <Text fontSize={13}>{itemData.asset_description}</Text>
        <Text fontSize={16}>{itemData.status}</Text>
      </Stack>
      <Divider />
      <>
        <HStack alignItems="center" py={3} space={2}>
          <Text flex={1} fontWeight={700} fontSize={13}>
            Condição Base do Equipamento:
          </Text>
          <Select
            minWidth="200px"
            height={9}
            accessibilityLabel="Selecione um fornecedor"
            placeholder="Selecione"
            _selectedItem={{
              bg: "teal.600",
            }}
            selectedValue={baseConditionId?.toString()}
            isDisabled
          >
            <Select.Item label="Local coberto" value="1" />
            <Select.Item label="Local não coberto - Ao tempo" value="2" />
          </Select>
        </HStack>

        <Divider />

        <HStack alignItems="center" py={3} space={2}>
          <Text fontWeight={700} fontSize={13}>
            Nº Inventário / Imo / “incolor”
          </Text>
          <TextInput
            value={inventoryNumber?.toString()}
            fontSize={13}
            isDisabled
          />
        </HStack>

        <Divider />

        <HStack alignItems="center" py={3} space={2}>
          <Text fontWeight={700} fontSize={13}>
            Número de Golpes Atual
          </Text>
          <TextInput
            value={cyclesPerformed?.toString()}
            placeholder="Tooling Tracking"
            fontSize={13}
            isDisabled
          />
        </HStack>

        <Divider />

        <Stack py={3} space={2}>
          <HStack alignItems="center" space={2}>
            <Text fontWeight={700} fontSize={13}>
              Tem Etiqueta?
            </Text>
            <Radio.Group
              name="label"
              value={label?.toString()}
              accessibilityLabel="Tem etiqueta?"
              isDisabled
            >
              <HStack ml={4} space={2} alignItems="center">
                <Radio
                  value="1"
                  accessibilityLabel="Sim, tem etiqueta!"
                  size="sm"
                >
                  Sim
                </Radio>
                <Radio
                  value="0"
                  accessibilityLabel="Não tem etiqueta!"
                  size="sm"
                >
                  Não
                </Radio>
              </HStack>
            </Radio.Group>
          </HStack>

          <HStack alignItems="center" space={2}>
            <Text fontWeight={700} fontSize={13}>
              Em Produção:
            </Text>
            <Radio.Group
              name="production"
              value={productionStatus}
              isDisabled
              accessibilityLabel="Em produção"
            >
              <HStack ml={4} space={2} alignItems="center">
                <Radio
                  value="yes"
                  accessibilityLabel="Sim, em produção!"
                  size="sm"
                  ml={"2px"}
                >
                  Sim
                </Radio>
                <Radio
                  value="no"
                  accessibilityLabel="Não está em produção!"
                  size="sm"
                >
                  Não
                </Radio>
                <Radio
                  value="pea"
                  accessibilityLabel="Não está em produção!"
                  size="sm"
                >
                  PeA
                </Radio>
              </HStack>
            </Radio.Group>
          </HStack>
        </Stack>
        <Divider />
        <HStack space={4} overflowX="auto" justifyContent={"space-around"}>
          {listPhotos.map((item, index) => (
            <ZoomableImage key={index} item={item} />
          ))}
        </HStack>
        <Stack
          _android={{ display: "none" }}
          direction="row"
          alignItems="center"
          justifyContent="center"
          bg={colors.white}
          space={3}
          py={1}
          px={4}
          mt={4}
        >
          {platformWeb ? (
            <Button
              _web={{ w: "30%" }}
              w="30%"
              label="VOLTAR"
              onPress={() => navigation.navigate("InventoryScreen")}
            />
          ) : null}
          {accountType === "ga" &&
          itemData.inventory_order_detail_status_id === 4 ? (
            <>
              <Button
                label="Sobra física"
                onPress={() => handleUpdateItem(10)}
              />
              <Button
                label="Comodatário transferido"
                onPress={() => handleUpdateItem(9)}
              />
              <Button label="Apagar" onPress={() => handleDeleteItem()} />
            </>
          ) : null}
        </Stack>
      </>
    </>
  );
}
