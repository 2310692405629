import { Divider } from "../index";
import { Center, Stack, Text, useTheme, View } from "native-base";


export const TabsHeaderOff = () => {


  return (
    <>
      <View px={2} py={1} bg="#fff">
        <Stack direction="row" minH={12}>
        <Center flex={1}>
          <Text fontWeight="bold">
            Nenhuma informação de inventário encontrada!
          </Text>
        </Center>
        </Stack>
      </View>
      <Divider />
    </>
  );
};
