import { PieChart } from "../../../../components/PiechartComponent";
import { Box, HStack, Select, Stack, Text, VStack, useTheme } from "native-base";
import { ProgressChartComponent } from "../../../../components/ProgressChart";
import { platformWeb } from "../../../../utils";
import { useCallback, useState } from "react";
import { useAuthContext, useInventoryContext, useSupplierContext } from "../../../../contexts";
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native";
import { getDashboardList } from "../../../../services/dashboardService";
import { ChartDataProcessor } from "../../../../components/DataProcessor";
import { ChartTitles, Pages, StatusColors } from "../Enum/enum";
import Spinner from "react-native-loading-spinner-overlay";
import { ModalAlert } from "../../../../components/ModalAlert";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { getInventoryClosure } from "../../../../services/inventoryService";

const optionsPages: Pages[] = [
  Pages.Inventario,
  // Pages.EvolucaoDoInventario,
  Pages.EstadoAtualDoInventario,
];

const statusColorMapping = {
  [StatusColors.CAF_Duplicado]: "#FF5733",
  [StatusColors.CAF_NaoEstaNaBase]: "#33FF57",
  [StatusColors.CAF_NaoLocalizado]: "#4F81BD",
  [StatusColors.CAF_Terceirizado]: "#FFA500",
  [StatusColors.ComodatarioTransferido]: "#C0504D",
  [StatusColors.DesinvestidoNoAno]: "#FFD700",
  [StatusColors.Planejado]: "#FF5733",
  [StatusColors.Realizado]: "#00FF00",
  [StatusColors.Autorizado]: "#1168ad",
  [StatusColors.EmPreenchimento]: "#dc2171",
  [StatusColors.NaoAutorizado]: "#FF4500",
  [StatusColors.Solicitado]: "#8064A2",
  [StatusColors.Completo]: "#9BBB59",
  [StatusColors.Pendente]: "#FF6347",
  [StatusColors.Enviada]: "#008080",
  [StatusColors.Lida]: "#FF8C00",
};

const sectionTitleMapping = {
  [ChartTitles.Inventory]: "Inventário",
  [ChartTitles.Movement]: "Movimentação",
  [ChartTitles.Disinvestment]: "Desinvestimento",
  [ChartTitles.Communication]: "Comunicação",
  [ChartTitles.Retooling]: "Retooling",
};

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase> }) => {

  const { colors } = useTheme();
  const { inventoryData } = useInventoryContext();
  const { currentSupplier } = useSupplierContext();
  const [value, setValue] = useState("Inventario")
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryData1, setInventoryData1] = useState<any>([]);
  const [item, setItem] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const percentage = ((inventoryData?.done / inventoryData?.total) * 100).toFixed(2);

  const handleCloseAlert = () => {
    setShowAlert(false);
    navigation.navigate("HomeScreen");
  };

  useFocusEffect(
    useCallback(() => {

      const fetchData = async () => {
        setIsLoading(true);
        try {
          const data = await getDashboardList(currentSupplier);

          if (data?.status) {
            setItem(data.result);
          }
        } catch (error: any) {
          console.error(error);
          if (error.response && error.response.status === 400 && !currentSupplier) {
            setShowAlert(true);
          }
          return [];
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();

      async function getInventoryClosures() {
        const supplier_id = currentSupplier;
        try {
          const data = await getInventoryClosure(inventoryData?.id);
          if (data?.status) setInventoryData1(data?.result);
        } catch (error: any) {
          console.error(error);
          if (error.response && error.response.status === 400 && !currentSupplier) {
            setShowAlert(true);
          }
          return [];
        }
      }
      getInventoryClosures();
    }, [currentSupplier, route, inventoryData])
  )

  return (
    <Stack flex={1} bgColor={colors.primary[100]} maxW={1500} width={'full'} _web={{ px: 10, py: 5 }}>
      {/* <Text>{currentSupplier}</Text> */}
      <Box
        maxW={platformWeb ? "100%" : "240"}
        minW={platformWeb ? "100%" : "240"}
      >
        <Select
          selectedValue={value}
          height={8}
          minWidth="150"
          accessibilityLabel="Selecione uma Página"
          placeholder="Selecione"
          _selectedItem={{
            bg: "teal.600",
          }}
          mt={1}
          onValueChange={(itemValue) => setValue(itemValue)}
        >
          {optionsPages?.map((item) => (
            <Select.Item key={item} label={item} value={item} />
          ))}
        </Select>
      </Box>

      <HStack flex={1} w={'full'} alignItems="center" justifyContent="center" flexWrap="wrap" >
        {value === "Inventario" && (
          <>
            {item && Object.keys(item).map((chartKey) => (
              <Stack bgColor={'white'} w='100%' _web={{ w: '75%' }} h={400} my={3} justifyContent={'center'} >
                <PieChart
                  key={chartKey}
                  title={sectionTitleMapping[chartKey]}
                  data={ChartDataProcessor({
                    data: item[chartKey],
                    statusColorMapping
                  })}
                  accessor="quantity"
                />
              </Stack>
            ))}
          </>
        )}
      </HStack >


      {value === "Estado Atual do Inventário" && (
        <VStack alignItems={'center'}  >
          <Stack w="90%" h="full" mt={15} py={2} bgColor={'white'} _web={{ w: '50%' }}>
            <ProgressChartComponent percentage={percentage} />
            <Stack>
              <Box mb={2}>
                <Text textAlign={"center"} >Já Inventariadas:   {inventoryData?.done}     {percentage}%</Text>
              </Box>
              <Box mb={2}>
                <Text textAlign={"center"} >Faltam Inventariar:   {(inventoryData?.total - inventoryData?.done)}</Text>
              </Box>
              <Box mb={2}>
                <Text textAlign={"center"} >Problemas Documentados:   {inventoryData1?.total_problems}</Text>
              </Box>
              <Box mb={2}>
                <Text textAlign={"center"} >Total de Ferramentas:   {inventoryData?.total}</Text>
              </Box>
            </Stack>
          </Stack>
        </VStack>
      )}
      <Spinner visible={isLoading} />
      <ModalAlert isVisible={showAlert} onClose={handleCloseAlert} />
    </Stack >
  );
};
