import { useContext, useState } from "react";
import { getRetoolingDetailList, postRetoolingAddItem } from "./../../../services/retoolingService";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { useNavigatorContext } from "../../../contexts";

export default function retoolingItemsHook() {
  const [isLoading, setIsLoading] = useState(false);
  const { navigateTo } = useNavigatorContext();
  const { addItemsRetooling } = useGlobalContext()

  async function postAddItemRetooling(retooling_header_id: number, asset_numbers: any) {
    setIsLoading(true)
    const data = await postRetoolingAddItem(retooling_header_id, asset_numbers);
    if (data?.status) {
      getDetailListRetooling(retooling_header_id)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getDetailListRetooling(retooling_header_id: number) {
    const data = await getRetoolingDetailList(retooling_header_id);
    if (data?.status) {
      addItemsRetooling(data.result)
      setIsLoading(false)
      navigateTo('RetoolingApprovedScreen')
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }


  return {
    postAddItemRetooling,
    getDetailListRetooling,
    isLoading
  }
}