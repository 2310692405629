import { useAuthContext, useSupplierContext } from "../../../../../contexts";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native";
import { VStack } from "native-base";
import { useCallback, useState } from "react";
import { SearchAssetHistory } from "../SearchAssetHistory";
import { AssetHistoryList } from "../AssetHistoryList";
import { getLogsList } from "../../../../../services/logsService";
import Spinner from "react-native-loading-spinner-overlay";
import { ModalAlert } from "../../../../../components/ModalAlert";

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase>; }) => {
  const { currentSupplier } = useSupplierContext();

  const [isLoading, setIsLoading] = useState(false);
  const [asset_number, setAssetNumber] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit] = useState(15);
  const [_selectStatus, _setSelectStatus] = useState<any>('Todos');
  const [showAlert, setShowAlert] = useState(false);
  const [assetHistoryListData, setAssetHistoryListData] = useState<any[]>([]);

  const handleCloseAlert = () => {
    setShowAlert(false);
    navigation.navigate("HomeScreen");
  };

  useFocusEffect(
    useCallback(() => {
      const getAssetHistoryList = async () => {
        setIsLoading(true);
        try {
          if (asset_number !== '') {
            loadApi();
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      getAssetHistoryList();
    }, [route, asset_number])
  );

  async function getAssetHistoryListData(id: number, asset_number: string, offset: number, limit: number) {
    try {
      const assetList = await getLogsList(id, asset_number, offset, limit);

      if (assetList?.status) {
        return assetList.result;
      } else {
        return [];
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 400 && !currentSupplier) {
        setShowAlert(true);
      }
      return [];
    }
  }

  async function loadApi() {
    if (isLoading) return;
    setIsLoading(true);
    const response = await getAssetHistoryListData(currentSupplier, asset_number, offset, limit);
    if (assetHistoryListData) {
      setAssetHistoryListData([...assetHistoryListData, ...response]);
    }

    if (response.length > 0) {
      setOffset(offset + limit);
    }

    setIsLoading(false);
  }


  function handleSearch(AssetNumber?: string) {
    if (AssetNumber) {
      setAssetNumber(AssetNumber);
    }
    else {
      setAssetNumber('');
    }
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, w: "100%" }} bg="#fff">
        <SearchAssetHistory handleSearch={handleSearch} />
        <AssetHistoryList
          assetHistoryListData={assetHistoryListData}
          navigation={navigation}
          onEndReached={loadApi}
          onEndReachedThreshold={0.1}
          isLoading={isLoading}
        />
      </VStack >

      <ModalAlert isVisible={showAlert} onClose={handleCloseAlert} />
      <Spinner visible={isLoading} />
    </>
  )
}