import { api } from "./api";

export const authService = async ({ data }: any) => {
  return api
    .post("/v1/login", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const resetPassword = async (email: string) => {
  const data = {
    email: email
  };
  try {
    const response = await api.post("/v1/request_password_reset", data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const accountTypeService = async () => {
  return api
    .get("/v1/account_type")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
