import { MainMenu } from "../../screens/Home/components/MainMenu";
import { Box, Stack, Text, useTheme } from "native-base";
import { platformWidthContent } from "../../utils";

export const WebSidebarMenu = ({ children, navigation }: any) => {
  const { colors } = useTheme();

  return (
      <Stack direction="row" bg="#fff">
        <Box
          w="20%"
          px={10}
          _web={{
            px: 0,
          }}
          py={10}
          bg={colors.primary[50]}
        >
          <MainMenu navigation={navigation}/>
        </Box>
        <Box flex={1}>
          <Box maxW={platformWidthContent} bg={colors.primary[100]} />
          <>{children}</>
        </Box>
      </Stack>
  );
};
