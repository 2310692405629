import { CheckIcon, Select, View } from "native-base";
import { useFormContext, useProviderContext } from "../../contexts";
import { PieChart } from "react-native-chart-kit";

interface IMiniPieChart {
  data: any;
  width?: any;
  height?: any;
}
export const MiniPieChart = ({ data, width, height }: IMiniPieChart) => {
  return (
    <View style={{ flex: 1 }}>
      <PieChart
        data={data}
        width={width}
        height={height}
        chartConfig={{
          decimalPlaces: 2,
          color: () => `#fff`,
          backgroundColor: "#fff",
        }}
        backgroundColor="#fff"
        center={[-50, 1]}
        accessor="quantity"
        paddingLeft={'50'}
        hasLegend={false}
        absolute
      />
    </View>
  );
};
