import {
  Divider,
  CircleStatus,
  Button,
} from "../../../components";
import {
  View,
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
  Pressable,
} from "native-base";
import { platformWidth } from "../../../utils";
import { useNavigatorContext, useSupplierContext } from "../../../contexts";
import { useContext, useEffect } from "react";
import { useGlobalContext } from "./../../../contexts/globalContext";
import movimentItemsHook from "./../../../screens/MovimentItems/hook/useMovimentItems";
import homeScreenHooks from "./../../../screens/Home/hooks/homeScreen";
import movimentHook from "./../../../screens/MovimentScreen/hook/useMoviment";
import managementItemsHook from "./../../../screens/AssetManagementItems/hook/useManagementItems";
import managementHook from "./../../../screens/AssetManagement/hook/useAssetManagement";

export const Content = () => {
  const { colors } = useTheme();
  const { currentSupplier } = useSupplierContext();
  const { navigateTo } = useNavigatorContext();
  const { _managementHeaderCDate, _flatListMoviment, _managementListDetails, _setListManagement } = useGlobalContext()
  const { getDetailListManagement } = managementItemsHook()
  const { getSelectItensManagement } = managementHook()

  const dateObject = new Date(_managementHeaderCDate.creation_date);
  const dia = dateObject.getDate();
  const mes = dateObject.getMonth() + 1; // Adiciona 1 pois o mês começa em 0 (janeiro) em JavaScript
  const ano = dateObject.getFullYear();
  const dataFormatada = `${dia}/${mes}/${ano}`;

  useEffect(() => {
    getDetailListManagement(_managementHeaderCDate.id)
  }, [_managementHeaderCDate, _flatListMoviment])

  console.log(_managementHeaderCDate)
  function handleRedirectPage(item: any) {
    _setListManagement(false)
    //getDetailIdRetooling(item.id)
  }

  function selectItems() {
    _setListManagement(false)
    getSelectItensManagement(currentSupplier)
    navigateTo('ManagementItemsScreen')
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, w: '100%' }} bg="#fff">
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          justifyContent="space-between"
          px={3}
          py={5}
        >
          <Text>{`${dataFormatada}`}  {`${_managementHeaderCDate.status}`}</Text>
          <Text>Id: {`${_managementHeaderCDate.id}`}</Text>
        </Stack>
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
        >
          <Box minWidth={24} _web={{ w: 200 }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={180} _web={{ w: 750 }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
        </Stack>
        <FlatList
          extraData={_flatListMoviment}
          nestedScrollEnabled
          data={_managementListDetails}
          contentContainerStyle={{
            marginBottom: 100,
          }}
          renderItem={({ item, index }) => {
            return (
              <Pressable onPress={() => handleRedirectPage(item)} >
                <Box px={4} py={1} _web={{ px: 1 }} >
                  <VStack direction="row" space={2} alignItems='center'>
                    <Box minWidth={24} _web={{ w: 210 }}>
                      <Text>{item?.asset_number}</Text>
                    </Box>
                    <Box w={240} _web={{ w: 756 }}>
                      <Text>{item?.asset_description}</Text>
                    </Box>
                  </VStack>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                </Box>
              </Pressable>
            );
          }}
        />
      </VStack>
      <Divider />


      <View maxWidth={platformWidth}>
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          justifyContent="center"
          px={3}
          py={2}
          backgroundColor='#fff'
        >
          {_managementHeaderCDate.status === "Em preenchimento" || _managementHeaderCDate.status === "Em Preenchimento" ? (
            <>
              <Button label="INCLUIR ITENS" w="49%" onPress={() => selectItems()} />
              <Button label="VER SUMÁRIO" w="49%" onPress={() => navigateTo("AssetManagementSummary")} />
            </>
          ) : (
            <Button label="VER SUMÁRIO" w="80%" onPress={() => navigateTo("AssetManagementSummary")} />
          )}

        </Stack>
      </View>
    </>
  );
};

