import Axios from "axios";
import { api } from "./api";
import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";
import axios from "axios";

export const getMovimentHeaderStatus = () => {
  return api
    .get(`v1/movement_header_status`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getMovementHeader = (header_id: string) => {
  return api
    .get(`v1/movement_header/${header_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getMovimentList = async (
  supplier_id: string,
  searchStatus?: string,
  searchInitialDate?: string,
  searchFinalDate?: string,
  offset?: string | number,
  limit?: string | number
) => {
  let url = `v1/movement_status?supplier_id=${supplier_id}`;
  if (searchStatus) url = url + `&status=${searchStatus}`;
  if (searchInitialDate) url = url + `&initialDate=${searchInitialDate}`;
  if (searchFinalDate) url = url + `&finalDate=${searchFinalDate}`;
  if (offset) url = url + `&offset=${offset}`;
  if (limit) url = url + `&limit=${limit}`;

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postMovimentHeader = (supplier_id: number) => {
  const body = { supplier_id };
  return api
    .post(`v1/movement_header`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getMovimentSelectItens = (
  supplier_id: string,
  limit: number,
  offset?: number,
  searchTerm?: string
) => {
  return api
    .get(
      `v1/asset_list?supplier_id=${supplier_id}&limit=${limit}&offset=${offset}&filter=${searchTerm}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deleteMovementItem = (movement_item_id: string) => {
  return api
    .delete(`v1/movement_detail/${movement_item_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postMovimentAddItem = (
  movement_header_id: number,
  asset_numbers: []
) => {
  const body = { movement_header_id, asset_numbers };
  return api
    .post(`v1/movement_detail`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getMovimentDetailList = (movement_header_id: number) => {
  return api
    .get(`v1/movement_detail_list/${movement_header_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postMovimentRecDocuments = (
  movement_order_header_id: number,
  fileName: any
) => {
  const body = {
    movement_order_header_id: movement_order_header_id,
    fileName: fileName,
  };
  return api
    .post(`v1/movement_header/generate_signed_url`, body)
    .then((response) => {
      console.log("Upload file:", body, response);
      return response.data;
    })
    .catch((error) => {
      console.error("Upload file error:", error.response);
    });
};

export const getMovimentRecDocuments = (movement_order_header_id: number) => {
  const body = { movement_order_header_id: movement_order_header_id };
  return api
    .post(`v1/movement_header/get_signed_url`, body)
    .then((response) => {
      console.log(movement_order_header_id, response);
      return response.data;
    })
    .catch((error) => {
      console.error(body, error.response?.data);
    });
};

export const putMovimentRecDocuments = async (
  presignedUrl: string,
  data: any
) => {
  if (Platform.OS !== "web") {
    const result = await FileSystem.uploadAsync(presignedUrl, data.uri, {
      fieldName: "file",
      httpMethod: "PUT",
      uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
    });
    return result;
  } else {
    try {
      const fileBlob = await fetch(data.uri).then((response) =>
        response.blob()
      );
      await axios.put(presignedUrl, fileBlob, {
        headers: {
          "Content-Type": data.file.type,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const putMovimentRec = (movement_header_id: number, file: any) => {
  return api
    .put(`v1/movement_header/${movement_header_id}`, file)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
