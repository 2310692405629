import { SelectProvider, Divider } from "../../../../components";
import { Box, Stack, Text, useTheme, VStack } from "native-base";
import { InventoryStatus } from "../../../../components";
import { platformWeb } from "../../../../utils";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";

export const CurrentProvider = ({
  navigation,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { colors } = useTheme();

  return (
    <VStack
      px={4}
      bg="#fff"
      _web={{
        display: "flex",
        bg: colors.primary[100],
        w: "80%",
        px: 10,
        py: 50,
        borderRadius: 10,
      }}
    >
      <Stack direction="row" pb={2} space={3} alignItems="center">
        <Box>
          <Text>Fornecedor:</Text>
        </Box>
        <Box
          maxW={platformWeb ? "90%" : "240"}
          minW={platformWeb ? "90%" : "240"}
        >
          <SelectProvider navigation={navigation} />
        </Box>
      </Stack>

      <InventoryStatus/>
      
      <Box pb={2}>
        <Divider />
      </Box>
    </VStack>
  );
};
