import { BottomNavigation, TemplateWebPages } from "../../components";
import { Center, Text, View, VStack } from "native-base";
import { platformWeb } from "../../utils";
import { Content } from "./Components/Content";
import { useContext, useEffect, useState } from "react";
import inventoryClosureHook from "./Components/useActivesHook";
import { useSupplierContext } from "../../contexts/supplierContext";
import searchListActives from "./dataTable";
import { useGlobalContext } from "../../contexts/globalContext";
import ListActivesHook from "./Components/useActivesHook";
import { FormContextProvider, NavigatorContextProvider } from "../../contexts";
import { ParamListBase } from "@react-navigation/native";
import { DrawerNavigationProp, DrawerScreenProps } from "@react-navigation/drawer";


export const Act = ({
  navigation,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { currentSupplier } = useSupplierContext();
  const { getListActives } = ListActivesHook();
  const { _listActives } = useGlobalContext()
  const [carregando, setCarregando] = useState<boolean>(true)

  useEffect(() => {
    getListActives(currentSupplier)
  }, [currentSupplier]);

  useEffect(() => {
    _listActives.length && setCarregando(false)
  }, [_listActives]);

  return (
    <>
      <VStack flex={1}>
        {platformWeb ? (
          <TemplateWebPages navigation={navigation}>
            <Center>
              {/* {carregando ? <View><Text>Carregando...</Text></View> : <Content />} */}
              <Content navigation={navigation} />

            </Center>
          </TemplateWebPages>
        ) : (
          <>
            <Content navigation={navigation} />
            <BottomNavigation navigation={navigation} />
          </>
        )}
      </VStack>
    </>
  );
};

export const Actives = ({ navigation }: DrawerScreenProps<ParamListBase>) => {
  return (
    <NavigatorContextProvider>
      <FormContextProvider>
        <Act navigation={navigation} />
      </FormContextProvider>
    </NavigatorContextProvider>
  );
};