import React from "react";
import { StyleSheet } from "react-native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";
import { ScrollView, Stack, Text, VStack, View } from "native-base";
import Spinner from "react-native-loading-spinner-overlay";
import { useInventoryContext } from "../../../../contexts";
import { getInventoryCodeService } from "../../../../services/inventoryService";
import { DetailForm } from "../DetailForm";

export interface IInventoryItem {
  asset_description: string;
  asset_id: number;
  asset_number: string;
  equipment_base_condition_id: any;
  inventory_number_imo_osf: any;
  number_of_cycles_performed: any;
  there_is_label_y_n: any;
  production_status: any;
  id: number;
  inventory_order_detail_status_id: number;
  status: string;
  info_asset_number: string;
}

export function Content({
  navigation,
  itemId
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
  itemId: string | undefined
}) {
  const { inventoryData, updateInventoryData } = useInventoryContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [assetNumber, setAssetNumber] = React.useState<string>();
  const [itemData, setItemData] = React.useState<IInventoryItem>();

  React.useEffect(() => {
    setAssetNumber(itemId);
  }, [itemId]);

  React.useEffect(() => {
    async function getInventoryItem() {
      if (inventoryData && assetNumber) {
        setIsLoading(true);
        const data = await getInventoryCodeService(
          inventoryData.id,
          assetNumber
        );
        if (data?.status) {
          setItemData(data?.result);
          setIsLoading(false);
        }
      }
    }
    getInventoryItem();
  }, [assetNumber]);
  return (
    <VStack flex={1} alignItems="center">
      <ScrollView _web={{ w: '60%' }}>
        <Stack flex={1} p={2} >
          {itemData ? (
            <DetailForm itemData={itemData} navigation={navigation} />
          ) : (
            <Stack alignItems="center" my={2} space={1}>
              <Text fontSize={13}>CAF não Encontrado.</Text>
            </Stack>
          )}
        </Stack>
      </ScrollView>
      <Spinner visible={isLoading} />
    </VStack>
  );
}

const styles = StyleSheet.create({
  camera: {
    zIndex: 10,
    ...StyleSheet.absoluteFillObject,
  },
});
