import React, { createContext, useContext, useState } from 'react';
import { useNavigatorContext } from './navigatorContext';
import retoolingItemsHook from './../screens/RetoolingItems/hook/useRetoolingItems';

/*interface ICloseInventoryContext {
  
}*/

const GlobalContext = createContext({});

function GlobalProvider({ children }: any) {
  const { navigateTo } = useNavigatorContext();
  const { getDetailListRetooling } = retoolingItemsHook();
  const [_listActives, _setListActives] = useState([]);
  const [_numberCaf, _setNumberCaf] = useState<number>();
  const [_assetList, _setAssetList] = useState<any>();
  const [_assetTypology, _setAssetTypology] = useState([]);
  const [_equipment, _setEquipment] = useState([]);
  const [_partNumbers, _setPartNumbers] = useState([]);
  const [_listActivesOne, _setListActivesOne] = useState();
  const [_retoolingSelectItens, _setRetoolingSelectItens] = useState([]);
  const [_retoolingHeaderId, _setRetoolingHeaderId] = useState({});
  const [_retoolingListDetails, _setRetoolingListDetails] = useState([]);
  const [_headerCDate, setHeaderCDate] = useState({});
  const [_itemRetoolingId, _setItemRetoolingId] = useState({});
  const [_flatListRetooling, _setListRetooling] = useState(0);
  const [_flatListRetoolingS, _setListRetoolingS] = useState(0);
  const [_movimentHeaderCDate, setMovimentHeaderCDate] = useState({});
  const [_movimentHeaderId, _setMovimentHeaderId] = useState({});
  const [_movimentSelectItens, _setMovimentSelectItens] = useState([]);
  const [_movimentListDetails, _setMovimentListDetails] = useState([]);
  const [_flatListMoviment, _setListMoviment] = useState<number>(0);
  const [_managementHeaderCDate, setManagementHeaderCDate] = useState({});
  const [_managementHeaderId, _setManagementHeaderId] = useState({});
  const [_managementSelectItens, _setManagementSelectItens] = useState([]);
  const [_managementListDetails, _setManagementListDetails] = useState([]);
  const [_flatListManagement, _setListManagement] = useState(false);
  const [_divestimentHeaderCDate, setDivestimentHeaderCDate] = useState({});
  const [_divestimentHeaderId, _setDivestimentHeaderId] = useState({});
  const [_divestimentSelectItens, _setDivestimentSelectItens] = useState([]);
  const [_divestimentListDetails, _setDivestimentListDetails] = useState([]);
  const [_flatListDivestiment, _setListDivestiment] = useState(false);
  const [_itemDivestimentId, _setItemDivestimentId] = useState({});
  const [_divestimentListSummary, _setDivestimentListSummary] = useState([])
  const [_flatListInventory, _setListInventory] = useState<number>(0);
  const [_flatListMoviment1, _setListMoviment1] = useState<number>(0);

  function addAssetList(data: any, typology: any, equipment: any) {
    _setAssetList(data);
    _setAssetTypology(typology)
    _setEquipment(equipment)
  }

  function addPartNumbers(data: any, navigation: any) {
    _setPartNumbers(data);
    navigation.navigate('PartNumbers')
    // navigateTo('PartNumbers')
  }

  function addRetoolingSelectItens(data: any) {
    _setRetoolingSelectItens(data);
    // navigateTo('RetoolingItemsScreen')
  }

  function addItemsRetooling(data: any) {
    _setRetoolingListDetails(data)
    // navigateTo('RetoolingApprovedScreen')
  }

  function addItemRetoolingId(data: any) {
    _setItemRetoolingId(data)
    // navigateTo('RetoolingDetailsScreen')
  }

  function recRetooling() {
    _setListRetooling(_flatListRetooling + 1)
  }

  function recRetoolingS() {
    _setListRetoolingS(_flatListRetoolingS + 1)
  }


  function recManagement() {
    _setListManagement(true)
    // navigateTo('AssetManagementScreen')
  }

  function addMovimentSelectItens(data: any) {
    _setMovimentSelectItens(data);
    //navigateTo('MovimentItemsScreen')
  }

  function addItemsMoviment(data: any) {
    _setMovimentListDetails(data)
    //navigateTo('MovimentApprovedScreen')
  }

  function addManagementSelectItens(data: any) {
    _setManagementSelectItens(data);
  }

  function addItemsManagement(data: any) {
    _setManagementListDetails(data)
  }

  function addDivestimentSelectItens(data: any) {
    _setDivestimentSelectItens(data);
    // navigateTo('DivestmentItems')
  }

  function addItemsDivestiment(data: any) {
    _setDivestimentListDetails(data)
    // navigateTo('DivestmentApprovedScreen')
  }

  function addItemDivestimentId(data: any) {
    _setItemDivestimentId(data)
    // navigateTo('DivestmentDetailsScreen')
  }

  function recDivestiment() {
    _setListDivestiment(true)
    // navigateTo('DivestmentScreen')
  }

  function navInventory() {
    _setListInventory(_flatListInventory + 1);

  }

  function MovimentList1() {
    _setListMoviment1(_flatListMoviment1 + 1)
  }

  function UpdateMovimentHeaderHeaderCDate(data: any) {
    console.log('update funciona', data)
    setMovimentHeaderCDate({ ...data });
  }

  return (
    <GlobalContext.Provider value={{
      _setListActives,
      _listActives,
      _numberCaf,
      _setNumberCaf,
      _assetList,
      _setAssetList,
      addAssetList,
      _assetTypology,
      _setAssetTypology,
      _equipment,
      _setEquipment,
      _partNumbers,
      addPartNumbers,
      _listActivesOne,
      _setListActivesOne,
      addRetoolingSelectItens,
      _retoolingSelectItens,
      _setRetoolingHeaderId,
      _retoolingHeaderId,
      addItemsRetooling,
      _retoolingListDetails,
      _headerCDate,
      setHeaderCDate,
      addItemRetoolingId,
      _itemRetoolingId,
      recRetooling,
      _flatListRetooling,
      _setListRetooling,
      setMovimentHeaderCDate,
      UpdateMovimentHeaderHeaderCDate,
      _movimentHeaderCDate,
      _movimentHeaderId,
      _setMovimentHeaderId,
      addMovimentSelectItens,
      _movimentSelectItens,
      _setMovimentSelectItens,
      addItemsMoviment,
      _movimentListDetails,
      _flatListMoviment,
      _setListMoviment,
      _managementHeaderCDate,
      setManagementHeaderCDate,
      _managementHeaderId,
      _setManagementHeaderId,
      addManagementSelectItens,
      _managementSelectItens,
      addItemsManagement,
      _managementListDetails,
      _flatListManagement,
      _setListManagement,
      recManagement,
      setDivestimentHeaderCDate,
      _divestimentHeaderCDate,
      _divestimentHeaderId,
      _setDivestimentHeaderId,
      addDivestimentSelectItens,
      _divestimentSelectItens,
      _setDivestimentSelectItens,
      _divestimentListDetails,
      _setDivestimentListDetails,
      addItemsDivestiment,
      _flatListDivestiment,
      _setListDivestiment,
      _itemDivestimentId,
      _setItemDivestimentId,
      addItemDivestimentId,
      recDivestiment,
      _divestimentListSummary,
      _setDivestimentListSummary,
      _flatListInventory,
      _setListInventory,
      navInventory,
      MovimentList1,
      _flatListMoviment1,
      _flatListRetoolingS,
      _setListRetoolingS,
      recRetoolingS
    }}>
      {children}
    </GlobalContext.Provider>
  )
}

const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export { GlobalProvider, useGlobalContext }