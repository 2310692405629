import { RetoolingSelectScreen, NewRetoolingItemDetailScreen, NewRetoolingItemListScreen, NewRetoolingItemSummaryScreen, NewRetoolingScreen } from "../screens";
import { createStackNavigator } from "@react-navigation/stack";

const RetoolingStack = createStackNavigator();

export const RetoolingNavigator = () => {
  return (
    <RetoolingStack.Navigator id="RetoolingStack" screenOptions={{ headerShown: false, }}>
      <RetoolingStack.Screen
        name="RetoolingScreen1"
        component={NewRetoolingScreen}
      />
      <RetoolingStack.Screen
        name="RetoolingItemListScreen"
        component={NewRetoolingItemListScreen}
      />
      <RetoolingStack.Screen
        name="RetoolingSelectItemListScreen"
        component={RetoolingSelectScreen}
      />
      <RetoolingStack.Screen
        name="RetoolingItemDetailScreen"
        component={NewRetoolingItemDetailScreen}
      />
      {/* <RetoolingStack.Screen
        name="RetoolingItemSummaryScreen"
        component={NewRetoolingItemSummaryScreen}
      /> */}
    </RetoolingStack.Navigator>
  );
};
