import { NavigationContainerRef, ParamListBase } from "@react-navigation/native";

let navigator: NavigationContainerRef<ParamListBase> | null = null;

export const setNavigator = (ref: NavigationContainerRef<ParamListBase> | null) => {
  navigator = ref;
};

export const navigateToLogin = () => {
  if (navigator) {
    navigator?.navigate("AuthScreen");
  }
};
