import { Text, Stack, Box, useTheme, View } from "native-base";
import { Divider, Button, TextInput, } from "../../../../components";
import { useAuthContext, useSupplierContext, } from "../../../../contexts";
import { useEffect, useState } from "react";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { getDivestimentSummary, putDivestimentFinish } from "../../../../services/divestimentService";
import Spinner from "react-native-loading-spinner-overlay";

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase> }) => {
  const { currentSupplier } = useSupplierContext();
  const { accountType } = useAuthContext();
  const { colors } = useTheme();
  const [summaryDetail, setSummaryDetail] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [contact, setContact] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    setIsloading(true);
    async function getSummaryDetail() {
      if (route.params && route.params.headerId) {
        const data = await getDivestimentSummary(route.params.headerId);
        if (data?.status) {
          setSummaryDetail(data.result);
        }
      }
    }

    getSummaryDetail();
    setIsloading(false);
  }, []);

  async function requestDisinvestment() {
    setIsloading(true);
    const body = {
      contact_name: contact,
      contact_email: email,
      disinvestment_order_header_status_id: 2,
    };
    const data = await putDivestimentFinish(route.params.headerId, body);
    console.log(data);
    if (data?.status) {
      navigation.navigate("DisinvestmentScreen", {
        currentSupplier,
      });
    }
    setIsloading(false);
  }

  async function updateDivestmentHeader(statusId: number) {
    setIsloading(true);
    const body = {
      disinvestment_order_header_status_id: statusId,
    };
    const data = await putDivestimentFinish(route.params.headerId, body);
    if (data?.status) {
      navigation.navigate("DisinvestmentScreen", {
        currentSupplier,
      });
    }
    setIsloading(false);
  }

  const dateObject = new Date(summaryDetail?.creation_date);
  const dia = dateObject.getDate();
  const mes = dateObject.getMonth() + 1; // Adiciona 1 pois o mês começa em 0 (janeiro) em JavaScript
  const ano = dateObject.getFullYear();
  const dataFormatada = `${dia}/${mes}/${ano}`;

  return (
    <>
      <View flex={1} _web={{ px: 10, w: "100%", mt: 50 }} bg="#fff">
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          justifyContent="space-between"
          px={3}
          py={5}
        >
          <Text>
            {`${dataFormatada}`} {`${summaryDetail?.status}`}
          </Text>
          <Text>Id: {`${summaryDetail?.id}`}</Text>
        </Stack>

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          _web={{ mt: 50 }}
        >
          <Box minWidth={100} _web={{ w: "100%" }}>
            <Text color={colors.white}>Resumo da Ordem</Text>
          </Box>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Valor Residual (Saldo) de Peças Total: </Text>
          </Box>
          <Box w="54%">
            <TextInput
              isDisabled
              fontSize={"13px"}
              my={1}
              _web={{ my: 0 }}
              textAlign={"right"}
              value={`R$ ${summaryDetail?.residual.toString()}`}
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Quantidade total de itens na ordem: </Text>
          </Box>
          <Box w="54%">
            <TextInput
              isDisabled
              my={1}
              _web={{ my: 0 }}
              fontSize={"13px"}
              textAlign={"right"}
              value={`${summaryDetail?.total.toString()}`}
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>Contato para Acompanhar a Retirada: </Text>
          </Box>
          <Box w="54%">
            <TextInput
              my={1}
              _web={{ my: 0 }}
              fontSize={"13px"}
              textAlign={"right"}
              isDisabled={summaryDetail?.status === "Autorizado" || summaryDetail?.status === "Não Autorizado"}
              isRequired
              defaultValue={summaryDetail?.contact_name?.toString()}
              onChangeText={setContact}
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="45%">
            <Text>E-mail do Contato Técnico: </Text>
          </Box>
          <Box
            w="54%"
          >
            <TextInput
              my={1}
              _web={{ my: 0 }}
              fontSize={"13px"}
              textAlign={"right"}
              isDisabled={summaryDetail?.status === "Autorizado" || summaryDetail?.status === "Não Autorizado"}
              placeholder={"contato.tecnico@gmail.com"}
              defaultValue={summaryDetail?.contact_email?.toString()}
              onChangeText={setEmail}
              isRequired
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}

          mx={1}
          _web={{ my: 3, justifyContent: "space-between" }}
        >
          {accountType === "ga" &&
            summaryDetail?.status === "Solicitado" ? (
            <>
              <Button
                _web={{ w: "30%" }}
                w="30%"
                size="sm"
                fontSize={12}
                label="VER SOLICITAÇÃO"
                onPress={() => navigation.goBack()}
              />
              <Button
                _web={{ w: "30%" }}
                w="30%"
                size="sm"
                fontSize={12}
                label="Não Autorizar"
                onPress={() => updateDivestmentHeader(5)}
              />
              <Button
                _web={{ w: "30%" }}
                w="30%"
                size="sm"
                fontSize={12}
                label="Autorizar"
                onPress={() => updateDivestmentHeader(3)}
              />
            </>
          ) : summaryDetail?.status === "Em Preenchimento" ? (
            <>
              <Button

                w="48%"
                label="VER SOLICITAÇÃO"
                onPress={() => navigation.goBack()}
              />
              <Button

                w="48%"
                label="GRAVAR"
                onPress={() => requestDisinvestment()}
              />
            </>
          ) : (
            <>
              <Button

                w="48%"
                label="VER SOLICITAÇÃO"
                onPress={() => navigation.goBack()}
              />
              <Button

                isDisabled
                w="48%"
                label="GRAVAR"
                onPress={() => { }}
              />
            </>
          )}
        </Stack>
      </View>
      <Spinner visible={isLoading} />
    </>
  );
};
