import { useNavigatorContext } from "../../../contexts/navigatorContext";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { getMovimentHeaderStatus, getMovimentList, getMovimentSelectItens, postMovimentHeader } from "./../../../services/movimentService";
import { useContext, useState } from "react";

export default function movimentHook() {
  const [isLoading, setIsLoading] = useState(true);
  const { navigateTo } = useNavigatorContext();
  const [_headerStatus, _setHeaderStatus] = useState([]);
  const [_movimentList, _setMovimentList] = useState([])
  const { setMovimentHeaderCDate, _setMovimentHeaderId, addMovimentSelectItens } = useGlobalContext()

  async function getHeaderStatusMoviment() {
    const data = await getMovimentHeaderStatus();
    if (data?.status) {
      _setHeaderStatus(data.result)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getListMoviment(supplier_id: number,) {
    const data = await getMovimentList(supplier_id);
    if (data?.status) {
      _setMovimentList(data.result)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }


  async function postHeaderMoviment(supplier_id: number,) {
    setIsLoading(true)
    const data = await postMovimentHeader(supplier_id);
    if (data?.status) {
      setMovimentHeaderCDate({ "creation_date": (new Date()), "id": data.result.movementHeaderId, "status": "Em Preenchimento" })
      _setMovimentHeaderId(data.result)
      console.log('data', data.result)
      getSelectItensMoviment(supplier_id)

    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getSelectItensMoviment(supplier_id: number,) {
    const data = await getMovimentSelectItens(supplier_id);
    if (data?.status) {
      addMovimentSelectItens(data.result)
      setIsLoading(false)
      navigateTo('MovimentItemsScreen')
    }
    setTimeout(() => {
      setIsLoading(false);

    }, 1500);
  }

  return {
    getHeaderStatusMoviment,
    _headerStatus,
    _movimentList,
    getListMoviment,
    postHeaderMoviment,
    getSelectItensMoviment,
    isLoading
  }
}