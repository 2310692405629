export const ChartDataProcessor = ({ data, statusColorMapping }) => {
  const totalQuantity = data.reduce((total, item) => total + item.quantity, 0);

  const processedData = data.map(item => ({
    ...item,
    percentage: ((item.quantity / totalQuantity) * 100).toFixed(1),
    color: statusColorMapping[item.name] || "#000000",
  }));

  return processedData;
};