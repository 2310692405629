import { ReactNode, createContext, useContext, useState } from "react";

interface IselectedDivestmentItens {
    id: number;
    asset_number: string;
    asset_description: string;
}

interface ItensListProviderProps {
    children: ReactNode;
}

const DivestmentContext = createContext({} as any);

const DivestmentContextProvider = ({ children }: ItensListProviderProps) => {
    const [selectedDivestmentItens, setSelectedDivestmentItens] = useState<IselectedDivestmentItens[]>([]);

    return (
        <DivestmentContext.Provider value={{ selectedDivestmentItens, setSelectedDivestmentItens }}>
            {children}
        </DivestmentContext.Provider>
    )
}

const useDivestmentContext = () => {
    return useContext(DivestmentContext);
}

export { DivestmentContextProvider, useDivestmentContext }