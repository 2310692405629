import { InputDate, Button } from "../../../../components";
import { Text, Stack, Box, Select, } from "native-base";
import { useEffect, useState } from "react";
import Spinner from "react-native-loading-spinner-overlay";
import { getCommunicationHeaderStatus, getCommunicationSummarySelect } from "../../../../services/communicationService";
export interface IHeaderStatusItem {
  id: string;
  name: string;
}

export interface IClassficationStatusItem {
  id: string;
  name: string;
  acronym: string;
}

export const SearchCommunication = ({ handleSearch }: { handleSearch: (status?: string, classfication?: string, initialDate?: string, finalDate?: string) => void }) => {
  const [headerStatusList, setHeaderStatusList] = useState<IHeaderStatusItem[] | undefined>();
  const [ClassficationStatusList, setClassficationStatusList] = useState<IClassficationStatusItem[] | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>('0');
  const [selectedClassfication, setSelectedClassfication] = useState<string | undefined>('0');
  const [isLoading, setIsLoading] = useState(false);
  const [initialDate, setInicialDate] = useState();
  const [finalDate, setFinalDate] = useState();

  useEffect(() => {
    setIsLoading(true);
    async function getheaderStatusList() {
      const data = await getCommunicationHeaderStatus();
      if (data?.status) {
        setHeaderStatusList(data.result);
      }
      setIsLoading(false);
    }
    async function getClassficationStatusList() {
      const data = await getCommunicationSummarySelect();
      if (data?.status) {
        setClassficationStatusList(data.result);
      }
      setIsLoading(false);
    }

    getheaderStatusList();
    getClassficationStatusList();
  }, []);

  return (
    <>
      <Stack
        direction="row"
        space={1}
        alignItems="center"
        px={1}
        py={1}
        _web={{ pt: 20, pb: 5, w: "100%", justifyContent: "space-around" }}
      >
        <Stack direction="column" _web={{ direction: "row" }}>

          <Stack direction="row" space={1} alignItems="center">
            <Text w={20} _web={{ w: 40 }}>
              Selecione o Status:
            </Text>

            <Select
              _web={{ w: "100%" }}
              selectedValue={selectedStatus}
              minWidth="170"
              height="8"
              onValueChange={(itemValue) => {
                setSelectedStatus(itemValue)
              }}
            >
              <Select.Item key={0} label="Todos" value={'0'} />
              {headerStatusList &&
                headerStatusList.map((item) => (
                  <Select.Item
                    key={item.id}
                    label={item.name}
                    value={'' + item.id}
                  />
                ))}
            </Select>

          </Stack>

          <Stack direction="row"
            space={1}
            my={1}
            alignItems="center"
            _web={{ justifyContent: "space-between" }}
          >
            <Text w={20} _web={{ w: 40 }}>
              Selecione o Setor:
            </Text>

            <Select
              _web={{ w: "100%" }}
              selectedValue={selectedClassfication}
              minWidth="170"
              height="8"
              onValueChange={(itemValue) => {
                setSelectedClassfication(itemValue)
              }}
            >
              <Select.Item key={0} label="Todos" value={'0'} />
              {ClassficationStatusList &&
                ClassficationStatusList.map((item) => (
                  <Select.Item
                    key={item.id}
                    label={item.name}
                    value={'' + item.id}
                  />
                ))}
            </Select>
          </Stack>

          <Stack
            direction="row"
            space={2}
            pt={3}
            alignItems="center"
            _web={{ justifyContent: "space-between" }}
          >
            <Box w={127}>
              <InputDate
                width={90}
                name="initialDate"
                value={initialDate}
                setValue={setInicialDate}
              />
            </Box>
            <Box w={127}>
              <InputDate
                width={90}
                name="finalDate"
                value={finalDate}
                setValue={setFinalDate}
              />
            </Box>
          </Stack>

        </Stack>

        <Box>
          <Button
            label="Pesquisar"
            onPress={() => handleSearch(selectedStatus, selectedClassfication, initialDate, finalDate)}
          />
        </Box>
      </Stack>
      <Spinner visible={isLoading} />
    </>
  );
};
