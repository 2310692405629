import { useFormContext } from "../../../contexts";
import { useCallback, useContext, useMemo, useState } from "react";
import { MachineImg } from "../../../../assets/images";
import { useGlobalContext } from "./../../../contexts/globalContext";

interface IResponseData {
  asset_number: any;
  description: string;
  CAF: number;
}

export default function searchMovimentOptions() {
  const { _movimentSelectItens } = useGlobalContext()
  const { watchFields, setValue } = useFormContext();
  const [responseData, setResponseData] = useState<IResponseData[] | null>(
    null
  );

  const searchValue = watchFields["searchMovimentOptions"];

  const filteredData = _movimentSelectItens?.filter(
    (item) =>
      item?.asset_number.includes(searchValue) ||
      item?.asset_description.includes(searchValue?.toUpperCase())
  );

  const handleFilterData = useCallback(() => {
    setResponseData(filteredData);
  }, []);

  useMemo(() => {
    if (searchValue) {
      setResponseData(filteredData);
    }
  }, [searchValue]);

  return {
    setResponseData,
    handleFilterData,
    responseData,
    _movimentSelectItens
  };
}
