import {  InputDate, Button } from "../../../../components";
import {
  Text,
  Stack,
  Box,
  Select,
} from "native-base";

import { useEffect, useState } from "react";
import Spinner from "react-native-loading-spinner-overlay";
import { getRetoolingHeaderStatus } from "../../../../services/retoolingService";

export interface IHeaderStatusItem {
  id: string;
  name: string;
}

export const SearchRetooling = ({
  handleSearch,
}: {
  handleSearch: (
    status?: string,
    initialDate?: string,
    finalDate?: string
  ) => void;
}) => {
  const [headerStatusList, setHeaderStatusList] = useState<
    IHeaderStatusItem[] | undefined
  >();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>('0');
  const [isLoading, setIsLoading] = useState(false);
  const [initialDate, setInicialDate] = useState();
  const [finalDate, setFinalDate] = useState();

  useEffect(() => {
    setIsLoading(true);
    async function getheaderStatusList() {
      const data = await getRetoolingHeaderStatus();
      if (data?.status) {
        setHeaderStatusList(data.result);
      }
      setIsLoading(false);
    }

    getheaderStatusList();
  }, []);

  return (
    <>
      <Stack
        direction="row"
        space={1}
        alignItems="center"
        px={1}
        py={1}
        _web={{ pt: 20, pb: 5, w: "100%", justifyContent: "space-around" }}
      >
        <Stack direction="column" _web={{ direction: "row" }}>
          <Stack direction="row" space={1} alignItems="center">
            <Text w={20} _web={{ w: 40 }}>
              Selecione o Status:
            </Text>

            <Select
              _web={{ w: "150%" }}
              selectedValue={selectedStatus}
              minWidth="150"
              onValueChange={(itemValue) => {
                console.log(itemValue)
                setSelectedStatus(itemValue)}}
            >
              <Select.Item key={0} label="Todos" value={'0'} />
              {headerStatusList &&
                headerStatusList.map((item) => (
                  <Select.Item
                    key={item.id}
                    label={item.name}
                    value={''+item.id}
                  />
                ))}
            </Select>
          </Stack>

          <Stack
            direction="row"
            space={2}
            pt={3}
            alignItems="center"
            _web={{ justifyContent: "space-between" }}
          >
            <Box w={120}>
              <InputDate
                width={80}
                name="initialDate"
                value={initialDate}
                setValue={setInicialDate}
              />
            </Box>
            <Box w={120}>
              <InputDate
                width={80}
                name="finalDate"
                value={finalDate}
                setValue={setFinalDate}
              />
            </Box>
          </Stack>
        </Stack>

        <Box>
          <Button
            label="Pesquisar"
            onPress={() => handleSearch(selectedStatus, initialDate, finalDate)}
          />
        </Box>
      </Stack>
      <Spinner visible={isLoading} />
    </>
  );
};
