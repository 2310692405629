import React from "react";
import { Box, Center, Stack, Text, useTheme, VStack } from "native-base";
import { useInventoryContext, useSupplierContext } from "../../contexts";
import { getInventoryService } from "../../services/inventoryService";
import { formatDate, platformWeb } from "../../utils";
import { CircleStatus } from "../CircleStatus";
import { MiniPieChart } from "../MiniPieChart";

export const InventoryStatus = () => {
  const { colors } = useTheme();
  const {inventoryData, chartData} = useInventoryContext();  

  return (
    <>
      {inventoryData ? (
        <>
          <Center>
            <Text>Inventário Atual</Text>
          </Center>

          <Box pt={3}>
            <Text>
              Início: {formatDate(inventoryData?.planned_start_date)} - Fim:
              {formatDate(inventoryData?.planned_finish_date)}
            </Text>
          </Box>

          <Stack direction="row" pt={1} space={3} alignItems="center">
            <Stack direction="row" space={2} alignItems="center">
              <Text>
                Inv. {"\n"}
                {inventoryData?.status}
              </Text>
              <CircleStatus
                color={
                  inventoryData?.status !== "Em andamento"
                    ? colors.success[100]
                    : colors.warning[50]
                }
              />

              <Stack direction="row" space={2} alignItems="center" ml={1}>
                <Text>
                  {inventoryData?.done} de {inventoryData?.total}
                  {` - ${(
                    (inventoryData?.done / inventoryData?.total) *
                    100
                  ).toFixed(2)}%`}
                </Text>

                {!platformWeb && (
                  <MiniPieChart data={chartData} width={100} height={60} />
                )}
              </Stack>
            </Stack>
          </Stack>
        </>
      ) : (
        <Center>
          <Text>Nenhum inventário aberto</Text>
        </Center>
      )}
    </>
  );
};
