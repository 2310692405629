import {
  Divider,
  Button,
  TextInputSearch,
} from "../../../../components";
import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
  Icon,
  Center,
  View,
  Spinner,
} from "native-base";
import { useFormContext, useNavigatorContext, useProviderContext, useSupplierContext } from "../../../../contexts";
import { FontAwesome } from "@expo/vector-icons";
import { Pressable } from "react-native";
import { DrawerActions } from "@react-navigation/routers";
import { useNavigation } from "@react-navigation/native";
import { platformWeb } from "../../../../utils";
import searchListActives from "../../dataTable";
import { useContext, useEffect, useState } from "react";
import inventoryClosureHook from "../useActivesHook";
import { useGlobalContext } from "../../../../contexts/globalContext";
// import { getActivesList } from "../../../../services/activesService";
import { api } from "../../../../services/api";
import { ActivityIndicator } from "react-native-paper";
import { set } from "date-fns";

export const Content = () => {
  const { colors } = useTheme();
  const { watchFields, setValue } = useFormContext();
  const { currentSupplier } = useSupplierContext()
  const drawerNavigation = useNavigation();
  const { responseData, setResponseData, listActives } = searchListActives();
  const { _setNumberCaf, _setListActivesOne } = useGlobalContext();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit] = useState(20); // Define o limite de itens por página
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");

  function openDrawer(caf: any) {
    _setNumberCaf(caf.id)
    _setListActivesOne(caf)
    drawerNavigation?.dispatch(
      DrawerActions?.openDrawer()
    )
  }
  
  useEffect(() => {
    loadApi();
  }, [watchFields]);

  async function loadApi() {
    // const activeList = await getActivesList(
    //   currentSupplier,
    //   limit,
    //   offset,
    // );
    if(isLoading) return;
    setIsLoading(true);
    const response = await api.get(`/v1/asset_list?supplier_id=${currentSupplier}&limit=${limit}&offset=${offset}&filter=${search}`);
    setData([...data, ...response.data.result]);
    setOffset(offset + limit);
    setIsLoading(false);
  }


  const renderItem = ({ item }: { item: any }) => {
    return (
      <Pressable>
        <Box px={4} py={2} _web={{ px: 1 }}>
          <VStack direction="row" space={2}>
            <Box minWidth={24} _web={{ w: 110 }}>
              <Text>{item?.asset_number}</Text>
            </Box>
            <Box style={{ flex: 1 }} minWidth={200} _web={{ w: 640 }}>
              <Text>{item?.asset_description}</Text>
            </Box>
            <Box minWidth={26} _web={{ w: 90 }}>
              <Center>
                <Pressable
                  onPress={() => openDrawer(item)}
                >
                  <Icon
                    as={FontAwesome}
                    name="ellipsis-v"
                    color={colors.primary[200]}
                    size={7}
                    alignItems="center"
                  />
                </Pressable>
              </Center>
            </Box>
          </VStack>
          <Box mt={3}>
            <Divider />
          </Box>
        </Box>
      </Pressable>
    );
  };

  const renderFooter = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <Spinner visible={isLoading} />
    );
  };
  return (
    <>
      <VStack flex={1} _web={{ px: 0, maxW: "100%", maxH: "80%",}} bg="#fff">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={1}
          px={1}
          my={2}
        >
          <TextInputSearch
            placeholder="Pesquisar"
            onChangeText={(value: string) =>
              value ? setValue("searchListActives", value) : setResponseData(listActives)
            }
          />
        </Stack>

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          _web={{ w: "100%" }}
        >
          <Box minWidth={32} _web={{ w: 90 }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={150} _web={{ w: 640 }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
          <Box minWidth={25} _web={{ w: 100 }}>
            <Text color={colors.white}>Ações</Text>
          </Box>
        </Stack>
        <FlatList
          nestedScrollEnabled
          data={data}
          renderItem={renderItem}
          onEndReached={loadApi}
          keyExtractor={(item, index) => index.toString()}
          onEndReachedThreshold={0.1}
          ListFooterComponent={renderFooter}
          // showsVerticalScrollIndicator={true}
        // contentContainerStyle={{
        //   marginBottom: !platformWeb ? 100 : 10,
        // }}
        />

      </VStack>
    </>
  );
};