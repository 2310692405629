import { Divider } from "../../../../../components";
import { Text, VStack, Stack, Box, useTheme, FlatList, Pressable } from "native-base";
import { formatDate, platformWeb } from "../../../../../utils";
import Spinner from "react-native-loading-spinner-overlay";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";
export interface ISupplierHistoryItem {
  created_at: string;
  operation: string;
  id: string;
}

export interface ISupplierHistoryListProps {
  supplierHistoryListData: ISupplierHistoryItem[] | undefined;
  navigation: DrawerNavigationProp<ParamListBase>;
  onEndReached: () => void;
  onEndReachedThreshold: number;
  isLoading: boolean;
}

export const SupplierHistoryList = ({
  supplierHistoryListData,
  navigation,
  onEndReached,
  onEndReachedThreshold,
  isLoading,
}: ISupplierHistoryListProps) => {
  const { colors } = useTheme();

  return (
    <>
      {supplierHistoryListData && supplierHistoryListData.length > 0 ? (
        <>
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            bg={colors.primary[50]}
            px={3}
            py={2}
            _web={{ w: "100%", justifyContent: "space-between" }}
          >
            <Box minWidth={20} _web={{ w: 100 }}>
              <Text color={colors.white}>Data</Text>
            </Box>
            <Box minWidth={160} _web={{ w: 200 }}>
              <Text color={colors.white}>Tipo de Registro</Text>
            </Box>
            <Box minWidth={38} _web={{ w: 60 }}>
              <Text color={colors.white}>Id</Text>
            </Box>
          </Stack>
          <Box h={'lg'}>
            <FlatList
              nestedScrollEnabled
              showsVerticalScrollIndicator={true}
              data={supplierHistoryListData}
              keyExtractor={supplierHistoryListData => supplierHistoryListData?.id?.toString()}
              onEndReached={onEndReached}
              onEndReachedThreshold={onEndReachedThreshold}
              contentContainerStyle={{
                marginBottom: !platformWeb ? 100 : 10,
              }}
              ListFooterComponent={isLoading ? <Spinner visible={isLoading} /> : null}
              renderItem={({ item }) => {
                return (
                  <Pressable onPress={() => navigation.navigate("SupplierHistoryDetail", {
                    item: item,
                  })}>
                    <Box px={4} py={2} _web={{ px: 1 }}>
                      <VStack direction="row" space={2} _web={{ justifyContent: "space-between" }}>
                        <Box minWidth={20} _web={{ w: 110 }}>
                          <Text>{formatDate(item?.created_at)}</Text>
                        </Box>
                        <Box minWidth={160} _web={{ w: 200 }}>
                          <Text>{item?.operation}</Text>
                        </Box>
                        <Box minWidth={45} _web={{ w: 60 }}>
                          <Text>{item?.id}</Text>
                        </Box>
                      </VStack>
                      <Box mt={3}>
                        <Divider />
                      </Box>
                    </Box>
                  </Pressable>
                );
              }}
            />
          </Box>
        </>
      ) : (
        <Stack alignItems={"center"} p={5} h={"90%"}>
          <Text>Nenhuma solicitação encontrada</Text>
        </Stack>
      )
      }
    </>
  );
}
