import { DisinvestmentSelectScreen, NewDisinvestmentItemDetailScreen, NewDisinvestmentItemListScreen, NewDisinvestmentItemSummaryScreen, NewDisinvestmentScreen } from "../screens";
import { createStackNavigator } from "@react-navigation/stack";

const DisinvestmentStack = createStackNavigator();

export const DisinvestmentNavigator = () => {
  return (
    <DisinvestmentStack.Navigator id="DisinvestmentStack" screenOptions={{ headerShown: false, }}>
      <DisinvestmentStack.Screen
        name="DisinvestmentScreen"
        component={NewDisinvestmentScreen}
      />
      <DisinvestmentStack.Screen
        name="DisinvestmentItemListScreen"
        component={NewDisinvestmentItemListScreen}
      />
      <DisinvestmentStack.Screen
        name="DisinvestmentSelectItemListScreen"
        component={DisinvestmentSelectScreen}
      />
      <DisinvestmentStack.Screen
        name="DisinvestmentItemDetailScreen"
        component={NewDisinvestmentItemDetailScreen}
      />
      <DisinvestmentStack.Screen
        name="DisinvestmentItemSummaryScreen"
        component={NewDisinvestmentItemSummaryScreen}
      />
    </DisinvestmentStack.Navigator>
  );
};
