import { useEffect, useState } from "react";
import { Divider, Button } from "../../../../components";
import {
  View,
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
  Icon,
  HStack,
} from "native-base";
import { Pressable } from "react-native";
import {
  useCommunicationContext,
  useSupplierContext,
} from "../../../../contexts";
import Spinner from "react-native-loading-spinner-overlay/lib";
import moment from "moment";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
import { platformWeb } from "../../../../utils";
import {
  deleteCommunicationItem,
  getCommunicationDetailList,
  getCommunicationHeader,
} from "../../../../services/communicationService";
interface ICommunicationHeader {
  creation_date: string;
  status: string;
  id: string;
  communication_order_header_status_id: number;
}
interface ICommunicationItem {
  id: string;
  asset_number: string;
  asset_description: string;
  communication_order_detail_status_id: number;
}

export const Content = ({
  route,
  navigation,
}: {
  route: RouteProp<ParamListBase, string>;
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { colors } = useTheme();
  const { headerId } = route.params;
  const { currentSupplier } = useSupplierContext();
  const [isLoading, setIsLoading] = useState(false);
  const { selectedCommunicationItens, setSelectedCommunicationItens } =
    useCommunicationContext();
  const [communicationHeaderInfo, setCommunicationHeaderInfo] =
    useState<ICommunicationHeader>();

  async function getCommunicationDetails() {
    if (headerId) {
      const headerData = await getCommunicationHeader(headerId);
      const listData = await getCommunicationDetailList(headerId);
      if (headerData?.status) {
        setCommunicationHeaderInfo(headerData.result);
      }
      if (listData?.status) {
        setSelectedCommunicationItens(listData.result);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    getCommunicationDetails();
  }, [route]);

  async function handleDelete(itemId: string) {
    setIsLoading(true);
    await deleteCommunicationItem(itemId);
    await getCommunicationDetails();
    setIsLoading(false);
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, maxW: "100%" }} bg="#fff">
        {communicationHeaderInfo ? (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={5}
          >
            <Text>
              {`${moment(communicationHeaderInfo.creation_date).format(
                "DD/MM/YYYY"
              )}`}{" "}
              {`${communicationHeaderInfo?.status}`}
            </Text>
            <Text>Id: {`${communicationHeaderInfo?.id}`}</Text>
          </Stack>
        ) : (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={5}
          ></Stack>
        )}

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          justifyContent={"space-between"}
        >
          <Box w={110} _web={{ minWidth: 200 }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={"35%"} _web={{ minWidth: "75%" }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
          {communicationHeaderInfo?.communication_order_header_status_id ===
            1 && (
            <Box minWidth={30} _web={{ minWidth: 20 }}>
              <Text color={colors.white}>Remover</Text>
            </Box>
          )}
        </Stack>

        <Box flex={1} _web={{ maxH: "lg", overflow: "hidden" }}>
          <FlatList
            nestedScrollEnabled
            data={selectedCommunicationItens}
            contentContainerStyle={{
              marginBottom: 100,
            }}
            renderItem={({ item, index }) => {
              return (
                <Box
                  px={4}
                  py={2}
                  justifyContent={"space-between"}
                  _web={{ px: 1 }}
                >
                  <VStack
                    direction="row"
                    space={2}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box minWidth={24} _web={{ minWidth: 200 }}>
                      <Text>{item.asset_number}</Text>
                    </Box>
                    <Box w={200} _web={{ minWidth: "75%" }}>
                      <Text>{item.asset_description}</Text>
                    </Box>
                    {communicationHeaderInfo?.communication_order_header_status_id ===
                      1 && (
                      <Box w={50} _web={{ w: 70 }}>
                        <Pressable onPress={() => handleDelete(item.id)}>
                          <Icon
                            as={FontAwesome}
                            name="trash"
                            color={colors.primary[200]}
                            size={7}
                          />
                        </Pressable>
                      </Box>
                    )}
                  </VStack>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                </Box>
              );
            }}
          />
        </Box>

        {communicationHeaderInfo?.status !== "Realizado" ? (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="center"
            px={3}
            py={2}
            _web={{
              mt: 4,
              justifyContent: "space-between",
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              background: "#fff",
            }}
          >
            {platformWeb ? (
              <Button
                label="Voltar"
                w="33%"
                onPress={() =>
                  navigation.navigate("CommunicationScreen1", {
                    currentSupplier,
                  })
                }
              />
            ) : null}
            {communicationHeaderInfo?.communication_order_header_status_id ===
              1 && (
              <Button
                label="SELECIONAR ITENS"
                w="49%"
                _web={{ w: "33%" }}
                onPress={() =>
                  navigation.navigate("CommunicationSelectItemListScreen", {
                    headerId,
                  })
                }
              />
            )}
            <Button
              label="Ver Sumário"
              w="49%"
              _web={{ w: "33%" }}
              onPress={() =>
                navigation.navigate("CommunicationItemSummaryScreen", {
                  headerId,
                })
              }
            />
          </Stack>
        ) : (
          <VStack></VStack>
        )}
      </VStack>
      <Spinner visible={isLoading} />
    </>
  );
};
