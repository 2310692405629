import { useNavigatorContext } from "./../../../contexts/navigatorContext";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { getMovimentDetailList, postMovimentAddItem } from "./../../../services/movimentService";
import { useContext, useState } from "react";

export default function movimentItemsHook() {
  const { navigateTo } = useNavigatorContext();
  const [isLoading, setIsLoading] = useState(false);
  const { addItemsMoviment } = useGlobalContext();

  async function postAddItemMoviment(moviment_header_id: number, asset_numbers: any) {
    setIsLoading(true);
    const data = await postMovimentAddItem(moviment_header_id, asset_numbers);
    if (data?.status) {
      getDetailListMoviment(moviment_header_id)

    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getDetailListMoviment(retooling_header_id: number) {
    const data = await getMovimentDetailList(retooling_header_id);
    if (data?.status) {
      addItemsMoviment(data.result)
      setIsLoading(false);
      navigateTo('MovimentApprovedScreen')
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  return {
    postAddItemMoviment,
    getDetailListMoviment,
    isLoading
  }
}