import React from "react";

export interface IListPhotos {
  uri: string;
  namePhoto: string;
}

export function useCamera() {
  const [openCamera, setOpenCamera] = React.useState(false);
  const [currentNamePhoto, setCurrentNamePhoto] = React.useState<string>("");
  const [listPhotos, setListPhotos] = React.useState<IListPhotos[]>([
    {
      namePhoto: "Foto CAF",
      uri: "",
    },
    {
      namePhoto: "Frontal",
      uri: "",
    },
    {
      namePhoto: "Lateral",
      uri: "",
    },
    {
      namePhoto: "Superior",
      uri: "",
    },
  ]);

  const removePhoto = (namePhoto: string) => {
    const index = listPhotos.findIndex((item) => item.namePhoto === namePhoto);

    if (index === -1) return;

    listPhotos[index].uri = "";
    setListPhotos([...listPhotos]);
  };

  const handleOpenCamera = (namePhoto: string) => {
    removePhoto(namePhoto);

    setOpenCamera(true);
    setCurrentNamePhoto(namePhoto);
  };

  const handleCloseCamera = () => {
    setOpenCamera(false);
    setCurrentNamePhoto("");
  };

  const handleSavePhotos = (uri: any) => {
    const index = listPhotos.findIndex(
      (item) => item.namePhoto === currentNamePhoto
    );
    listPhotos[index].uri = uri;

    setListPhotos([...listPhotos]);

    handleCloseCamera();
    setOpenCamera(false);
  };

  const handleClearPhotos = () => {
    setListPhotos([
      {
        namePhoto: "Foto CAF",
        uri: "",
      },
      {
        namePhoto: "Frontal",
        uri: "",
      },
      {
        namePhoto: "Lateral",
        uri: "",
      },
      {
        namePhoto: "Superior",
        uri: "",
      },
    ]);
  };

  return {
    openCamera,
    listPhotos,
    handleOpenCamera,
    handleSavePhotos,
    handleCloseCamera,
    handleClearPhotos,
  };
}
