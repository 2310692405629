import { TemplateWebPages } from "../../components";
import { Center, Image, Pressable, Stack, Text, VStack } from "native-base";
import { MainMenu } from "./components/MainMenu";
import { CurrentProvider } from "./components/CurrentProvider";
import { platformWeb } from "../../utils";
import packageJson from "../../../package.json";
import { BottomNavigation } from "../../components";
import { LogoPoweredBy } from "../../../assets/images";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";

export const HomeScreen = ({ navigation }: DrawerScreenProps<ParamListBase>) => {
  return (
    <VStack flex={1}>
      {platformWeb ? (
        <TemplateWebPages navigation={navigation}>
          <Center>
            <Pressable>
              <Image
                source={LogoPoweredBy}
                alt={"Alt Image"}
                resizeMode="contain"
                size="lg"
              />
            </Pressable>
            <Stack alignSelf={'center'} mb={5}>
              <Text fontSize={10}>Version: {packageJson.version}</Text>
            </Stack>
            <CurrentProvider navigation={navigation} />
          </Center>
        </TemplateWebPages>
      ) : (
        <>
          <CurrentProvider navigation={navigation} />
          <MainMenu navigation={navigation} />
          <BottomNavigation navigation={navigation} />
        </>
      )}
    </VStack>
  );
};
