import { putInventoryClose } from "../../../services/inventoryService";

export default function inventoryCloseHook() {
  
  async function submitInventoryClose(id: number) {
    const data = await putInventoryClose(id);
      if (data?.status) {
        console.log(data)
        }
      }

  return {
    submitInventoryClose
  };
}