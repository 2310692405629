import { NewAssetHistoryScreen, NewAssetHistoryDetail } from "../screens";
import { createStackNavigator } from "@react-navigation/stack";

const AssetHistoryStack = createStackNavigator();

export const AssetHistoryNavigator = () => {
    return (
        <AssetHistoryStack.Navigator id="AssetHistoryStack" screenOptions={{ headerShown: false, }}>
            <AssetHistoryStack.Screen
                name="AssetHistoryScreen"
                component={NewAssetHistoryScreen}
            />
            <AssetHistoryStack.Screen
                name="AssetHistoryDetail"
                component={NewAssetHistoryDetail}
            />

        </AssetHistoryStack.Navigator>
    );
};