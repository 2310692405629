import React from "react";
import * as Location from 'expo-location';
import { postInventoryGenerateUrls, putInventorySend, uploadFile } from "../../../services/inventoryService";

export function useSendInventory() {
  async function getLocation() {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      throw new Error("Permissão de localização é necessária");
    }
    const location = await Location.getCurrentPositionAsync({});
    const lat = location.coords.latitude;
    const lng = location.coords.longitude;
    return { lat, lng }
  }

  async function getUrls(itemId: number | undefined) {
    if (!itemId) {
      throw new Error("ID do item não encontrado");
    }
    try {
      const urls = await postInventoryGenerateUrls(itemId);
      return urls.result;
    } catch (error) {
      throw new Error("Erro na geração das URLs assinadas");
    }
  }

  async function uploadPhoto(url: any, image: any) {
    try {
      await uploadFile(url, image);
      const indexOf = url.indexOf('?')
      console.log(indexOf);
      const urlDiminuida = url.substring(0, indexOf)
      console.log(urlDiminuida)
      return urlDiminuida;
    } catch (error) {
      throw new Error("Erro ao fazer o upload das fotos");
    }
  }

  async function updateItem(itemId: number | undefined, urlImages: any, itemData: any) {
    if (!itemId) {
      throw new Error("ID do item não encontrado");
    }
    try {
      const response = await putInventorySend(itemId, urlImages, itemData)
      return response;
    } catch (error) {
      throw new Error("Erro ao fazer o update do item");
    }
  }

  return {
    getLocation,
    getUrls,
    uploadPhoto,
    updateItem,
  };
}
