import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
} from "@react-navigation/drawer";
import { Image, Stack, Text, useTheme, View } from "native-base";
import {
  EnterCodeScreen,
  HomeScreen,
  InventoryClosureScreen,
  InventoryScreen,
  ItemDetailScreen,
  LoginScreen,
  ReportsScreen,
} from "../screens";
import { Divider, HeaderApp, SelectProvider } from "../components";
import { Logoff, UpdateProvider } from "../../assets/images";
import { useAuthContext } from "../contexts";
import packageJson from '../../package.json';
import { DrawerActives } from "./ModalActives";
import { DisinvestmentNavigator } from "./disinvestmentNavigator";
import { CommunicationNavigator } from "./communicationNavigator";
import { MovementNavigator } from "./movementNavigator";
import { RetoolingNavigator } from "./retoolingNavigator";
import { ActiveslistNavigator } from "./activesListNavigator";
import { AssetHistoryNavigator } from "./AssetHistoryNavigator";
import { SupplierHistoryNavigator } from "./SupplierHistoryNavigator";
import { DashboardNavigator } from "./dashboardNavigator";
// import { NotificationsNavigator } from "./notificationsNavigator";

const Drawer = createDrawerNavigator();

export const DrawerNavigator = () => {
  const { signOut } = useAuthContext();
  const { colors } = useTheme();

  function handleLogout(screenName: string) {
    if (screenName === "AuthScreen") {
      signOut();
    }
  }

  const drawerOptions = [
    {
      label: "Logoff",
      icon: Logoff,
      screenName: "AuthScreen",
    },
    {
      label: "Trocar de Fornecedor",
      icon: UpdateProvider,
      screenName: null,
    },
  ];

  return (
    <Drawer.Navigator
      id="DefaultDrawer"
      initialRouteName="HomeScreen"
      screenOptions={{
        headerShown: true,

        header: (props) => <HeaderApp {...props} />,
      }}
      useLegacyImplementation
      drawerContent={(props: any) => (
        <View flex={1} bg="#243783" px={5} justifyContent="space-between">
          <Stack h={200}>
            <DrawerContentScrollView {...props}>
              {drawerOptions?.map(({ label, icon, screenName }, index) => (
                <View key={index}>
                  <DrawerItem
                    label={() => <Text color={colors.white}>{label}</Text>}
                    icon={() => (
                      <Image
                        source={icon}
                        alt="Update provider icon"
                        resizeMode="contain"
                        size="xs"
                      />
                    )}
                    onPress={() => screenName && handleLogout(screenName)}
                  />
                  {screenName && <Divider />}
                </View>
              ))}
            </DrawerContentScrollView>
            <Stack>
              <SelectProvider whiteMode navigation={props.navigation} />
            </Stack>
          </Stack>
          <Stack alignSelf={'center'} mb={5}>
            <Text color={colors.white} fontSize={16}>V {packageJson.version}</Text>
          </Stack>
        </View>
      )}
    >
      <>
        <Drawer.Screen name="HomeScreen" component={HomeScreen} />
        <Drawer.Screen name="InventoryClosureScreen" component={InventoryClosureScreen} />
        <Drawer.Screen name="MovimentScreen" component={MovementNavigator} />
        <Drawer.Screen name="DivestmentScreen" component={DisinvestmentNavigator} />
        <Drawer.Screen name="CommunicationScreen" component={CommunicationNavigator} />
        <Drawer.Screen name="RetoolingScreen" component={RetoolingNavigator} />
        <Drawer.Screen name="InventoryScreen" component={InventoryScreen} />
        <Drawer.Screen name="EnterCodeScreen" component={EnterCodeScreen} />
        <Drawer.Screen name="ItemDetailScreen" component={ItemDetailScreen} />
        <Drawer.Screen name="ReportsScreen" component={ReportsScreen} />
        <Drawer.Screen name="ActiveDrawer" component={ActiveslistNavigator} />
        <Drawer.Screen name="AssetHistory" component={AssetHistoryNavigator} />
        <Drawer.Screen name="SupplierHistory" component={SupplierHistoryNavigator} />
        <Drawer.Screen name="Dashboard" component={DashboardNavigator} />
        {/* <Drawer.Screen name="Notifications" component={NotificationsNavigator} /> */}
        <Drawer.Screen name="AuthScreen" component={LoginScreen} options={{ headerShown: false }} />
      </>
    </Drawer.Navigator>
  );
};
