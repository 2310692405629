import { Divider } from "../../../../components";
import {
  Box,
  FlatList,
  Image,
  Stack,
  VStack,
  useTheme,
  Text,
  View,
} from "native-base";
import { Platform, Pressable } from "react-native";
import { menuOptions } from "../../../../utils/menuOptions";
import { platformWeb, platformAndroid } from "../../../../utils";
import { Home } from "../../../../../assets/images";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";
import { useAuthContext } from "../../../../contexts";

export const MainMenu = ({
  navigation,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { signOut } = useAuthContext();
  const { colors } = useTheme();

  function handleClick(screenName: string) {
    if (screenName === "Logoff") {
      signOut();
    } else {
      navigation.navigate(screenName);
    }
  }

  return (
    <>
      <View
        flex={1}
        mb={1}
        _android={{
          bg: "#fff",
        }}
      >
        {platformWeb && (
          <Pressable onPress={() => navigation.navigate("HomeScreen")}>
            <Stack direction="row" mt={2} alignItems="center" px={10}>
              <Box>
                <Image
                  source={Home}
                  alt="Logo Tool Control"
                  resizeMode="contain"
                  size="xs"
                  tintColor={
                    platformWeb ? colors.white : colors.black
                  }
                />
              </Box>
              <Text ml={5} _web={{ color: colors.white }}>
                Início
              </Text>
            </Stack>
          </Pressable>
        )}

        {/* Dynamic menu list */}
        <FlatList
          nestedScrollEnabled
          data={menuOptions}
          contentContainerStyle={{
            marginBottom: 100,
          }}
          renderItem={({ item, index }) => {
            if ((platformAndroid) && item.webOnly){
              return null;
            } else {
              return (
                <>
                  <VStack mb={index === menuOptions?.length - 1 ? 3 : 0}>
                    {index === menuOptions?.length - 1 && (
                      <Box mt={1} mb={2}>
                        <Divider />
                      </Box>
                    )}
                    <Pressable
                      onPress={() =>
                        item?.screenName && handleClick(item?.screenName)
                      }
                    >
                      <Stack direction="row" mt={2} alignItems="center" px={10}>
                        <Box>
                          <Image
                            source={item?.icon}
                            alt="Logo Tool Control"
                            resizeMode="contain"
                            size="xs"
                            tintColor={platformWeb ? colors.white : colors.black}
                          />
                        </Box>
                        <Text ml={5} _web={{ color: colors.white }}>
                          {item?.label}
                        </Text>
                      </Stack>
                    </Pressable>
                  </VStack>
                </>
              );
            }
          }}
        />
      </View>
    </>
  );
};
