import { handleScreeName } from "../utils";
import { useNavigation } from "@react-navigation/native";
import { useContext, createContext, useCallback, useState } from "react";

interface INavigatorContext {
  navigateTo: (path: string) => void;
  screenName: string;
  navigation: any;
  navigateToPrevPage: () => any;
}

const NavigatorContext = createContext({} as INavigatorContext);

const NavigatorContextProvider = ({ children }: any) => {
  const navigation = useNavigation<any>();
  const [screenName, setScreenName] = useState("Menu Principal");

  const navigateTo = useCallback((path: any) => {
    setScreenName(handleScreeName(path));
    navigation.navigate(path);
  }, []);

  function navigateToPrevPage() {
    switch (screenName) {
      case "Digitar Código":
        return navigateTo("InventoryScreen");
      case "Retooling - Selecionar Itens":
        return navigateTo("RetoolingScreen");
      case "Retooling - Consulta Itens Selecionados - A":
        return navigateTo("RetoolingScreen");
      case "Retooling - Consulta Itens Selecionados - P":
        return navigateTo("RetoolingScreen");
      case "Retooling - Selecionar Itens":
        return navigateTo("RetoolingScreen");
      case "Retooling - Detalhe do Item":
        return navigateTo("RetoolingScreen");  
      case "Detalhamento do CAF":
        return navigateTo("ActivesScreen");
      case "Lista de Part Numbers":
        return navigateTo("ActivesScreen");
      case "Desinvestimento - Selecionar Itens":
        return navigateTo("DivestmentScreen");
      case "Desinvest. - Consulta Itens Selecionados I":
        return navigateTo("DivestmentScreen");
      case "Desinvest. - Consulta Itens Selecionados A":
        return navigateTo("DivestmentScreen");
      case "Desinvest. - Detalhes dos Itens":
        return navigateTo("DivestmentScreen");
      case "Movimentação - Sumário A":
        return navigateTo("MovimentSummary");
      case "Movimentação - Sumário":
        return navigateTo("MovimentApprovedScreen");
      case "Movimentação - Selecionar Itens A":
        return navigateTo("MovimentScreen");
      case "Movimentação - Selecionar Itens I":
        return navigateTo("MovimentScreen");
      case "Movimentação - Selecionar Itens":
        return navigateTo("MovimentScreen");
        

      default:
        return navigateTo("home");
    }
  }
  
  return (
    <NavigatorContext.Provider
      value={{
        navigateTo,
        screenName,
        navigation,
        navigateToPrevPage,
      }}
    >
      {children}
    </NavigatorContext.Provider>
  );
};

const useNavigatorContext = () => {
  return useContext(NavigatorContext);
};

export { NavigatorContextProvider, useNavigatorContext };
