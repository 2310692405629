import { useAuthContext, useModalAlertContext, useSupplierContext } from "../../../../../contexts";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native";
import { VStack } from "native-base";
import { useCallback, useState } from "react";
import moment from "moment";
import { SupplierHistoryList } from "../SupplierHistoryList";
import { getLogsList } from "../../../../../services/logsService";
import { ModalAlert } from "../../../../../components/ModalAlert";
import Spinner from "react-native-loading-spinner-overlay";

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase>; }) => {
  const { currentSupplier } = useSupplierContext();
  const { token } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [searchStatus, setSearchStatus] = useState<string>();
  const [offset, setOffset] = useState(0);
  const [limit] = useState(15);
  const [asset_number, setAssetNumber] = useState("");
  const [_selectStatusSet, _setSelectStatusSet] = useState<any>({ "id": 0, "name": "Todos" });
  const [searchInitialDate, setSearchInitialDate] = useState<string>();
  const [searchFinalDate, setSearchFinalDate] = useState<string>();
  const [supplierHistoryListData, setSupplierHistoryListData] = useState<any[]>([]);

  const { showModal, setShowModal } = useModalAlertContext();

  const handleCloseAlert = () => {
    setShowModal(false);
    navigation.navigate("HomeScreen");
  };

  useFocusEffect(
    useCallback(() => {

      const getAssetHistoryList = async () => {
        setIsLoading(true);

        try {
          loadApi();
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      getAssetHistoryList();
    }, [route, currentSupplier])
  );

  async function getSupplierHistoryListData(id: number, asset_number: string, offset: number, limit: number) {
    try {
      const logsList = await getLogsList(id, asset_number, offset, limit);

      if (logsList?.status) {
        return logsList.result;
      } else {
        return [];
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 400 && !currentSupplier) {
        setShowModal(true);
      }
      return [];
    }
  }

  async function loadApi() {
    if (isLoading) return;
    setIsLoading(true);
    const response = await getSupplierHistoryListData(currentSupplier, asset_number, offset, limit);
    if (supplierHistoryListData) {
      setSupplierHistoryListData([...supplierHistoryListData, ...response]);
    }

    if (response.length > 0) {
      setOffset(offset + limit);
    }

    setIsLoading(false);
  }

  function handleSearch(
    status?: string,
    initialDate?: string,
    finalDate?: string
  ) {
    let iDate;
    let fDate;

    if (initialDate) {
      iDate = moment(initialDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    }
    if (finalDate) {
      fDate = moment(finalDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    }

    if (status === "0") {
      setSearchStatus(undefined);
    } else {
      setSearchStatus(status);
    }
    setSearchInitialDate(iDate);
    setSearchFinalDate(fDate);
  }

  return (
    <>
      <VStack flex={1} mt={2} _web={{ px: 20, w: "100%" }} bg="#fff">
        {/* <SearchSupplierHistory handleSearch={handleSearch} /> */}
        <SupplierHistoryList
          supplierHistoryListData={supplierHistoryListData}
          navigation={navigation}
          onEndReached={loadApi}
          onEndReachedThreshold={0.1}
          isLoading={isLoading}
        />
      </VStack >

      <ModalAlert isVisible={showModal} onClose={handleCloseAlert} />
      <Spinner visible={isLoading} />
    </>
  )
}