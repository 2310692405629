import { Modal, Platform, StyleSheet, TouchableOpacity } from "react-native";
import { Image, Text, VStack, Pressable } from "native-base";
import { useState } from "react";

declare interface IListPhotos {
  uri: string;
  namePhoto: string;
  icon: any;
}

export const ZoomableImage: React.FC<{ item: IListPhotos }> = ({ item }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const toggleZoom = () => {
    setIsZoomed((prev) => !prev);
  };

  return (
    <VStack alignItems="center" space={1}>
      <Text>{item?.namePhoto}</Text>
      <Pressable onPress={toggleZoom} disabled={item.uri ? false : true}>
        <Image
          source={item?.uri ? { uri: item?.uri } : item.icon}
          alt={item?.namePhoto}
          size="md"
          borderRadius={5}
        />
      </Pressable>

      <Modal visible={isZoomed} onRequestClose={toggleZoom}>
        <TouchableOpacity
          onPress={toggleZoom}
          activeOpacity={1}
          style={styles.modalContainer}
        >
          <Image
            source={item.uri ? { uri: item.uri } : item.icon}
            resizeMode={Platform.OS === "web" ? "contain" : undefined}
            style={styles.modalImage}
          />
        </TouchableOpacity>
      </Modal>
    </VStack>
  );
};

const styles = StyleSheet.create({
  image: {
    borderRadius: 5,
    overflow: "hidden",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modalImage: {
    width: "80%",
    height: "80%",
    borderRadius: 5,
  },
});
