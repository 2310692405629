import { ReactNode, createContext, useContext, useState } from "react";

interface IselectedMovementItens {
    id: number;
    asset_number: string;
    asset_description: string;
}

interface ItensListProviderProps {
    children: ReactNode;
}

const MovementContext = createContext({} as any);

const MovementContextProvider = ({ children }: ItensListProviderProps) => {
    const [selectedMovementItens, setSelectedMovementItens] = useState<IselectedMovementItens[]>([]);

    return (
        <MovementContext.Provider value={{ selectedMovementItens, setSelectedMovementItens }}>
            {children}
        </MovementContext.Provider>
    )
}

const useMovementContext = () => {
    return useContext(MovementContext);
}

export { MovementContextProvider, useMovementContext }