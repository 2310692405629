import { BottomNavigation, TemplateWebPages } from "../../components";
import { ScrollView, VStack } from "native-base";
import { platformWeb } from "../../utils";
import { ParamListBase } from "@react-navigation/native";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { Content } from "./Components/Content";


export const NewMovementItemListScreen = ({ route, navigation, }: DrawerScreenProps<ParamListBase>) => {
  return (
    <>
      <VStack flex={1}>
        {platformWeb ? (
          <ScrollView> 
          <TemplateWebPages navigation={navigation}>
            <Content route={route} navigation={navigation} />
          </TemplateWebPages>
          </ScrollView>
        ) : (
          <>
            <Content route={route} navigation={navigation} />
            <BottomNavigation navigation={navigation} />
          </>
        )}
      </VStack>
    </>
  );
};
