import { NewSupplierHistoryScreen, NewSupplierHistoryDetail } from "../screens";
import { createStackNavigator } from "@react-navigation/stack";

const SupplierHistoryStack = createStackNavigator();

export const SupplierHistoryNavigator = () => {
  return (
    <>
      <SupplierHistoryStack.Navigator id="SupplierHistoryStack" screenOptions={{ headerShown: false, }}>
        <SupplierHistoryStack.Screen
          name="SupplierHistoryScreen"
          component={NewSupplierHistoryScreen}
        />
        <SupplierHistoryStack.Screen
          name="SupplierHistoryDetail"
          component={NewSupplierHistoryDetail}
        />
      </SupplierHistoryStack.Navigator>
    </>
  );
};
