import { Button, TextInput } from "../../../../components";
import { platformWeb } from "../../../../utils";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { Box, Divider, HStack, Stack, Text, VStack, View, useTheme } from "native-base";
import Spinner from "react-native-loading-spinner-overlay";

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase> }) => {
  const { item } = route.params;
  const { colors } = useTheme();

  if (!item) {
    return (
      <View>
        <Spinner visible={!item} />
      </View>
    );
  }

  return (
    <VStack flex={1} w={"full"} mt={2} _web={{ px: 20 }} bg="#fff">
      <Stack
        direction="row"
        space={2}
        alignItems="center"
        bg={colors.primary[50]}
        px={3}
        py={2}
      >
        <Box minWidth={100} _web={{ w: "100%" }}>
          <Text color={colors.white}>Resumo da Ordem</Text>
        </Box>
      </Stack>

      <VStack space={1} alignItems="flex-start" w="100%">
        {Object.entries(item.description).map(([label, value]) => {
          if (value !== null && value !== "Invalid date") {
            return (
              <View
                key={label}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: 8,
                  alignItems: 'center',
                }}
              >
                <Text style={{ width: '65%' }}>{label}</Text>
                <TextInput
                  style={{
                    width: '35%',
                    fontSize: 14,
                    textAlign: 'right',
                  }}
                  editable
                  value={value?.toString()}
                />
              </View>
            );
          }
          return null;
        })}
      </VStack>

      {
        platformWeb ? (
          <Box alignItems="center" mt={4}>
            <Button
              _web={{ w: "40%" }}
              w="30%"
              label="VOLTAR"
              onPress={() => navigation.navigate("AssetHistoryScreen")}
            />
          </Box>
        ) : null
      }
    </VStack >
  );
};