import React, {createContext, useState} from 'react';
import { useNavigatorContext } from './navigatorContext';

/*interface ICloseInventoryContext {
  
}*/

export const CloseInventoryContext = createContext({});

function CloseInventoryProvider({ children }: any){
  const [finishInventory, setFinishInventory] = useState({})
  const { navigateTo } = useNavigatorContext();

  function addFinishInventory(data: any){
    setFinishInventory(data);
    navigateTo("InventoryClosureScreen")
  }

  return (
    <CloseInventoryContext.Provider value={{ addFinishInventory, finishInventory }}>
      {children}
    </CloseInventoryContext.Provider>
  )
}

export default CloseInventoryProvider