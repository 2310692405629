import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { TabView } from "react-native-tab-view";
import { handleIndexChange } from "./functions/handleIndexChange";
import { renderLazyPlaceholder } from "./functions/renderLazyPlaceholder";
import { IRenderScene, renderSceneFormatList } from "./functions/renderScene";
import { renderTabBar } from "./functions/renderTabBar";
import { useInventoryContext } from "../../contexts";
import inventoryHook from "../../screens/Inventory/hook/useInventory";
import { useTabInventory } from "../../screens/Inventory/hook/useTabInventory";
import { Performed } from "./../../screens/Inventory/Tabs/Performed";
import { Pending } from "./../../screens/Inventory/Tabs/Pending";
import { Analysis } from "./../../screens/Inventory/Tabs/Analysis";
import { useGlobalContext } from "../../contexts/globalContext";


interface ITabCustom {
  listRenderTabs: IRenderScene[];
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
  routes: { key: string; title: string }[];
}

/**
 * TabCustom
 * @param listRenderTabs: IRenderScene[] - List of tabs to be rendered
 * @param index: number - Index of the current tab
 * @param setIndex: Dispatch<SetStateAction<number>> - Function to change the index of the current tab
 * @param routes: { key: string; title: string }[] - List of routes to be rendered
 * @return JSX.Element
 * @example
 * <TabCustom
 *  listRenderTabs={listRenderTabs}
 * index={index}
 * setIndex={setIndex}
 * routes={routes}
 * />
 */
export function TabCustom({
  listRenderTabs,
  index,
  routes,
  setIndex,

}: ITabCustom) {
  const { _flatListInventory } = useGlobalContext()
  const renderScene = renderSceneFormatList({
    listRenderTabs,
  });
  const { attStatus } = inventoryHook();
  const [tabActivity, setTabActivity] = useState(0);
  const { setUpdateCount, updateCount } = useTabInventory();

  const [_mudou, _setMudou] = useState<number>(0)



  function handleTabActivity(index: number) {
    setIndex(index);
    setTabActivity(index);
    _setMudou(_mudou + 1)

  }

  useEffect(() => {
    handleTabActivity(index)
  }, [index])

  return (
    <TabView
      lazy
      navigationState={{ index, routes }}
      renderScene={({ route }) => {
        switch (route.key) {
          case 'performed':
            return <Performed _mudou={_mudou} _flatListInventory={_flatListInventory} />;
          case 'pending':
            return <Pending _mudou={_mudou} _flatListInventory={_flatListInventory} />;
          case 'analysis':
            return <Analysis _mudou={_mudou} _flatListInventory={_flatListInventory} />;
          default:
            return null;
        }
      }}
      renderTabBar={(props) =>
        renderTabBar({ props, routes, handleTabActivity, tabActivity })
      }
      onIndexChange={(index: number) =>
        handleIndexChange({ index, handleTabActivity })
      }
      renderLazyPlaceholder={({ route }) => renderLazyPlaceholder({ route })}
      initialLayout={{ width: Dimensions.get("window").width }}
    />
  );
}
