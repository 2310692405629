import { NewCommunicationScreen, NewCommunicationItemListScreen, CommunicationSelectScreen, NewCommunicationItemSummaryScreen } from "../screens";
import { createStackNavigator } from "@react-navigation/stack";

const CommunicationStack = createStackNavigator();

export const CommunicationNavigator = () => {
  return (
    <CommunicationStack.Navigator id="CommunicationStack" screenOptions={{ headerShown: false, }}>
      <CommunicationStack.Screen
        name="CommunicationScreen1"
        component={NewCommunicationScreen}
      />
      <CommunicationStack.Screen
        name="CommunicationItemListScreen"
        component={NewCommunicationItemListScreen}
      />
      <CommunicationStack.Screen
        name="CommunicationSelectItemListScreen"
        component={CommunicationSelectScreen}
      />
      <CommunicationStack.Screen
        name="CommunicationItemSummaryScreen"
        component={NewCommunicationItemSummaryScreen}
      />
    </CommunicationStack.Navigator>
  );
};
