import { useNavigatorContext } from "../../../contexts";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { getDivestimentDetailList, postDivestimentAddItem } from "./../../../services/divestimentService";
import { useContext, useState } from "react";

export default function divestimentItemsHook() {
  const { navigateTo } = useNavigatorContext();
  const [isLoading, setIsLoading] = useState(true);
  const { addItemsDivestiment } = useGlobalContext();

  async function postAddItemDivestiment(divestiment_header_id: number, asset_numbers: any) {
    const data = await postDivestimentAddItem(divestiment_header_id, asset_numbers);
    if (data?.status) {

      getDetailListDivestiment(divestiment_header_id)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getDetailListDivestiment(divestimient_header_id: number) {
    const data = await getDivestimentDetailList(divestimient_header_id);
    if (data?.status) {
      addItemsDivestiment(data.result)
      navigateTo('DivestmentApprovedScreen')
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  return {
    postAddItemDivestiment,
    getDetailListDivestiment
  }
}