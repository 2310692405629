import {
  useAuthContext,
  useInventoryContext,
  useNavigatorContext,
  // useProviderContext,
} from "../../../contexts";
import { getPercentageValues } from "../../../utils";

export default function homeScreenHooks() {
  const { signOut } = useAuthContext();
  const { navigateTo } = useNavigatorContext();
  // const { filteredProvider } = useProviderContext();
  const { inventoryData } = useInventoryContext();

  function validateAndHandleLogout(screenName: string) {
    if (screenName === "AuthScreen") {
      signOut();
    }
    return navigateTo(screenName);
  }

  const chartData = [
    {
      quantity: (100 - (inventoryData?.done / inventoryData?.total)*100),
      color: "#F00",
    },
    {
      quantity: (inventoryData?.done / inventoryData?.total)*100,
      color: "#00B050",
    },
  ];

  const statusInventory = getPercentageValues(
    inventoryData?.done,
    inventoryData?.total
  );

  return {
    validateAndHandleLogout,
    // filteredProvider,
    inventoryData,
    chartData,
    statusInventory,
  };
}
