import { Button, TabsHeader } from "../../../../components";
import { Stack, useTheme, View } from "native-base";
import Tabs from "../Tabs";
import { useAuthContext, useInventoryContext } from "../../../../contexts";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";
import inventoryCloseHook from "../../hook/useInventoryClose";
import { ModalAlert } from "../../../../components/ModalAlert";

export const Content = ({
  navigation,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { colors } = useTheme();
  const { accountType } = useAuthContext();
  const { inventoryData, updateInventoryData, showAlert, setShowAlert } = useInventoryContext();
  const { submitInventoryClose } = inventoryCloseHook();

  async function handleCloseInventory() {
    await submitInventoryClose(inventoryData.id);
    updateInventoryData();
    navigation.navigate("HomeScreen");
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
    navigation.navigate("HomeScreen");
  };

  return (
    <View
      flex={1}
      _web={{
        px: 10,
      }}
    >
      <TabsHeader />
      <View
        style={{
          backgroundColor: "#dee1e6",
          paddingTop: 10,
        }}
        flex={1}
      >
        <Tabs navigation={navigation} />
      </View>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        bg={colors.white}
        space={2}
        py={1}
        px={4}
      >
        {accountType !== "ga" ? (
          <>
            <Button
              _web={{ display: "none" }}
              w="49%"
              label="LER CÓDIGO QR"
              onPress={() => navigation.navigate("EnterCodeScreen")}
              isDisabled={
                (inventoryData)
                  ? inventoryData.done === inventoryData.total
                  : true
              }
            />
            <Button
              w="49%"
              label="ENCERRAR"
              onPress={() => navigation.navigate("InventoryClosureScreen")}
              isDisabled={
                (inventoryData) ?
                  (inventoryData.done !== inventoryData.total ||
                    inventoryData.status === "Em Conciliação")
                  : true
              }
            />
          </>
        ) : (
          <Button
            _android={{ display: "none" }}
            w="49%"
            label="FECHAR INVENTÁRIO"
            onPress={() => handleCloseInventory()}
            isDisabled={
              (inventoryData) ? !(inventoryData.status === "Em Conciliação") : true
            }
          />
        )}
      </Stack>
      <ModalAlert isVisible={showAlert} onClose={handleCloseAlert} />
    </View>
  );
};
