import { useContext, useEffect } from "react";
import {
  Divider,
  CircleStatus,
  Button,
} from "../../../../components";
import {
  View,
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
} from "native-base";
import divestimentItemsHook from "./../../../../screens/DivestmentItems/hook/useDivestimentItems";
import { useGlobalContext } from "./../../../../contexts/globalContext";
import divestimentHook from "./../../../../screens/DivestmentScreen/hook/useDivestiment";
import homeScreenHooks from "./../../../../screens/Home/hooks/homeScreen";
import { Pressable } from "react-native";
import divestimentApprovedHook from "../../hook/useDivestimentApproved";
import { useNavigatorContext, useSupplierContext } from "../../../../contexts";

export const Content = () => {
  const { navigateTo } = useNavigatorContext();
  const { colors } = useTheme();
  const { getDetailListDivestiment } = divestimentItemsHook();
  const { _divestimentHeaderCDate, _flatListDivestiment, _divestimentListDetails, _setListDivestiment } = useGlobalContext()
  const { getSelectItensDivestiment } = divestimentHook();
  const { currentSupplier } = useSupplierContext();
  const { getDetailIdDivestiment } = divestimentApprovedHook()

  console.log('verde?', _divestimentListDetails)

  useEffect(() => {
    getDetailListDivestiment(_divestimentHeaderCDate.id)
  }, [_divestimentHeaderCDate, _flatListDivestiment])

  function selectItems() {
    _setListDivestiment(false)
    getSelectItensDivestiment(currentSupplier)
  }

  function handleRedirectPage(item: any) {
    _setListDivestiment(false)
    getDetailIdDivestiment(item.id)
  }

  const dateObject = new Date(_divestimentHeaderCDate.creation_date);
  const dia = dateObject.getDate();
  const mes = dateObject.getMonth() + 1; // Adiciona 1 pois o mês começa em 0 (janeiro) em JavaScript
  const ano = dateObject.getFullYear();
  const dataFormatada = `${dia}/${mes}/${ano}`;


  return (
    <>
      <VStack flex={1} _web={{ px: 20, maxW: '100%' }} bg="#fff">
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          justifyContent="space-between"
          px={3}
          py={5}
        >
          <Text>{`${dataFormatada}`}  {`${_divestimentHeaderCDate.status}`}</Text>
          <Text>Id: {`${_divestimentHeaderCDate.id}`}</Text>
        </Stack>
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
        >
          <Box minWidth={24} _web={{ w: 200 }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={180} _web={{ w: 600 }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
          <Box minWidth={30} _web={{ w: 20 }}>
            <Text color={colors.white}>Status</Text>
          </Box>
        </Stack>
        <FlatList
          nestedScrollEnabled
          data={_divestimentListDetails}
          contentContainerStyle={{
            marginBottom: 100,
          }}
          renderItem={({ item, index }) => {
            return (
              <Pressable onPress={() => handleRedirectPage(item)} >
                <Box px={4} py={1} _web={{ px: 1 }} >
                  <VStack direction="row" space={2} alignItems='center'>
                    <Box minWidth={24} _web={{ w: 210 }}>
                      <Text>{item?.asset_number}</Text>
                    </Box>
                    <Box w={190} _web={{ w: 600 }}>
                      <Text>{item?.asset_description}</Text>
                    </Box>
                    {<CircleStatus
                      color={
                        item?.disinvestment_order_detail_status_id === 2
                          ? colors.success[100]
                          : colors.warning[50]
                      }
                    />}
                  </VStack>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                </Box>
              </Pressable>
            );
          }}
        />
        {_divestimentHeaderCDate.status !== 'Realizado' ? (
          <View>
            <Stack
              direction="row"
              space={2}
              alignItems="center"
              justifyContent="space-between"
              px={3}
              py={2}
            >
              <Button label="SELECIONAR ITENS" w="49%" onPress={() => selectItems()} />
              {_divestimentListDetails.every((d) => d.disinvestment_order_detail_status_id === 2) ? (
                <Button label="Ver Sumário" w="49%" onPress={() => navigateTo('DivestmentSummary')} />
              ) : (
                <Button label="Ver Sumário" backgroundColor='#bfbfbf' w="49%" onPress={() => { }} />
              )}

            </Stack>
          </View>) : (<View></View>)}
      </VStack>
    </>
  );
};

