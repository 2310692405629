import { api } from "./api";

export const getActivesList = (supplier_id: number, limit?: number, offset?: number, search?: string) => {
  return api
    // .get(`/v1/asset_list?supplier_id=${supplier_id})
    .get(`/v1/asset_list?supplier_id=${supplier_id}&limit=${limit}&offset=${offset}&filter=${search}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getAssetList = (asset_id: number) => {
  return api
    .get(`v1/asset_list/${asset_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getAsset = (asset_id: number) => {
  return api
    .get(`v1/asset/${asset_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getTypology = () => {
  return api
    .get(`v1/asset_typology`)
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getEquipment = () => {
  return api
    .get(`v1/equipment_base_condition`)
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const putForm = (asset_id: number, data: any) => {
  return api
    .put(`v1/asset/${asset_id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getPartNumber = (asset_id: number) => {
  return api
    .get(`v1/part_number/${asset_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};


export const putNewPartNumber = (asset_id: number, data: any) => {
  return api
    .post(`v1/part_number/${asset_id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
};