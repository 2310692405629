import { Platform } from "react-native";
import { api } from "./api";
import * as FileSystem from "expo-file-system";
import axios from "axios";

export const getRetoolingHeaderStatus = () => {
  return api
    .get(`v1/retooling_header_status`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getRetoolingHeader = (header_id: string) => {
  return api
    .get(`v1/retooling_header/${header_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getRetoolingList = async (
  supplier_id: number,
  searchStatus?: string,
  searchInitialDate?: string,
  searchFinalDate?: string,
  offset?: string | number,
  limit?: string | number
) => {
  let url = `v1/retooling_status?supplier_id=${supplier_id}`;
  if (searchStatus) url = url + `&status=${searchStatus}`;
  if (searchInitialDate) url = url + `&initialDate=${searchInitialDate}`;
  if (searchFinalDate) url = url + `&finalDate=${searchFinalDate}`;
  if (offset) url = url + `&offset=${offset}`;
  if (limit) url = url + `&limit=${limit}`;

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRetoolingSelectItens = (
  supplier_id: number,
  limit: number,
  offset?: number,
  searchTerm?: string
) => {
  return api
    .get(
      `v1/asset_list?supplier_id=${supplier_id}&limit=${limit}&offset=${offset}&filter=${searchTerm}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postRetoolingHeader = (supplier_id: number) => {
  const body = { supplier_id };
  return api
    .post(`v1/retooling_header`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postRetoolingAddItem = (
  retooling_header_id: number,
  asset_numbers: []
) => {
  const body = { retooling_header_id, asset_numbers };
  return api
    .post(`v1/retooling_detail`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deleteRetoolingItem = (retooling_item_id: string) => {
  return api
    .delete(`v1/retooling_detail/${retooling_item_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getRetoolingDetailList = (retooling_header_id: number) => {
  return api
    .get(`v1/retooling_detail_list/${retooling_header_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getRetoolingDetailItem = (retooling_detail_id: number) => {
  return api
    .get(`v1/retooling_detail/${retooling_detail_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postRetoolingRecDocuments = (
  retooling_order_detail_id: number,
  fileName: any
) => {
  const body = {
    retooling_order_detail_id: retooling_order_detail_id,
    fileName: fileName,
  };
  return api
    .post(`v1/retooling_detail/generate_signed_url`, body)
    .then((response) => {
      console.log("Upload file:", body, response);
      return response.data;
    })
    .catch((error) => {
      console.error("Upload file error:", error.response);
    });
};

export const getRetoolingRecDocuments = (retooling_order_detail_id: number) => {
  const body = { retooling_order_detail_id: retooling_order_detail_id };
  console.log(body);
  return api
    .post(`v1/retooling_detail/get_signed_url`, body)
    .then((response) => {
      console.log(retooling_order_detail_id, response);
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
    });
};

export const putRetoolingRecDocuments = async (
  presignedUrl: string,
  data: any
) => {
  if (Platform.OS !== "web") {
    const result = await FileSystem.uploadAsync(presignedUrl, data.uri, {
      fieldName: "file",
      httpMethod: "PUT",
      uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
    });
    return result;
  } else {
    try {
      const fileBlob = await fetch(data.uri).then((response) =>
        response.blob()
      );
      await axios.put(presignedUrl, fileBlob, {
        headers: {
          "Content-Type": data.file.type,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const putRetoolingRec = (retooling_detail_id: number, fileName: any) => {
  return api
    .put(`v1/retooling_detail/${retooling_detail_id}`, fileName)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const putRetoolingReq = (retooling_header_id: number, body: any) => {
  return api
    .put(`v1/retooling_header/${retooling_header_id}`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
