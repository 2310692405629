import {
  Divider,
  WebSidebarMenu,
  Button,
  BottomNavigation,
  Select,
  InputDate,
} from "../../../../components";
import {
  View,
  Text,
  VStack,
  Stack,
  Center,
  Box,
  Icon,
  FlatList,
  useTheme,
  HStack,
  Radio,
  ScrollView,
  Image,
  Pressable,
  StatusBar,
} from "native-base";
import { FontAwesome } from "@expo/vector-icons";
import { TextInput } from "../../../../components/TextInput/index";
import { Union } from "../../../../../assets/images";
import { useCallback, useContext, useEffect, useState } from "react";
import * as DocumentPicker from 'expo-document-picker';
import { SafeAreaView } from "react-native";
import { useGlobalContext } from "./../../../../contexts/globalContext";
import { postRetoolingRecDocuments, putRetoolingRecDocuments } from "./../../../../services/retoolingService";
import RetoolingDetailsHook from "../hook/useRetoolingDetails";
import { useNavigatorContext } from "./../../../../contexts/navigatorContext";
import retoolingItemsHook from "./../../../../screens/RetoolingItems/hook/useRetoolingItems";

export const Content = () => {
  const { navigateTo } = useNavigatorContext();
  const { colors } = useTheme();
  const [excel, setExcel] = useState<string>('');
  const [pdf, setPdf] = useState<string>('');
  const [ppt, setPpt] = useState<string>('');
  const { _itemRetoolingId, _headerCDate, recRetooling } = useGlobalContext();
  const { _sendRecRetooling } = RetoolingDetailsHook();
  const { getDetailListRetooling } = retoolingItemsHook();

  const [_application, _setApplication] = useState();
  const [_asset_description, _setAsset_description] = useState();
  const [_asset_number, _setAsset_number] = useState();
  const [_cavity_number, _setCavity_number] = useState();
  const [_denomination, _setDenomination] = useState();
  const [_id, _setId] = useState();
  const [_investment_order, _setInvestment_order] = useState();
  const [_justification, _setJustification] = useState();
  const [_metallic_or_plastic, _setMetallic_or_plastic] = useState();
  const [_number_of_contracted_cycles, _setNumber_of_contracted_cycles] = useState();
  const [_number_of_cycles_performed, _setNumber_of_cycles_performed] = useState();
  const [_operation, _setOperation] = useState();
  const [_part_numbers, _setPart_numbers] = useState();
  const [_production_beguinning_date, _setProduction_beguinning_date] = useState();
  const [_type, _setType] = useState();


  useEffect(() => {
    _setApplication(_itemRetoolingId.application);
    _setAsset_description(_itemRetoolingId.asset_description);
    _setAsset_number(_itemRetoolingId.asset_number);
    _setCavity_number(_itemRetoolingId.cavity_number);
    _setDenomination(_itemRetoolingId.denomination);
    _setId(_itemRetoolingId.id);
    _setInvestment_order(_itemRetoolingId.investment_order);
    _setJustification(_itemRetoolingId.justification);
    _setMetallic_or_plastic(_itemRetoolingId.metallic_or_plastic);
    _setNumber_of_contracted_cycles(_itemRetoolingId.number_of_contracted_cycles);
    _setNumber_of_cycles_performed(_itemRetoolingId.number_of_cycles_performed);
    _setOperation(_itemRetoolingId.operation);
    _setPart_numbers(_itemRetoolingId.part_numbers);
    _setProduction_beguinning_date(_itemRetoolingId.production_beguinning_date);
    _setType(_itemRetoolingId.type);
    setExcel('');
    setPdf('');
    setPpt('');
  }, [_headerCDate])


  const uploadFileExcel = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === 'success') {
      recExcel(result)
    }
  }

  console.log()

  function finishItensConsult() {
    const data = {
      application: _application,
      cavity_number: _cavity_number,
      denomination: _denomination,
      id: _id,
      investment_order: _investment_order,
      justification: _justification,
      metallic_or_plastic: _metallic_or_plastic,
      number_of_contracted_cycles: _number_of_contracted_cycles,
      number_of_cycles_performed: _number_of_cycles_performed,
      operation: _operation,
      part_numbers: _part_numbers,
      production_beguinning_date: _production_beguinning_date,
      type: _type,
      files: [excel, pdf, ppt]
    }
    _sendRecRetooling(_id, data)

  }

  async function recExcel(file: any) {
    const data = await postRetoolingRecDocuments(file.name);
    if (data?.status) {
      sendRecExcel(data.result, file.uri)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  async function sendRecExcel(url: string, file: any) {
    const data = await putRetoolingRecDocuments(url, file);
    if (data?.status) {
      const _indexOf = url.indexOf('?')
      const urlDiminuida = url.substring(0, _indexOf)
      setExcel(urlDiminuida)
      console.log('excel', urlDiminuida)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  const uploadFilePdf = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === 'success') {
      recPdf(result)
    }
  }

  async function recPdf(file: any) {
    const data = await postRetoolingRecDocuments(file.name);
    if (data?.status) {
      sendRecPdf(data.result, file.uri)
      //setPdf
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  async function sendRecPdf(url: string, file: any) {
    const data = await putRetoolingRecDocuments(url, file);
    if (data?.status) {
      const _indexOf = url.indexOf('?')
      const urlDiminuida = url.substring(0, _indexOf)
      setPdf(urlDiminuida)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }


  const uploadFilePpt = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === 'success') {
      recPpt(result)
    }
  }

  async function recPpt(file: any) {
    const data = await postRetoolingRecDocuments(file.name);
    if (data?.status) {
      sendRecPpt(data.result, file.uri)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  async function sendRecPpt(url: string, file: any) {
    const data = await putRetoolingRecDocuments(url, file);
    if (data?.status) {
      const _indexOf = url.indexOf('?')
      const urlDiminuida = url.substring(0, _indexOf)
      setPpt(urlDiminuida)
      console.log('ppt', urlDiminuida)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }


  function rec() {
    console.log('GRAVOU')
  }

  return (
    <>
      <VStack flex={1} px={2} py={2} _web={{ px: 20, w: '100%' }} bg="#fff">
        <Stack>
          <Center pb={2} _web={{ pb: 5 }}>
            <Text>{`${_itemRetoolingId.asset_number}`}</Text>
            <Text>{`${_itemRetoolingId.asset_description}`}</Text>
          </Center>
          <Divider />
        </Stack>
        <ScrollView>
          <HStack space={2} py={3}>
            <Radio.Group isDisabled={_headerCDate.status !== "Em Preenchimento"} name="exampleGroup" defaultValue={_itemRetoolingId.type}>
              <HStack alignItems="center" justifyContent="space-between">
                <Box w="60%">
                  <Radio value="1" size="sm" isDisabled={_headerCDate.status !== "Em Preenchimento"}>
                    Reconstrução
                  </Radio>
                </Box>
                <Box w="40%">
                  <Radio value="2" size="sm" isDisabled={_headerCDate.status !== "Em Preenchimento"}>
                    Revitalização
                  </Radio>
                </Box>
              </HStack>
            </Radio.Group>
          </HStack>
          <Divider />
          <Stack direction="row" justifyContent="space-between" p={2} space={1}>
            <Box w="39%">
              <Text>Nº Componente / Conjunto (PN)</Text>
            </Box>
            <Box w="60%">
              <TextInput defaultValue={_itemRetoolingId.part_numbers} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="40%">
              <Text>Aplicação(Modelo / Projeto)</Text>
            </Box>
            <Box w="60%">
              <TextInput defaultValue={_itemRetoolingId.application} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="40%">
              <Text>Metálico / Plástico</Text>
            </Box>
            <Box w="60%">
              <TextInput defaultValue={_itemRetoolingId.metallic_or_plastic} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="40%">
              <Text>Operação</Text>
            </Box>
            <Box w="60%">
              <TextInput isDisabled defaultValue={_itemRetoolingId.operation} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="40%">
              <Text>Nº Cavidades</Text>
            </Box>
            <Box w="60%">
              <TextInput defaultValue={_itemRetoolingId.cavity_number} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="40%">
              <Text>Denominação</Text>
            </Box>
            <Box w="60%">
              <TextInput defaultValue={_itemRetoolingId.denomination} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="40%">
              <Text>Pedido de Compra Atual</Text>
            </Box>
            <Box w="60%">
              <TextInput defaultValue={_itemRetoolingId.investment_order} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="39%">
              <Text>Data Início Produção</Text>
            </Box>
            <Box w="59%">
              <InputDate placeholder="DD/MM/AA" width={172} defaultValue={_itemRetoolingId.production_beguinning_date} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="40%">
              <Text>Nº Ciclos Realizados</Text>
            </Box>
            <Box w="60%">
              <TextInput defaultValue={_itemRetoolingId.number_of_cycles_performed} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="40%">
              <Text>Nº Ciclos Contratados</Text>
            </Box>
            <Box w="60%">
              <TextInput defaultValue={_itemRetoolingId.number_of_contracted_cycles} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="40%">
              <Text>Justificativa</Text>
            </Box>
            <Box w="60%">
              <TextInput defaultValue={_itemRetoolingId.justification} isDisabled={_headerCDate.status !== "Em Preenchimento"} />
            </Box>
          </Stack>
          <Divider />
          <Stack
            _web={{ px: 10 }}
            direction="row"
            space={3}
            justifyContent="space-between"
            px={2}
            py={3}
          >
            <Text fontWeight='bold'>Anexos</Text>
          </Stack>
          <Box px={2} py={2} _web={{ px: 10 }} >
            <Pressable onPress={uploadFileExcel} isDisabled={_headerCDate.status !== "Em Preenchimento"}>
              <VStack
                direction="row"
                space={2}
                justifyContent="space-between"
                alignItems='center'
              >
                <Stack direction="row" space={2} alignItems='center'>
                  <Text>1-</Text>
                  <Text w={240}>Cost Breakdown Tooling [Excel]</Text>
                  {excel ? <Image
                    source={Union}
                    alt="Update provider icon"
                    resizeMode="contain"
                    size='15px'
                  /> : ''}

                </Stack>
                <Box>
                  <Icon
                    as={FontAwesome}
                    name="pencil-square-o"
                    size={8}
                    color={colors.success[50]}
                  />
                </Box>
              </VStack>
            </Pressable>
          </Box>

          <Box px={2} py={2} _web={{ px: 10 }} >
            <Pressable onPress={uploadFilePdf} isDisabled={_headerCDate.status !== "Em Preenchimento"}>
              <VStack
                direction="row"
                space={2}
                justifyContent="space-between"
                alignItems='center'
              >
                <Stack direction="row" space={2} alignItems='center'>
                  <Text>2-</Text>
                  <Text w={240}>Form. de Revitalização e Construção [PDF]</Text>
                  {pdf ? <Image
                    source={Union}
                    alt="Update provider icon"
                    resizeMode="contain"
                    size='15px'
                  /> : ''}

                </Stack>
                <Box>
                  <Icon
                    as={FontAwesome}
                    name="pencil-square-o"
                    size={8}
                    color={colors.success[50]}
                  />
                </Box>
              </VStack>
            </Pressable>
          </Box>

          <Box px={2} py={2} _web={{ px: 10 }} >
            <Pressable onPress={uploadFilePpt} isDisabled={_headerCDate.status !== "Em Preenchimento"}>
              <VStack
                direction="row"
                space={2}
                justifyContent="space-between"
                alignItems='center'
              >
                <Stack direction="row" space={2} alignItems='center'>
                  <Text>1-</Text>
                  <Text w={240}>Apresent. de Retooling [PPT]</Text>
                  {ppt ? <Image
                    source={Union}
                    alt="Update provider icon"
                    resizeMode="contain"
                    size='15px'
                  /> : ''}

                </Stack>
                <Box>
                  <Icon
                    as={FontAwesome}
                    name="pencil-square-o"
                    size={8}
                    color={colors.success[50]}
                  />
                </Box>
              </VStack>
            </Pressable>
          </Box>
        </ScrollView>

        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-between" }}
        >
          <Button
            _web={{ w: 500 }}
            w="48%"
            label="CANCELAR"
            onPress={() => navigateTo('RetoolingApprovedScreen')}
          />

          <Button
            _web={{ w: 500 }}
            w="48%"
            label="GRAVAR"
            onPress={() => finishItensConsult()}
          />
        </Stack>
      </VStack>
    </>
  );
};
