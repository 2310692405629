import { useNavigatorContext } from "../../../contexts";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { getDivestimentDetailId } from "./../../../services/divestimentService";
import { useContext, useState } from "react";

export default function divestimentApprovedHook() {
  const { navigateTo } = useNavigatorContext();
  const [isLoading, setIsLoading] = useState(true);
  const { addItemDivestimentId } = useGlobalContext()

  async function getDetailIdDivestiment(divestiment_detail_id: number) {
    const data = await getDivestimentDetailId(divestiment_detail_id);
    if (data?.status) {
      addItemDivestimentId(data.result)
      navigateTo('DivestmentDetailsScreen')
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  /*async function putReqRetooling(retooling_detail_id: number) {
    const data = await putRetoolingReq(retooling_detail_id);
    if (data?.status) {
      console.log('finalizado', data)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }*/

  return {
    getDetailIdDivestiment,
  }
}