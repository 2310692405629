import { MachineImg } from "../../../assets/images";
import { Box, Center, HStack, Icon, Image, Stack, Text, useTheme } from "native-base";
import { Platform, Pressable } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";

interface ICardProps {
  id: number;
  asset_number: string;
  asset_description: string;
  status: string;
  openModal: any;
  navigation: DrawerNavigationProp<ParamListBase>;
}

export const CardItems = ({
  id,
  asset_number,
  asset_description,
  status,
  openModal,
  navigation
}: ICardProps) => {
  const { colors } = useTheme();
  const successColor = colors.success[100];
  const warningColor = colors.warning[50];

  function handleItemPress() {
    if (status === "done") {
      console.log('go to item detail with id:', id);
    } else {
      console.log('go to item form with id:', id);
    }
  }

  return (
    <Pressable onPress={() => console.log('test')}>
      <HStack alignItems="center" flex={1} px={1} py={2} _web={{ flexDirection: 'row' }}>
        <Box
          alignItems="center"
          justifyContent="center"
          borderColor={(status !== 'Planejado' && status !== 'CAF tercerizado') ? successColor : warningColor}
          borderWidth={5}
          borderRadius={150}
          h={60}
          w={60}
          mr={4}
        >
          <Image
            source={MachineImg}
            alt={"Alt Image"}
            w={50}
            h={50}
            borderRadius={150}
          />
        </Box>

        <Stack minW={Platform.OS === 'web' ? '90%' : 215} maxW={Platform.OS === 'web' ? '90%' : 215} _web={{ flexDirection: 'row' }}>
          <HStack space={2}>
            <Text fontWeight="regular">{asset_number}</Text>
            <Text fontWeight="bold">{status}</Text>
          </HStack>

          <HStack space={2} minW={Platform.OS === 'web' ? '90%' : 200} maxW={Platform.OS === 'web' ? '90%' : 200} _web={{ marginLeft: 20 }}>
            <Text fontWeight="regular">{asset_description}</Text>
          </HStack>
        </Stack>
        <Center minWidth={55}>
          <Pressable style={{ padding: 10 }} onPress={openModal}>
            <Icon
              as={FontAwesome}
              name="ellipsis-v"
              color={colors.primary[200]}
              size={7}
              alignItems="center"
            />
          </Pressable>
        </Center>
      </HStack>
    </Pressable>
  );
};
