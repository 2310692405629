import Constants from "expo-constants";

const ENV = {
 dev: {
   apiUrl: "https://apidev.toolcontrol.com.br",
 },
 qas: {
   apiUrl: "https://apiqa.toolcontrol.com.br",
 },
 prod: {
   apiUrl: "https://api.toolcontrol.com.br",
 }
};

const getEnvVars = (env = Constants.expoConfig?.extra?.releaseChannel) => {
  if (__DEV__ || env === 'development') {
    return ENV.dev;
  } else if (env === 'qas') {
    return ENV.qas;
  } else if (env === 'production') {
    return ENV.prod;
  }
  
  return ENV.dev;
};

export default getEnvVars;