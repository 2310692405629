import Axios from "axios";
import { api } from "./api";
import * as FileSystem from "expo-file-system";

export const getManagementList = (
  supplier_id: number,
) => {
  return api
    .get(`v1/communication_status?supplier_id=${supplier_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};


export const postManagementHeader = (
  supplier_id: number,
) => {
  const body = { supplier_id }
  return api
    .post(`v1/communication_header`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};


export const getManagementSelectItens = (

  supplier_id: number,
) => {
  return api
    .get(`v1/asset_list?supplier_id=${supplier_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};


export const postManagementAddItem = (
  communication_header_id: number,
  asset_numbers: [],
) => {
  const body = { communication_header_id, asset_numbers }
  return api
    .post(`v1/communication_detail`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};


export const getManagementDetailList = (
  communication_header_id: number,
) => {
  return api
    .get(`v1/communication_detail_list/${communication_header_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getManagementSummarySelect = (
) => {
  return api
    .get(`v1/communication_header_classification`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};


export const postManagementRecDocuments = (
  fileName: any,
) => {
  const body = { fileName: fileName }
  return api
    .post(`v1/communication_header/generate_signed_url`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};


export const putManagementRecDocuments = async (presignedUrl: string, file: any) => {
  const result = await FileSystem.uploadAsync(presignedUrl, file, {
    fieldName: 'file',
    httpMethod: 'PUT',
    uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
  });
  return result;
}

export const putManagementRec = (
  communication_header_id: number,
  file: any,
) => {
  return api
    .put(`v1/communication_header/${communication_header_id}`, file)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};