import { useGlobalContext } from "../../../contexts/globalContext";
import { getActivesList, getAssetList, getEquipment, getPartNumber, getTypology, putForm, putNewPartNumber } from "../../../services/activesService";
import { useNavigatorContext } from "../../../contexts/navigatorContext";



export default function ListActivesHook() {
  const { _setListActives, addAssetList, addPartNumbers } = useGlobalContext()
  const { navigateTo } = useNavigatorContext();


  async function getListActives(supplier_id: number) {
    const data = await getActivesList(supplier_id);
    if (data?.status) {
      _setListActives(data.result)
    }
  }

  async function getCLInventory(id: number, navigation: any) {
    const typology = await getTypology();
    const equipment = await getEquipment();
    const data = await getAssetList(id);
    if (data?.status) {
      addAssetList(data.result, typology, equipment)
      navigation.navigate('ActivesDetailsScreen')
    }
  }

  async function getPNumber(asset_id: any, navigation: any) {
    const data = await getPartNumber(asset_id);
    if (data?.status) {
      addPartNumbers(data.result, navigation)
      
    }
  }

  console.log('lista de ativos',)

  async function putSendForm(asset_id: any, form: any, navigation: any) {
    console.log('salvou?', asset_id)
    const data = await putForm(asset_id, form);
    if (data?.status) {
      console.log(data)
      navigation.navigate('ActivesScreen')
    }
  }



  async function putSendPartNumber(asset_id: any, form: any) {
    const data = await putNewPartNumber(asset_id, form);
    if (data?.status) {
      console.log('data', data)
    }
  }

  return {
    getListActives,
    getCLInventory,
    putSendForm,
    getPNumber,
    putSendPartNumber,
  };
}