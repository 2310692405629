import axios from "axios";
import getEnvVars from '../../environment';
import { clearToken, getToken } from "../utils";
import { navigateToLogin } from "../utils/navigateToLogin";

const { apiUrl } = getEnvVars();

console.log('API Teste');
console.log(apiUrl);
const api = axios.create({
  baseURL: apiUrl,
});

api.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers = {
        token: `${token}`,
        "Content-Type": "application/json",
      };
    }
    return config;
  },
  (error) => {
    console.log("API ERROR ", error);
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      clearToken();
      navigateToLogin();
    }
    return Promise.reject(error);
  }
);

export { api };
