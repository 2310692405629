import { NativeBaseProvider } from "native-base";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NavigationContainer } from "@react-navigation/native";
import { theme } from "./src/styles/theme";
import { AuthContextProvider, CommunicationContextProvider, DivestmentContextProvider, GlobalProvider, InventoryContextProvider, MovementContextProvider, RetoolingContextProvider, SupplierContextProvider, ModalAlertContextProvider } from "./src/contexts";
import { MainNavigator } from "./src/navigation/mainNavigator";
import { LogBox } from "react-native";
import { Divestment } from "@/screens/DivestmentScreen/Components/Content";
import { setNavigator } from "./src/utils/navigateToLogin";

export default function App() {
  LogBox.ignoreAllLogs();

  return (
    <SafeAreaProvider>
      <NativeBaseProvider theme={theme}>
        <AuthContextProvider>
          <SupplierContextProvider>
            <GlobalProvider>
              <InventoryContextProvider>
                <MovementContextProvider>
                  <RetoolingContextProvider>
                    <CommunicationContextProvider>
                      <DivestmentContextProvider>
                        <ModalAlertContextProvider>
                          <NavigationContainer ref={(ref) => setNavigator(ref)}>
                            <MainNavigator />
                          </NavigationContainer>
                        </ModalAlertContextProvider>
                      </DivestmentContextProvider>
                    </CommunicationContextProvider>
                  </RetoolingContextProvider>
                </MovementContextProvider>
              </InventoryContextProvider>
            </GlobalProvider>
          </SupplierContextProvider>
        </AuthContextProvider>
      </NativeBaseProvider >
    </SafeAreaProvider >
  );
}
