import { BottomNavigation, TemplateWebPages } from "../../components";
import { Center, ScrollView, VStack } from "native-base";
import { platformWeb } from "../../utils";
import { Content } from "./Components/Content";
import { ParamListBase } from "@react-navigation/native";
import { DrawerScreenProps } from "@react-navigation/drawer";

export const RetoolingSelectScreen = ({ route, navigation, }: DrawerScreenProps<ParamListBase>) => {
  return (
    <>
      <VStack flex={1}>
        {platformWeb ? (
          <ScrollView>
          <TemplateWebPages navigation={navigation}>
            <Center>
              <Content route={route} navigation={navigation} />
            </Center>
          </TemplateWebPages>
          </ScrollView>
        ) : (
          <>
            <Content route={route} navigation={navigation} />
            <BottomNavigation navigation={navigation} />
          </>
        )}
      </VStack>
    </>
  );
};
