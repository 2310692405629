import { useNavigatorContext } from "../../../contexts";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { getDivestimentSummary, putDivestimentFinish } from "./../../../services/divestimentService";
import { useContext, useState } from "react";

export default function divestimentSummaryHook() {
  const { navigateTo } = useNavigatorContext();
  const [isLoading, setIsLoading] = useState(true);
  const [_itensSummary, _setItensSummary] = useState()
  const { recDivestiment } = useGlobalContext()

  async function getSummaryDivestiment(disinvestment_header_id: number) {
    const data = await getDivestimentSummary(disinvestment_header_id);
    if (data?.status) {
      _setItensSummary(data.result)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function putFinishDivestiment(disinvestment_header_id: number, body: any) {

    const data = await putDivestimentFinish(disinvestment_header_id, body);
    if (data?.status) {
      console.log(data)
      recDivestiment()
      navigateTo('DivestmentScreen')
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  return { getSummaryDivestiment, _itensSummary, putFinishDivestiment }
}