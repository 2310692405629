import React from "react";
import { CircleStatus, Divider, MiniPieChart } from "../index";
import { Box, Center, Stack, Text, useTheme, View } from "native-base";
import { useInventoryContext, useSupplierContext } from "../../contexts";
import homeScreenHooks from "../../screens/Home/hooks/homeScreen";
import { formatDate, platformWeb } from "../../utils";
import { getInventoryService } from "../../services/inventoryService";

export const TabsHeader = () => {
  const { colors } = useTheme();
  const { inventoryData, chartData } = useInventoryContext();

  return (
    <>
      {inventoryData ? (
        <>
          <View px={2} py={1} bg="#fff">
            <Stack direction="row" minH={12}>
              <Stack>
                <Box>
                  <Text>
                    Início: {formatDate(inventoryData?.planned_start_date)} -
                    Fim: {formatDate(inventoryData?.planned_finish_date)}
                  </Text>
                </Box>

                <Stack direction="row" space={2} alignItems="center">
                  <Text>Inv. {inventoryData.status}</Text>
                  <CircleStatus
                    color={
                      (inventoryData.status && inventoryData.status?.includes("Parado")) ||
                      (inventoryData.status?.includes("Em andamento") &&
                        colors.warning[50]) ||
                      colors.success[200]
                    }
                  />
                  <Text>
                    {inventoryData?.done} de {inventoryData?.total}
                    {` - ${(
                      (inventoryData?.done / inventoryData?.total) *
                      100
                    ).toFixed(2)}%`}
                  </Text>
                </Stack>
              </Stack>

              <Stack>
                {!platformWeb && chartData && (
                  <MiniPieChart data={chartData} width={120} height={55} />
                )}
              </Stack>
            </Stack>
          </View>
          <Divider />
        </>
      ) : (
        <>
          <View px={2} py={1} bg="#fff">
            <Stack direction="row" minH={12}>
              <Center flex={1}>
                <Text fontWeight="bold">
                  Nenhuma informação de inventário encontrada!
                </Text>
              </Center>
            </Stack>
          </View>
          <Divider />
        </>
      )}
    </>
  );
};
