import { LoginScreen } from "../screens";
import { useAuthContext } from "../contexts";
import { createStackNavigator } from "@react-navigation/stack";
import { DrawerNavigator } from "./drawerNavigation";

const Stack = createStackNavigator();

export const MainNavigator = () => {
  const { isLoggedUser } = useAuthContext();

  return (
    <Stack.Navigator id="MainStack" screenOptions={{ headerShown: false, }}>
      {isLoggedUser ? (
        <>
          <Stack.Screen name="DrawerNavigator" component={DrawerNavigator} />
        </>
      ) : (
        <>
          <Stack.Screen name="AuthScreen" component={LoginScreen} options={{ headerShown: false }} />
        </>
      )}
    </Stack.Navigator>
  );
};
