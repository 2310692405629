import {
  Button,
  Divider,
  InputDate,
  TextInput,
  ZoomableImage,
} from "../../../../components";
import {
  Box,
  Center,
  HStack,
  ScrollView,
  Select,
  Stack,
  Text,
  VStack,
  View,
} from "native-base";
import { useCallback, useState } from "react";
import {
  getAsset,
  getEquipment,
  getTypology,
  putForm,
} from "../../../../services/activesService";
import { RadioButton } from "react-native-paper";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import {
  ParamListBase,
  RouteProp,
  useFocusEffect,
} from "@react-navigation/native";
import { platformWeb } from "../../../../utils";
import moment from "moment";
import { CAM } from "../../../../../assets/images";
import { getInventoryGenerateUrls } from "../../../../services/inventoryService";
import Spinner from "react-native-loading-spinner-overlay";
export interface IListPhotos {
  uri: string;
  namePhoto: string;
  icon: any;
}

export const Content = ({
  route,
  navigation,
}: {
  route: RouteProp<ParamListBase, string>;
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { caf } = route?.params;

  const [listPhotos, setListPhotos] = useState<IListPhotos[]>([
    {
      namePhoto: "Foto CAF",
      uri: "",
      icon: CAM,
    },
    {
      namePhoto: "Frontal",
      uri: "",
      icon: CAM,
    },
    {
      namePhoto: "Lateral",
      uri: "",
      icon: CAM,
    },
    {
      namePhoto: "Superior",
      uri: "",
      icon: CAM,
    },
  ]);

  const [itemDetails, setItemDetails] = useState<any>();
  const [typology, setTypology] = useState<any>([]);
  const [acquisitionDate, setAcquisitionDate] = useState<string>("");
  const [equipmentBaseCondition, setEquipmentBaseCondition] = useState<any>([]);
  const [editable, setEditable] = useState<string[]>([]);

  async function getItemDetail() {
    setIsLoading(true);
    if (caf) {
      const data = await getAsset(caf);
      if (data?.status) {
        setItemDetails(data.result);
        setEditable(Object.keys(data.result).filter(item => data.result[item]));

        if (data.result.acquisition_date) {
          setAcquisitionDate(
            moment(data.result.acquisition_date)
              .utcOffset(0)
              .format("DD/MM/YYYY")
          );
        }
        const asset_id = data.result?.id;
        handleGetItemPhotos(0, asset_id);
      }
      const typology = await getTypology();
      setTypology(typology);

      const equipment_base_condition = await getEquipment();
      setEquipmentBaseCondition(equipment_base_condition);
    }
    setIsLoading(false);
  }

  const handleGetItemPhotos = async (id: number, asset_id: number) => {
    const Urls = await getInventoryGenerateUrls(id, asset_id);
    if (Urls?.status) {
      const photos = Urls.result.map((item: any, index: any) => ({
        namePhoto: listPhotos[index].namePhoto,
        uri: item,
        icon: CAM,
      }));

      setListPhotos(photos);
    }
  };

  useFocusEffect(
    useCallback(() => {
      getItemDetail();
    }, [route.params])
  );

  async function saveSend() {
    setIsLoading(true);

    const data = {
      sublet_y_n: itemDetails?.sublet_y_n,
      asset_typology_id: itemDetails?.asset_typology_id,
      equipment_base_condition_id: itemDetails?.equipment_base_condition_id,
      number_of_cycles_performed: itemDetails?.number_of_cycles_performed,
      number_of_contracted_cycles: itemDetails?.number_of_contracted_cycles,
      acquisition_date: moment(acquisitionDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      production_status: itemDetails?.production_status,
      investment_order: itemDetails?.investment_order,
    };
    try {
      await putForm(itemDetails?.id, data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    navigation.navigate("activeDrawer");
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <VStack
          flex={1}
          px={2}
          mt={1}
          _web={{ px: 20, maxW: "full" }}
          bg="#fff"
        >
          <VStack>
            <Stack>
              {itemDetails ? (
                <Center pb={2} _web={{ pb: 5 }}>
                  <Text>{`${itemDetails?.asset_number}`}</Text>
                  <Text>{`${itemDetails?.asset_description}`}</Text>
                </Center>
              ) : null}
              <Divider />
            </Stack>

            <Box w="100%">
              <HStack alignItems="center" space={2} py={2}>
                <Box w="39%">
                  <Text p={2} fontWeight={"bold"}>
                    Sublocado
                  </Text>
                </Box>
                <Box w="60%">
                  <View>
                    <RadioButton.Group
                      value={itemDetails?.sublet_y_n?.toString()}
                      onValueChange={(value: string) =>
                        setItemDetails({ ...itemDetails, sublet_y_n: value })
                      }
                    >
                      <HStack
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box w="60%" display="flex" flexDirection="row">
                          <RadioButton.Item
                            label="Não"
                            value="0"
                            disabled={editable.includes("sublet_y_n")}
                          />
                          <RadioButton.Item
                            label="Sim"
                            value="1"
                            disabled={editable.includes("sublet_y_n")}
                          />
                        </Box>
                      </HStack>
                    </RadioButton.Group>
                  </View>
                </Box>
              </HStack>
            </Box>
            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              p={1}
              py={2}
              alignItems="center"
            >
              <Box w="39%">
                <Text fontWeight="bold" p={2}>
                  Tipologia do Ativo
                </Text>
              </Box>
              <Box w="60%">
                <Select
                  selectedValue={itemDetails?.asset_typology_id?.toString()}
                  isDisabled={editable.includes("asset_typology_id")}
                  onValueChange={(value: string) =>
                    setItemDetails({ ...itemDetails, asset_typology_id: value })
                  }
                >
                  {typology?.map((item: { name: string; id: string }) => (
                    <Select.Item
                      label={item.name}
                      value={item.id?.toString()}
                    />
                  ))}
                </Select>
              </Box>
            </Stack>
            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              p={1}
              py={2}
              alignItems="center"
            >
              <Box w="39%">
                <Text fontWeight="bold" p={2}>
                  Condições Base do Equipamento
                </Text>
              </Box>
              <Box w="60%">
                <Select
                  selectedValue={itemDetails?.equipment_base_condition_id?.toString()}
                  isDisabled={editable.includes("equipment_base_condition_id")}
                  onValueChange={(value: string) =>
                    setItemDetails({
                      ...itemDetails,
                      equipment_base_condition_id: value,
                    })
                  }
                >
                  {equipmentBaseCondition?.map(
                    (item: { name: string; id: string }) => (
                      <Select.Item
                        label={item.name}
                        value={item.id?.toString()}
                      />
                    )
                  )}
                </Select>
              </Box>
            </Stack>
            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              p={1}
              py={2}
              alignItems="center"
            >
              <Box w="39%">
                <Text p={2} fontWeight="bold">
                  Nº Ciclos Realizados
                </Text>
              </Box>
              <Box w="60%">
                <TextInput
                  defaultValue={itemDetails?.number_of_cycles_performed?.toString()}
                  isDisabled={editable.includes("number_of_cycles_performed")}
                  onChangeText={(value: string) =>
                    setItemDetails({
                      ...itemDetails,
                      number_of_cycles_performed: Number(value),
                    })
                  }
                />
              </Box>
            </Stack>
            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              p={1}
              py={2}
              alignItems="center"
            >
              <Box w="39%">
                <Text p={2} fontWeight="bold">
                  Nº Ciclos Contratados
                </Text>
              </Box>
              <Box w="60%">
                <TextInput
                  value={itemDetails?.number_of_contracted_cycles?.toString()}
                  isDisabled={editable.includes("number_of_contracted_cycles")}
                  onChangeText={(value: string) =>
                    setItemDetails({
                      ...itemDetails,
                      number_of_contracted_cycles: Number(value),
                    })
                  }
                />
              </Box>
            </Stack>
            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              p={2}
              alignItems="center"
            >
              <Box w="39%">
                <Text p={2} fontWeight="bold">
                  Data de Incorporação
                </Text>
              </Box>
              <Box w="60%">
                <InputDate
                  editable={!editable.includes("acquisition_date")}
                  selectTextOnFocus={!editable.includes("acquisition_date")}
                  placeholder="DD/MM/AA"
                  width="97%"
                  value={acquisitionDate}
                  onChangeText={(value: string) => setItemDetails(value)}
                />
              </Box>
            </Stack>
            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              p={1}
              py={2}
              alignItems="center"
            >
              <Box w="39%">
                <Text p={2} fontWeight="bold">
                  Pedido de Compra Atual
                </Text>
              </Box>
              <Box w="60%">
                <TextInput
                  defaultValue={itemDetails?.investment_order?.toString()}
                  isDisabled={editable.includes("investment_order")}
                  onChangeText={(value: string) =>
                    setItemDetails({ ...itemDetails, investment_order: value })
                  }
                />
              </Box>
            </Stack>
            <Divider />

            <HStack alignItems="center" w={"100%"} py={2} _web={{ space: 2 }}>
              <Box w="24%">
                <Text p={2} fontWeight={"bold"}>
                  Produção
                </Text>
              </Box>

              <HStack alignItems="center" justifyContent="space-between">
                <RadioButton.Group
                  value={itemDetails?.production_status}
                  onValueChange={(value: string) =>
                    setItemDetails({
                      ...itemDetails,
                      production_status: value,
                    })
                  }
                >
                  <Box w={"100%"} flexDirection="row">
                    <RadioButton.Item
                      label="Não"
                      disabled={editable.includes("production_status")}
                      value="no"
                    />
                    <RadioButton.Item
                      label="Sim"
                      disabled={editable.includes("production_status")}
                      value="yes"
                    />
                    <RadioButton.Item
                      label="PeA"
                      disabled={editable.includes("production_status")}
                      value="pea"
                    />
                  </Box>
                </RadioButton.Group>
              </HStack>
            </HStack>
            <Divider />
            <HStack space={4} overflowX="auto" justifyContent={"space-around"}>
              {listPhotos.map((item, index) => (
                <ZoomableImage key={index} item={item} />
              ))}
            </HStack>
          </VStack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            space={2}
            py={2}
            _web={{ justifyContent: "space-around" }}
          >
            {platformWeb ? (
              <Button
                _web={{ w: "30%" }}
                w="30%"
                label="VOLTAR"
                onPress={() => navigation.navigate("activeDrawer")}
              />
            ) : null}
            <Button
              _web={{ w: "30%" }}
              w="30%"
              label="SALVAR"
              onPress={() => saveSend()}
            />

            <Button
              _web={{ w: "30%" }}
              w="50%"
              label="LISTA DE P. NUMBERS"
              onPress={() =>
                navigation.navigate("activePartNumbers", {
                  id: itemDetails?.id,
                  caf: itemDetails?.asset_number,
                  asset_description: itemDetails?.asset_description,
                })
              }
            />
          </Stack>
        </VStack>
      )}
    </>
  );
};
