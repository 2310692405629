import { useContext } from "react";
import { useNavigatorContext } from "../../../../contexts/navigatorContext";
import { putRetoolingRec } from "./../../../../services/retoolingService";
import { useGlobalContext } from "./../../../../contexts/globalContext";

export default function RetoolingDetailsHook() {
  const { navigateTo } = useNavigatorContext();
  const { recRetooling } = useGlobalContext()

  async function _sendRecRetooling(retooling_detail_id: any, file: any) {
    const data = await putRetoolingRec(retooling_detail_id, file);
    if (data?.status) {

      navigateTo('RetoolingApprovedScreen')
      recRetooling();
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }


  return {
    _sendRecRetooling
  }
}