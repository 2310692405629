import {
  Divider,
  InputDate,
  Select,
  Button,
  BottomNavigation,
  CircleStatus,
  Pagination,
} from "../../../../components";
import {
  View,
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
  Icon,
  Center,
} from "native-base";

import moment from "moment";
import { platformWidth, platformWidthContent } from "../../../../utils";
import { useContext, useEffect, useState } from "react";
import { useGlobalContext } from "./../../../../contexts/globalContext";
import retoolingHook from "./../../../../screens/Retooling/hook/useRetooling";
import retoolingItemsHook from "./../../../../screens/RetoolingItems/hook/useRetoolingItems";
import { Pressable } from "react-native";
import retoolingApprovedHook from "../../hook/useRetoolingApproved";
import { useNavigatorContext } from "./../../../../contexts/navigatorContext";
import homeScreenHooks from "./../../../../screens/Home/hooks/homeScreen";
import { useAuthContext, useSupplierContext } from "../../../../contexts";


export const Content = () => {
  const { accountType } = useAuthContext();
  const { navigateTo } = useNavigatorContext();
  const { currentSupplier } = useSupplierContext();
  const { colors } = useTheme();
  const { _retoolingListDetails, _headerCDate, _flatListRetooling, _setListRetooling, recRetoolingS } = useGlobalContext();
  const { getDetailListRetooling } = retoolingItemsHook();
  const { getSelectItensRetooling } = retoolingHook()
  const { getDetailIdRetooling, putReqRetooling } = retoolingApprovedHook();

  const dateObject = new Date(_headerCDate.creation_date);
  const dia = dateObject.getDate();
  const mes = dateObject.getMonth() + 1; // Adiciona 1 pois o mês começa em 0 (janeiro) em JavaScript
  const ano = dateObject.getFullYear();
  const dataFormatada = `${dia}/${mes}/${ano}`;

  useEffect(() => {
    getDetailListRetooling(_headerCDate.id)
  }, [_headerCDate, _flatListRetooling])

  function solicitar() {
    _setListRetooling(false)
    putReqRetooling(_headerCDate.id, { retooling_order_header_status_id: 2 })
    navigateTo("RetoolingScreen")
    recRetoolingS()
  }

  function updateRetoolingHeader(statusId: number) {
    _setListRetooling(false)
    putReqRetooling(_headerCDate.id, { retooling_order_header_status_id: statusId })
    navigateTo("RetoolingScreen")
    recRetoolingS()
  }

  function handleRedirectPage(item: any) {
    _setListRetooling(false)
    getDetailIdRetooling(item.id)
  }

  function selectItems() {
    _setListRetooling(false)
    getSelectItensRetooling(currentSupplier)
  }


  return (
    <>
      <VStack flex={1} _web={{ px: 20, w: '100%' }} bg="#fff">
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          justifyContent="space-between"
          px={3}
          py={5}
        >
          <Text>{`${dataFormatada}`} {`${_headerCDate.status}`}</Text>
          <Text>Id: {`${_headerCDate.id}`}</Text>
        </Stack>
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
        >
          <Box minWidth={24} _web={{ w: 200 }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={180} _web={{ w: 600 }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
          <Box minWidth={30} _web={{ w: 20 }}>
            <Text color={colors.white}>Status</Text>
          </Box>
        </Stack>
        <FlatList
          extraData={_flatListRetooling}
          nestedScrollEnabled
          data={_retoolingListDetails}
          contentContainerStyle={{
            marginBottom: 100,
          }}
          renderItem={({ item, index }) => {
            return (
              <Pressable onPress={() => handleRedirectPage(item)} >
                <Box px={4} py={1} _web={{ px: 1 }}>
                  <VStack direction="row" space={2} alignItems='center'>
                    <Box minWidth={24} _web={{ w: 210 }}>
                      <Text>{item?.asset_number}</Text>
                    </Box>
                    <Box w={190} _web={{ w: 600 }}>
                      <Text>{item?.asset_description}</Text>
                    </Box>
                    <CircleStatus
                      color={
                        item?.retooling_order_detail_status_id === 2
                          ? colors.success[100]
                          : colors.warning[50]
                      }
                    />
                  </VStack>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                </Box>
              </Pressable>
            );
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "center" }}
        >
          {
            accountType === "ga" && _headerCDate.status === "Solicitado" ? (
              <>
                <Button
                  _web={{ w: 400 }}
                  w="48%"
                  label="Autorizar"
                  onPress={() => updateRetoolingHeader(3)}
                />
                <Button
                  _web={{ w: 400 }}
                  w="48%"
                  label="Não Autorizar"
                  onPress={() => updateRetoolingHeader(5)}
                />
              </>
            ) : _headerCDate.status === "Em Preenchimento" ? (
              <>
                <Button label="SELECIONAR ITENS" _web={{ w: 500 }} w="48%" onPress={() => selectItems()} />
                {_retoolingListDetails.length > 0 && _retoolingListDetails.every((d) => d.retooling_order_detail_status_id === 2) ? (
                  <Button label="SOLICITAR" w="48%" _web={{ w: 500 }} onPress={() => solicitar()} />
                ) : (
                  <Button label="SOLICITAR" backgroundColor='#bfbfbf' w="48%" _web={{ w: 500 }} onPress={() => { }} />
                )}
              </>
            ) : (
              <>
                <Button label="SELECIONAR ITENS" isDisabled _web={{ w: 500 }} w="48%" onPress={() => selectItems()} />
                <Button label="SOLICITAR" w="48%" isDisabled _web={{ w: 500 }} onPress={() => solicitar()} />
              </>
            )
          }
        </Stack>
      </VStack>
    </>
  );
};


