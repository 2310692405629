import { useContext, useState } from "react";
import { getManagementSummarySelect, putManagementRec } from "./../../../services/managementService";
import { useGlobalContext } from "./../../../contexts/globalContext";

export default function ManagementSummaryHook() {
  const [summarySelect, setSummarySelect] = useState()
  const { recManagement } = useGlobalContext()

  async function getSummarySelectManagement() {
    const data = await getManagementSummarySelect();
    if (data?.status) {
      setSummarySelect(data.result)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  async function _sendRecSummary(management_detail_id: any, file: any) {
    console.log(file)
    const data = await putManagementRec(management_detail_id, file);
    if (data?.status) {
      console.log(data)
      recManagement()
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  return {
    getSummarySelectManagement,
    summarySelect,
    _sendRecSummary
  }
}