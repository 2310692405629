import { Divider } from "../../../../components/Divider";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { Box, FlatList, Stack, Text, VStack, useTheme } from "native-base";

const data = [
  { id: 1, asset_number: "0000000", asset_description: "Descrição do ativo 1" },
  { id: 2, asset_number: "0000001", asset_description: "Descrição do ativo 2" },
  { id: 3, asset_number: "0000002", asset_description: "Descrição do ativo 3" },
  { id: 4, asset_number: "0000003", asset_description: "Descrição do ativo 4" },
  { id: 5, asset_number: "0000004", asset_description: "Descrição do ativo 5" },
]

export const Content = ({ route, navigation, }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase> }) => {

  const { colors } = useTheme()

  return (
    <VStack flex={1} _web={{ px: 20, w: "100%" }} bg="#fff">
      <Stack
        direction="row"
        space={2}
        alignItems="center"
        bg={colors.primary[50]}
        px={3}
        py={2}
        _web={{ w: "100%", justifyContent: "space-between" }}
      >
        <Box minWidth={20} _web={{ minWidth: 100 }}>
          <Text color={colors.white}>Data</Text>
        </Box>
        <Box minWidth={160} _web={{ minWidth: 150 }}>
          <Text color={colors.white}>Status</Text>
        </Box>
        <Box minWidth={38} _web={{ minWidth: 100 }}>
          <Text color={colors.white}>Qtd</Text>
        </Box>
        <Box minWidth={35} _web={{ minWidth: 100 }}>
          <Text color={colors.white}>Id</Text>
        </Box>
      </Stack>
      <Box flex={1} _web={{ maxH: "lg", overflow: "hidden" }}>
        <FlatList
          nestedScrollEnabled
          data={data}
          contentContainerStyle={{
            marginBottom: 100,
          }}
          renderItem={({ item, index }) => {
            return (
              <Box px={4} py={1} _web={{ px: 1 }}>
                <VStack
                  direction="row"
                  space={2}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Box minWidth={24} _web={{ minWidth: 200 }}>
                    <Text>{item?.asset_number}</Text>
                  </Box>
                  <Box w={"35%"} _web={{ minWidth: "55%" }}>
                    <Text>{item?.asset_description}</Text>
                  </Box>
                </VStack>
                <Box mt={3}>
                  <Divider />
                </Box>
              </Box>
            );
          }}
        />
      </Box>
    </VStack>
  );
}