import { BottomNavigation, TemplateWebPages } from "../../../components";
import { platformWeb } from "../../../utils";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { Content } from "./Components/Content";
import { Center, ScrollView, VStack } from "native-base";

export const NewSupplierHistoryScreen = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase>; }) => {
  return (
    <>
      <VStack flex={1}>
        {platformWeb ? (
          <ScrollView>
          <TemplateWebPages navigation={navigation}>
            <Center>
              <Content route={route} navigation={navigation} />
            </Center>
          </TemplateWebPages>
          </ScrollView>
        ) : (
          <>
            <Content route={route} navigation={navigation} />
            <BottomNavigation navigation={navigation} />
          </>
        )}
      </VStack>
    </>
  );
};