import {
  Button,
  CardItems,
  Divider,
  Loader,
  TextInputSearch,
} from "../../../../components";
import { Box, Center, Image, Stack, Text, View } from "native-base";
import { useFormContext, useInventoryContext } from "../../../../contexts";

import { ImageSourcePropType, Modal, Pressable, StyleSheet, VirtualizedList } from "react-native";
import inventoryHook from "../../hook/useInventory";
import { useEffect, useState } from "react";
import { CancelRight } from "../../../../../assets/images";
import { putInventoryItemService } from "../../../../services/inventoryService";
import { getProvidersService } from "../../../../services/suppliersService";
import { useTabInventory } from "../../hook/useTabInventory";
import homeScreenHooks from "./../../../../screens/Home/hooks/homeScreen";
import Spinner from 'react-native-loading-spinner-overlay';
import { ScrollView } from "react-native-gesture-handler";

interface IListProps {
  caf: string;
  name: string;
  status: string;
  inventoryId: number;
  image?: ImageSourcePropType;
  asset_number: string;
  asset_description: string;
  id: number;
}

export const Performed = ({_mudou, _flatListInventory}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { setValue } = useFormContext();
  const { filteredPerformedData, inventoryData, getInventoryPerformedData, attStatus, isLoading } = inventoryHook();
  const [paramsApi, setParamsApi] = useState<number>(0)
  const { updateCount } = useTabInventory();
  const { getInventoryData } = useInventoryContext();
  const {filteredProvider} = homeScreenHooks()

  useEffect(() => {
    attStatus()
  }, [_mudou, _flatListInventory])

  function openModal(id: number) {
    setModalVisible(true)
    setParamsApi(id)
  }

  async function putApi(id: number) {
    const data = await putInventoryItemService(id, 1);
    if (data?.status) {
      attStatus()
      setModalVisible(false)
      getInventoryData(filteredProvider.company_id, filteredProvider.supplier_id)
      }
  }

  return (
    <View
      style={{
        backgroundColor: "#fff",
      }}
      flex={1}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        space={1}
        px={1}
        my={2}
      >
        <TextInputSearch
          placeholder="Pesquisar"
          onChangeText={(value: string) =>
           setValue("searchPerformed", value.toUpperCase())
          }
        />
      </Stack>
      <Divider />

      {inventoryData !== null ? (
        <ScrollView>
        <VirtualizedList
          data={filteredPerformedData}
          initialNumToRender={4}
          getItemCount={(data: IListProps[]) => data?.length}
          getItem={(data, index) => data[index]}
          keyExtractor={(item: IListProps) => item?.caf}
          extraData={_mudou}
          renderItem={({ item }) => (
            <>
              <Box px={4} py={2}>
                <CardItems success {...item} tab='Performed' openModal={() => openModal(item.id)}/>
              </Box>
              <Divider />
            </>
          )}
        />
        </ScrollView>
      ) : (
        <Center flex={1}>
          <Text fontWeight="bold">
            Nenhuma informação de inventário encontrada!
          </Text>
        </Center>
      )}

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
          <Pressable style={styles.container} onPress={() => setModalVisible(false)}>
            <Pressable style={styles.subcontainer} onPress={() => {}}>
              <Pressable style={styles.subcontainerinfos} onPress={() => putApi(paramsApi)}>
                <Image
                      source={CancelRight}
                      alt="Update provider icon"
                      resizeMode="center"
                      w={30}
                      h={33}
                    />
                <View style={styles.subcontainertext}>
                  
                  <Text style={styles.subtext}>Cancelar Registro de Inventário</Text>
                  <Text style={styles.subtext}>Voltar para PLANEJADO</Text>
                </View>   
                
              </Pressable>
            </Pressable>
          </Pressable>
        </Modal>
        <Spinner visible={isLoading} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  subcontainer: {
    width: 290,
    height: 150,
    backgroundColor: '#BFBFFF',
    display: 'flex',
    justifyContent: 'center',
    borderBottomLeftRadius: 22,
    borderTopLeftRadius: 22,
  },
  subcontainerinfos: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 30,
    alignItems: 'center'
  },
  subcontainertext: {
    marginLeft: 9,
  },
  subtext: {
    fontWeight: '600',
    fontSize: 13,
  }
})
