import { useEffect, useState } from "react";
import { Divider, CircleStatus, Button } from "../../../../components";
import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
  Icon,
} from "native-base";
import { Pressable } from "react-native";
import {
  useAuthContext,
  useRetoolingContext,
  useSupplierContext,
} from "../../../../contexts";
import {
  deleteRetoolingItem,
  getRetoolingHeader,
  getRetoolingDetailList,
  putRetoolingReq,
} from "../../../../services/retoolingService";
import Spinner from "react-native-loading-spinner-overlay/lib";
import moment from "moment";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
import { platformWeb } from "../../../../utils";

interface IRetoolingHeader {
  creation_date: string;
  status: string;
  id: number;
  retooling_order_header_status_id: number;
  supplier_id: number;
}
interface IRetoolingItem {
  id: string;
  asset_number: string;
  asset_description: string;
  retooling_order_detail_status_id: number;
}

export const Content = ({
  route,
  navigation,
}: {
  route: RouteProp<ParamListBase, string>;
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { selectedRetoolingItens, setSelectedRetoolingItens } =
    useRetoolingContext();
  const [isLoading, setIsLoading] = useState(false);
  const [retoolingHeaderInfo, setRetoolingHeaderInfo] =
    useState<IRetoolingHeader[]>();
  const { colors } = useTheme();
  const { currentSupplier } = useSupplierContext();
  const { accountType } = useAuthContext();
  const { headerId } = route.params;

  async function getRetoolingDetails1() {
    if (headerId) {
      const headerData = await getRetoolingHeader(headerId);
      const listData = await getRetoolingDetailList(headerId);

      if (headerData?.status) {
        setRetoolingHeaderInfo(headerData.message);
      }
      if (listData?.status) {
        setSelectedRetoolingItens(listData.result);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    getRetoolingDetails1();
  }, [route]);

  async function updateRetoolingHeader(statusId: number) {
    const data = {
      retooling_order_header_status_id: statusId,
    };
    await putRetoolingReq(headerId, data);

    navigation.navigate("RetoolingScreen1", {
      headerId,
    });
  }

  async function handleDelete(itemId: string) {
    setIsLoading(true);
    await deleteRetoolingItem(itemId);
    await getRetoolingDetails1();
    setIsLoading(false);
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, maxW: "100%" }} bg="#fff">
        {retoolingHeaderInfo ? (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={5}
          >
            <Text>
              {`${moment(retoolingHeaderInfo?.creation_date).format(
                "DD/MM/YYYY"
              )}`}{" "}
              {`${retoolingHeaderInfo?.status}`}
            </Text>
            <Text>Id: {`${retoolingHeaderInfo?.id}`}</Text>
          </Stack>
        ) : (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={5}
          ></Stack>
        )}

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          justifyContent={"space-between"}
        >
          <Box minWidth={24} _web={{ minWidth: 200 }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={"35%"} _web={{ minWidth: "55%" }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
          <Box minWidth={30} _web={{ minWidth: 20 }}>
            <Text color={colors.white}>Status</Text>
          </Box>
          {retoolingHeaderInfo?.status === "Em Preenchimento" && (
            <Box minWidth={30} _web={{ minWidth: 20 }}>
              <Text color={colors.white}>Remover</Text>
            </Box>
          )}
        </Stack>

        <Box flex={1} _web={{ maxH: "lg", overflow: "hidden" }}>
          <FlatList
            nestedScrollEnabled
            data={selectedRetoolingItens}
            contentContainerStyle={{
              marginBottom: 100,
            }}
            renderItem={({ item, index }) => {
              return (
                <Pressable
                  onPress={() =>
                    navigation.navigate("RetoolingItemDetailScreen", {
                      detailId: item.id,
                      headerId,
                      status: retoolingHeaderInfo?.status,
                    })
                  }
                >
                  <Box px={4} py={1} _web={{ px: 1 }}>
                    <VStack
                      direction="row"
                      space={2}
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Box w={110} _web={{ minWidth: 200 }}>
                        <Text>{item?.asset_number}</Text>
                      </Box>
                      <Box w={"35%"} _web={{ minWidth: "55%" }}>
                        <Text>{item?.asset_description}</Text>
                      </Box>
                      {
                        <Box minWidth={30} _web={{ minWidth: 20 }}>
                          <CircleStatus
                            color={
                              item?.retooling_order_detail_status_id === 2
                                ? colors.success[100]
                                : colors.warning[50]
                            }
                          />
                        </Box>
                      }
                      {retoolingHeaderInfo?.status === "Em Preenchimento" && (
                        <Box w={50} _web={{ w: 70 }} paddingLeft={3}>
                          <Pressable onPress={() => handleDelete(item.id)}>
                            <Icon
                              as={FontAwesome}
                              name="trash"
                              color={colors.primary[200]}
                              size={7}
                              alignItems="center"
                            />
                          </Pressable>
                        </Box>
                      )}
                    </VStack>
                    <Box mt={3}>
                      <Divider />
                    </Box>
                  </Box>
                </Pressable>
              );
            }}
          />
        </Box>

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          justifyContent="center"
          px={3}
          py={2}
          _web={{
            mt: 4,
            justifyContent: "space-between",
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#fff",
          }}
        >
          {accountType === "eqf" &&
          retoolingHeaderInfo?.status === "Solicitado" ? (
            <>
              {platformWeb && (
                <Button
                  label="VOLTAR"
                  w="33%"
                  onPress={() =>
                    navigation.navigate("RetoolingScreen1", {
                      currentSupplier,
                    })
                  }
                />
              )}
              <Button
                _web={{ w: "30%" }}
                w="49%"
                label="Não Autorizar"
                onPress={() => updateRetoolingHeader(5)}
              />
              <Button
                _web={{ w: "30%" }}
                w="49%"
                label="Autorizar"
                onPress={() => updateRetoolingHeader(3)}
              />
            </>
          ) : retoolingHeaderInfo?.status === "Em Preenchimento" ? (
            <>
              {platformWeb && (
                <Button
                  label="VOLTAR"
                  w="33%"
                  onPress={() =>
                    navigation.navigate("RetoolingScreen1", {
                      currentSupplier,
                    })
                  }
                />
              )}
              <Button
                label="SELECIONAR ITENS"
                w="49%"
                _web={{ w: "33%" }}
                onPress={() =>
                  navigation.navigate("RetoolingSelectItemListScreen", {
                    headerId,
                  })
                }
              />
              {selectedRetoolingItens?.every(
                (d) => d.retooling_order_detail_status_id === 2
              ) ? (
                <Button
                  label="SOLICITAR"
                  w="49%"
                  isDisabled={selectedRetoolingItens?.length === 0}
                  _web={{ w: "33%" }}
                  onPress={() => updateRetoolingHeader(2)}
                />
              ) : (
                <Button
                  label="SOLICITAR"
                  backgroundColor="#bfbfbf"
                  w="49%"
                  isDisabled={selectedRetoolingItens?.length === 0}
                  _web={{ w: "33%" }}
                  onPress={() => updateRetoolingHeader(2)}
                />
              )}
            </>
          ) : (
            <>
              {platformWeb && (
                <Button
                  label="VOLTAR"
                  justifyContent={"center"}
                  w="33%"
                  onPress={() =>
                    navigation.navigate("RetoolingScreen1", {
                      currentSupplier,
                    })
                  }
                />
              )}
            </>
          )}
        </Stack>
      </VStack>
      <Spinner visible={isLoading} />
    </>
  );
};
