import { BottomNavigation, TemplateWebPages } from "../../components";
import { Center, ScrollView, VStack } from "native-base";
import { platformWeb } from "../../utils";
import { Communication } from "./Components/Content";
import { ParamListBase } from "@react-navigation/native";
import { DrawerScreenProps } from "@react-navigation/drawer";

export const NewCommunicationScreen = ({
  route,
  navigation,
}: DrawerScreenProps<ParamListBase>) => {
  return (
    <>
      <VStack flex={1}>
        {platformWeb ? (
          <ScrollView>
            <TemplateWebPages navigation={navigation}>
              <Center>
                <Communication route={route} navigation={navigation} />
              </Center>
            </TemplateWebPages>
          </ScrollView>
        ) : (
          <>
            <Communication route={route} navigation={navigation} />
            <BottomNavigation navigation={navigation} />
          </>
        )}
      </VStack>
    </>
  );
};
