import { Text, VStack } from "native-base";
import { Modal, StyleSheet } from "react-native";
import { Button } from "../Button";

export const SuccessModal = ({ isVisible, onClose }) => {

  const handleOk = () => {
    onClose();
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <VStack style={styles.modalContainer}>
        <VStack style={styles.modalContent} _web={{ w: '40%', h: '30%' }}>
          <Text style={styles.modalTitle}>Operação realizada com êxito!!!</Text>
          <Button
            label="OK"
            style={styles.modalButton} onPress={handleOk} />

        </VStack>
      </VStack>
    </Modal >
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    width: '80%',
    height: '30%',
    padding: 16,
    justifyContent: 'center',
    borderRadius: 8,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
    textAlign: 'center',
  },
  modalText: {
    fontSize: 16,
    marginBottom: 16,
    textAlign: 'center',
  },
  modalButton: {
    backgroundColor: '#2196F3',
    color: 'white',
    padding: 10,
    borderRadius: 4,
    alignSelf: 'center',
    width: '50%',
  },
  modalButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});