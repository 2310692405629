import { Divider } from "../../../../components";
import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
} from "native-base";
import { Pressable } from "react-native";
import { formatDate, platformWeb } from "../../../../utils";
import Spinner from "react-native-loading-spinner-overlay";
export interface ICommunicationItem {
  creation_date: string;
  status: string;
  qty: number;
  id: string;
}

export interface ICommunicationListProps {
  communicationListData: ICommunicationItem[] | undefined;
  handleItemSelect: (headerId: string) => void;
  onEndReached: () => void;
  onEndReachedThreshold: number;
  isLoading: boolean;
}

export const CommunicationList = ({
  communicationListData,
  handleItemSelect,
  onEndReached,
  onEndReachedThreshold,
  isLoading,
}: ICommunicationListProps) => {
  const { colors } = useTheme();

  return (
    <>
      {communicationListData && communicationListData.length > 0 ? (
        <>
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            bg={colors.primary[50]}
            px={3}
            py={2}
            _web={{ w: "100%", justifyContent: "space-between" }}
          >
            <Box minWidth={20} _web={{ minWidth: 100 }}>
              <Text color={colors.white}>Data</Text>
            </Box>
            <Box minWidth={160} _web={{ minWidth: 150 }}>
              <Text color={colors.white}>Status</Text>
            </Box>
            <Box minWidth={38} _web={{ minWidth: 100 }}>
              <Text color={colors.white}>Qtd</Text>
            </Box>
            <Box minWidth={35} _web={{ minWidth: 100 }}>
              <Text color={colors.white}>Id</Text>
            </Box>
          </Stack>
          {platformWeb ? (
            <Box flex={1} _web={{ maxH: 'lg', overflow: "hidden" }}>
              <FlatList
                nestedScrollEnabled
                data={communicationListData}
                onEndReached={onEndReached}
                onEndReachedThreshold={onEndReachedThreshold}
                contentContainerStyle={{
                  marginBottom: !platformWeb ? 100 : 10,
                }}
                ListFooterComponent={isLoading ? <Spinner visible={isLoading} /> : null}
                renderItem={({ item }) => {
                  return (
                    <Pressable onPress={() => handleItemSelect(item.id)}>
                      <Box px={4} py={2} _web={{ px: 1 }}>
                        <VStack direction="row" space={2} _web={{ justifyContent: "space-between" }} >
                          <Box minWidth={20} _web={{ minWidth: 110 }}>
                            <Text>{formatDate(item?.creation_date)}</Text>
                          </Box>
                          <Box minWidth={160} _web={{ minWidth: 150 }}>
                            <Text>{item?.status}</Text>
                          </Box>
                          <Box minWidth={30} _web={{ minWidth: 90 }}>
                            <Text>{item?.qty}</Text>
                          </Box>
                          <Box minWidth={45} _web={{ minWidth: 90 }}>
                            <Text>{item?.id}</Text>
                          </Box>
                        </VStack>
                        <Box mt={3}>
                          <Divider />
                        </Box>
                      </Box>
                    </Pressable>
                  );
                }}
              />
            </Box>
          ) : (
            <FlatList
              nestedScrollEnabled
              data={communicationListData}
              onEndReached={onEndReached}
              onEndReachedThreshold={onEndReachedThreshold}
              contentContainerStyle={{
                marginBottom: !platformWeb ? 100 : 10,
              }}
              ListFooterComponent={isLoading ? <Spinner visible={isLoading} /> : null}
              renderItem={({ item }) => {
                return (
                  <Pressable onPress={() => handleItemSelect(item.id)}>
                    <Box px={4} py={2} _web={{ px: 1 }}>
                      <VStack direction="row" space={2}>
                        <Box minWidth={20} _web={{ w: 110 }}>
                          <Text>{formatDate(item?.creation_date)}</Text>
                        </Box>
                        <Box minWidth={160} _web={{ w: 640 }}>
                          <Text>{item?.status}</Text>
                        </Box>
                        <Box minWidth={30} _web={{ w: 90 }}>
                          <Text>{item?.qty}</Text>
                        </Box>
                        <Box minWidth={45} _web={{ w: 90 }}>
                          <Text>{item?.id}</Text>
                        </Box>
                      </VStack>
                      <Box mt={3}>
                        <Divider />
                      </Box>
                    </Box>
                  </Pressable>
                );
              }}
            />
          )}
        </>
      ) : (
        <Stack alignItems={"center"} p={5} h={"90%"}>
          <Text>Nenhuma solicitação encontrada</Text>
        </Stack>
      )}
    </>
  );
};
