import React from "react";
import { Button, TextInputSearch } from "../../../../components";
import { HStack, IconButton, Stack, Text, useTheme } from "native-base";
import { MaterialCommunityIcons } from '@expo/vector-icons'; 

interface ISearchNumberCaf {
  handleAssetChange: (value: string) => void;
  handleOpenScanner: (value: boolean) => void;
}

export function SearchNumberCaf({handleAssetChange, handleOpenScanner} : ISearchNumberCaf) {
  const [searchNumber, setSearchNumber] = React.useState('');
  const { colors } = useTheme();
  
  return (
    <>
      <HStack alignItems="center" space={4} mb={3}>
        <Stack space={1} flex={1} h={"110px"} maxW={"70%"}>
          <Text fontWeight={700} fontSize={13} color={colors.black}>
            Numero do Caf
          </Text>
          <TextInputSearch keyboardType='numeric' placeholder="ex: 13211231" onChangeText={(value) => setSearchNumber(value)} value={searchNumber}/>
          <Button py={1.5} px={5} onPress={() => handleAssetChange(searchNumber)} label="Pesquisar" />
        </Stack>
        <IconButton
          maxW={80}
          maxH={80}
          mt={"auto"}
          backgroundColor="primary.600"
          _hover={{ backgroundColor: "primary.700" }}
          onPress={() => handleOpenScanner(true)}
          icon={<MaterialCommunityIcons name="qrcode-scan" size={60} color="white" />}
        />
      </HStack>
    </>
  );
}
