import { useFormContext } from "../../../contexts";
import { useCallback, useContext, useMemo, useState } from "react";
import { MachineImg } from "../../../../assets/images";
import { useGlobalContext } from "./../../../contexts/globalContext";

interface IResponseData {
  asset_description: string;
  asset_number: number;
}

export default function searchRetoolingOptions() {
  const { _retoolingSelectItens } = useGlobalContext()
  const { watchFields, setValue } = useFormContext();
  const [responseData, setResponseData] = useState<IResponseData[] | null>(
    null
  );

  const searchValue = watchFields["searchRetoolingOptions"];

  const filteredData = _retoolingSelectItens?.filter(
    (item) =>
      item?.asset_number.includes(searchValue) ||
      item?.asset_description.includes(searchValue?.toUpperCase())
  );

  const handleFilterData = useCallback(() => {
    setResponseData(filteredData);
  }, []);

  useMemo(() => {
    if (searchValue) {
      setResponseData(filteredData);
    }
  }, [searchValue]);

  return {
    setResponseData,
    _retoolingSelectItens,
    handleFilterData,
    responseData,
  };
}
