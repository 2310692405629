import { Divider, Button, InputDate } from "../../../../components";
import { Text, VStack, Stack, Box, Icon, HStack, Radio, useTheme, Pressable, Center } from "native-base";
import { FontAwesome } from "@expo/vector-icons";
import { TextInput } from "../../../../components/TextInput/index";
import * as DocumentPicker from "expo-document-picker";
import { useState, useCallback } from "react";
import { getRetoolingDetailItem, getRetoolingRecDocuments, postRetoolingRecDocuments, putRetoolingRec, putRetoolingRecDocuments } from "./../../../../services/retoolingService";
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import Spinner from "react-native-loading-spinner-overlay";
import moment from "moment";
import { Union } from "../../../../../assets/images";
import { Image } from "react-native-svg";
import { Linking } from "react-native";

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase> }) => {
  const { colors } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { detailId, headerId, status } = route.params;
  const [files, setFiles] = useState<any[]>([]);
  const [itemDetails, setItemDetails] = useState<any>();
  const [type, setType] = useState<any>("");
  const [production_beguinning_date, setProductionBeguinningDate] = useState<string>("");

  async function getItemDetail() {
    setIsLoading(true);
    if (detailId) {
      const data = await getRetoolingDetailItem(detailId);
      if (data?.status) {
        setItemDetails(data.result);

        if (data.result.production_beguinning_date) {
          setProductionBeguinningDate(
            moment(data.result.production_beguinning_date)
              .utcOffset(0)
              .format("DD/MM/YYYY")
          );
        }
        if (data.result.production_beguinning_date) {
          setType(data.result.type);
        }
        if (route.params?.status !== "Em Preenchimento") {
          const res = await getRetoolingRecDocuments(detailId);
          if (res?.status) {
            setItemDetails({
              ...data.result,
              files: [res?.result],
            });
          }
        }
      }
    }
    setIsLoading(false);
  }

  useFocusEffect(
    useCallback(() => {
      getItemDetail();
    }, [route.params])
  );

  async function saveDetail() {
    setIsLoading(true);
    const data = {
      ...itemDetails,
      production_beguinning_date: moment(
        production_beguinning_date,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD"),
      type: type,
    };
    delete data["asset_number"];
    delete data["asset_description"];
    delete data["id"];
    if (files.length) {
      await postRetoolingRecDocuments(itemDetails.id, files[0].name)
        .then(async (res: any) => {
          const url = res.result;
          await putRetoolingRecDocuments(url, files[0]);
          console.log("Check Point")
          const indexOf = url.indexOf("?");
          const reducedUrl = url.substring(0, indexOf);
          data.files = [reducedUrl];
        })
        .catch((err: any) => console.error(err));
    }
    console.log("Update item:", itemDetails.id, data);
    putRetoolingRec(detailId, data)
      .catch((error: any) => console.log(error))
      .finally(() => {
        setIsLoading(false);
        navigation.navigate("RetoolingItemListScreen", {
          headerId,
        });
      });
  }

  const uploadFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === "success") {
      const file = {
        ...result,
        name:
          itemDetails?.asset_number +
          "-" +
          moment().format("YYYYMMDDHmmss") +
          "_" +
          result.name,
      };
      setFiles([file]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles([]);
  };

  const downloadFile = (fileUrl: string) => {
    Linking.openURL(fileUrl).catch((error) => {
      console.error("Error opening URL:", error);
    });
  };

  return (
    <>
      <VStack flex={1} px={2} py={2} _web={{ px: 20, w: "100%" }} bg="#fff">
        <Stack>
          {itemDetails ? (
            <Center pb={2} _web={{ pb: 5 }}>
              <Text>{`${itemDetails?.asset_number}`}</Text>
              <Text>{`${itemDetails?.asset_description}`}</Text>
            </Center>
          ) : null}
          <Divider />
        </Stack>

        <HStack space={2} py={3}>
          <Radio.Group
            isDisabled={status !== "Em Preenchimento"}
            name="exampleGroup"
            value={type}
            onChange={(value: string) => setType(value)}
          >
            <HStack alignItems="center" justifyContent="space-between">
              <Box w="60%">
                <Radio
                  value="construction"
                  size="sm"
                  isDisabled={status !== "Em Preenchimento"}
                >
                  Reconstrução
                </Radio>
              </Box>
              <Box w="40%">
                <Radio
                  value="revitalization"
                  size="sm"
                  isDisabled={status !== "Em Preenchimento"}
                >
                  Revitalização
                </Radio>
              </Box>
            </HStack>
          </Radio.Group>
        </HStack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="39%" h={10}>
            <Text>Nº Componente / Conjunto (PN)</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.part_numbers}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, part_numbers: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="40%" h={10}>
            <Text>Aplicação(Modelo / Projeto)</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.application?.toString()}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, application: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="40%" h={10}>
            <Text>Metálico / Plástico</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.metallic_or_plastic}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, metallic_or_plastic: value })
              }
            />
          </Box>
        </Stack>
        <Divider />
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="40%" h={10}>
            <Text>Operação</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.operation}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, operation: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          alignItems="center"
        >
          <Box w="40%" h={10}>
            <Text>Nº Cavidades</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.cavity_number?.toString()}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, cavity_number: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          alignItems="center"
        >
          <Box w="40%" h={10}>
            <Text>Denominação</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.denomination}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, denomination: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          alignItems="center"
        >
          <Box w="40%" h={10}>
            <Text>Pedido de Compra Atual</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.investment_order}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, investment_order: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="39%" h={10}>
            <Text>Data Início Produção</Text>
          </Box>
          <Box w="59%">
            <InputDate
              placeholder="DD/MM/AA"
              width={172}
              value={production_beguinning_date}
              onChangeText={(value: string) =>
                setProductionBeguinningDate(value)
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="40%" h={10}>
            <Text>Nº Ciclos Realizados</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.number_of_cycles_performed?.toString()}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({
                  ...itemDetails,
                  number_of_cycles_performed: value,
                })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="40%" h={10}>
            <Text>Nº Ciclos Contratados</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.number_of_contracted_cycles?.toString()}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({
                  ...itemDetails,
                  number_of_contracted_cycles: value,
                })
              }
            />
          </Box>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          space={1}
          alignItems="center"
        >
          <Box w="40%" h={10}>
            <Text>Justificativa</Text>
          </Box>
          <Box w="60%">
            <TextInput
              defaultValue={itemDetails?.justification}
              my={2}
              _web={{ my: 0 }}
              isDisabled={status !== "Em Preenchimento"}
              onChangeText={(value: string) =>
                setItemDetails({ ...itemDetails, justification: value })
              }
            />
          </Box>
        </Stack>
        <Divider />

        {(itemDetails?.files?.length > 0 ||
          route.params?.status === "Em Preenchimento") && (
            <>
              <Stack
                _web={{ px: 10 }}
                direction="row"
                space={3}
                justifyContent="space-between"
                px={2}
                py={3}
              ></Stack>
              <Box px={2} py={2} _web={{ px: 10 }}>
                <Pressable
                  onPress={uploadFile}
                  isDisabled={route.params?.status !== "Em Preenchimento"}
                >
                  <VStack
                    direction="row"
                    space={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" space={2} alignItems="center">
                      <Text fontWeight="bold" w={240}>
                        {route.params?.status === "Em Preenchimento"
                          ? "Anexo (Inserir Documento)"
                          : "Anexo"}
                      </Text>
                    </Stack>
                    {route.params?.status === "Em Preenchimento" && (
                      <Box>
                        <Icon
                          as={FontAwesome}
                          name="pencil-square-o"
                          size={8}
                          color={colors.success[50]}
                        />
                      </Box>
                    )}
                  </VStack>
                </Pressable>

                {files?.map((file: any, index) => (
                  <>
                    <Divider />
                    <VStack
                      direction="row"
                      space={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row" space={2} alignItems="center">
                        <Text w={240}>{file.name}</Text>
                      </Stack>
                      <Pressable onPress={() => handleRemoveFile(index)}>
                        <Icon
                          as={FontAwesome}
                          name="trash"
                          size={8}
                          color={colors.red[600]}
                        />
                      </Pressable>
                    </VStack>
                  </>
                ))}
                {itemDetails?.files?.map((fileUrl: string) => (
                  <>
                    <Divider />
                    <VStack
                      direction="row"
                      space={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row" space={2} alignItems="center">
                        <Text w={240}>
                          {fileUrl.split("?")[0].split("/").pop()}
                        </Text>
                      </Stack>
                      <Pressable onPress={() => downloadFile(fileUrl)}>
                        <Icon
                          as={FontAwesome}
                          name="download"
                          size={8}
                          color={colors.success[50]}
                        />
                      </Pressable>
                    </VStack>
                  </>
                ))}
              </Box>
            </>
          )}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-between" }}
        >
          <Button
            _web={{ w: 500 }}
            w="48%"
            label="VOLTAR"
            onPress={() =>
              navigation.navigate("RetoolingItemListScreen", {
                headerId: headerId,
              })
            }
          />

          <Button
            _web={{ w: 500 }}
            w="48%"
            label="GRAVAR"
            isDisabled={status !== "Em Preenchimento"}
            onPress={() => saveDetail()}
          />
        </Stack>
        <Spinner visible={isLoading} />
      </VStack>
    </>
  );
};
