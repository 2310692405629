import Axios from "axios";
import { api } from "./api";
import * as FileSystem from "expo-file-system";
import axios from "axios";
import { Platform } from "react-native";

export const getCommunicationList = async (
  supplier_id: string,
  searchStatus?: string,
  searchClassfication?: string,
  searchInitialDate?: string,
  searchFinalDate?: string,
  offset?: string | number,
  limit?: string | number
) => {
  let url = `v1/communication_status?supplier_id=${supplier_id}`;
  if (searchStatus) url = url + `&status=${searchStatus}`;
  if (searchClassfication) url = url + `&classification=${searchClassfication}`;
  if (searchInitialDate) url = url + `&initialDate=${searchInitialDate}`;
  if (searchFinalDate) url = url + `&finalDate=${searchFinalDate}`;
  if (offset) url = url + `&offset=${offset}`;
  if (limit) url = url + `&limit=${limit}`;

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCommunicationHeader = (header_id: string) => {
  return api
    .get(`v1/communication_header/${header_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getCommunicationHeaderStatus = () => {
  return api
    .get(`v1/communication_header_status`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postCommunicationHeader = (supplier_id: string) => {
  const body = { supplier_id };
  return api
    .post(`v1/communication_header`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getCommunicationSelectItens = (
  supplier_id: string,
  limit: number,
  offset?: number,
  searchTerm?: string
) => {
  return api
    .get(
      `v1/asset_list?supplier_id=${supplier_id}&limit=${limit}&offset=${offset}&filter=${searchTerm}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postCommunicationAddItem = (
  communication_header_id: number,
  asset_numbers: []
) => {
  const body = { communication_header_id, asset_numbers };
  return api
    .post(`v1/communication_detail`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deleteCommunicationItem = (communication_item_id: string) => {
  return api
    .delete(`v1/communication_detail/${communication_item_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getCommunicationDetailList = (communication_header_id: number) => {
  return api
    .get(`v1/communication_detail_list/${communication_header_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getCommunicationSummarySelect = () => {
  return api
    .get(`v1/communication_header_classification`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postCommunicationRecDocuments = (id: number, fileName: any) => {
  const body = { id: id, fileName: fileName };
  return api
    .post(`v1/communication_header/generate_signed_url`, body)
    .then((response) => {
      console.log("Upload file:", body, response);
      return response.data;
    })
    .catch((error) => {
      console.error("Upload file error:", error.response);
    });
};

export const getCommunicationRecDocuments = (
  communication_order_header_status_id: number
) => {
  return api
    .post(`v1/communication_header/get_signed_url`, {
      communication_order_header_status_id:
        communication_order_header_status_id,
    })
    .then((response) => {
      console.log(communication_order_header_status_id, response);
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const putCommunicationRecDocuments = async (
  presignedUrl: string,
  data: any
) => {
  if (Platform.OS !== "web") {
    const result = await FileSystem.uploadAsync(presignedUrl, data.uri, {
      fieldName: "file",
      httpMethod: "PUT",
      uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
    });
    return result;
  } else {
    try {
      const fileBlob = await fetch(data.uri).then((response) =>
        response.blob()
      );
      await axios.put(presignedUrl, fileBlob, {
        headers: {
          "Content-Type": data.file.type,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const putCommunicationFinish = (
  communication_header_id: number,
  body: any
) => {
  return api
    .put(`v1/communication_header/${communication_header_id}`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
