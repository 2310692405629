import { ReactNode, createContext, useContext, useState } from "react";

interface IselectedCommunicationItens {
    id: number;
    asset_number: string;
    asset_description: string;
}

interface ItensListProviderProps {
    children: ReactNode;
}

const CommunicationContext = createContext({} as any);

const CommunicationContextProvider = ({ children }: ItensListProviderProps) => {
    const [selectedCommunicationItens, setSelectedCommunicationItens] = useState<IselectedCommunicationItens[]>([]);

    return (
        <CommunicationContext.Provider value={{ selectedCommunicationItens, setSelectedCommunicationItens }}>
            {children}
        </CommunicationContext.Provider>
    )
}

const useCommunicationContext = () => {
    return useContext(CommunicationContext);
}

export { CommunicationContextProvider, useCommunicationContext }