import { api } from "./api";

export interface IReportItem {
  id: string,
  name: string
}

interface IGetReportResponse {
  status: boolean,
  result: IReportItem[],
}

export const getReportsService = async (): Promise<IGetReportResponse> => {
  return api
    .get(`/v1/report`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};