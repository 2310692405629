import { useContext } from "react";
import { putDivestimentRec } from "./../../../services/divestimentService";
import { useGlobalContext } from "./../../../contexts/globalContext";

export default function divestimentDetailsHook() {
  const { recDivestiment } = useGlobalContext();

  async function _sendRecDivestiment(divestiment_detail_id: any, file: any) {
    const data = await putDivestimentRec(divestiment_detail_id, file);
    if (data?.status) {
      recDivestiment()
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }


  return {
    _sendRecDivestiment
  }
}