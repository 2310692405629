import {
  Divider,
  Button,
  InputDate,
} from "../../../../components";
import {
  View,
  Text,
  VStack,
  Stack,
  Center,
  Box,
  Icon,
  useTheme,
  ScrollView,
  Pressable,
} from "native-base";
import { FontAwesome } from "@expo/vector-icons";
import { Camm } from "../../../../../assets/images";
import { TextInput } from "../../../../components/TextInput/index";
import { useEffect, useState, useRef, useContext } from "react";
import { Camera } from 'expo-camera'
import { Modal, StyleSheet, SafeAreaView, Image, ImageBackground } from "react-native";
import * as MediaLibrary from 'expo-media-library';
import { shareAsync } from 'expo-sharing'
import { useGlobalContext } from "./../../../../contexts/globalContext";
import * as DocumentPicker from 'expo-document-picker';
import { postDivestimentRecDocuments, putDivestimentRecDocuments } from "./../../../../services/divestimentService";
import { useNavigatorContext } from "./../../../../contexts/navigatorContext";
import divestimentDetailsHook from "../../hook/useDivestmentDetails";


export const Content = () => {
  const { navigateTo } = useNavigatorContext();
  const { colors } = useTheme();
  let cameraRef: any = useRef();
  const [hasCameraPermission, setHasCameraPermission] = useState<any>();
  const [hasMediaLibraryPermission, setHasMediaLibraryPermission] = useState<any>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { _itemDivestimentId, _divestimentHeaderCDate } = useGlobalContext();
  const { _sendRecDivestiment } = divestimentDetailsHook()

  const [_asset_description, _set_asset_description] = useState<string>('');
  const [_asset_location, _set_asset_location] = useState<string>('');
  const [_asset_number, _set_asset_number] = useState<string>('');
  const [_asset_number_duplicated, _set_asset_number_duplicated] = useState<string>('');
  const [_inventory_number_imo_osf, _set_inventory_number_imo_osf] = useState<string>('');
  const [_invoice_number, _set_invoice_number] = useState<string>('');
  const [_last_production_date, _set_last_production_date] = useState<string>('');
  const [_model_project, _set_model_project] = useState<string>('');
  const [_number_of_cycles_performed, _set_number_of_cycles_performed] = useState<string>('');
  const [_part_number_direct, _set_part_number_direct] = useState<string>('');
  const [_part_number_group, _set_part_number_group] = useState<string>('');
  const [_part_number_parts_and_accessories, _set_part_number_parts_and_accessories] = useState<string>('');
  const [_purchase_doc_number, _set_purchase_doc_number] = useState<string>('');
  const [_residual_value, _set_residual_value] = useState<string>('');
  const [_tool_dimensions_w_l_h, _set_tool_dimensions_w_l_h] = useState<string>('');
  const [_tool_storage, _set_tool_storage] = useState<string>('');
  const [_tool_weight_in_kg, _set_tool_weight_in_kg] = useState<string>('');
  const [_file, _setFile] = useState<string>('');

  useEffect(() => {
    _set_asset_description(_itemDivestimentId._asset_description);
    _set_asset_location(_itemDivestimentId._asset_location);
    _set_asset_number(_itemDivestimentId._asset_number);
    _set_asset_number_duplicated(_itemDivestimentId._asset_number_duplicated);
    _set_inventory_number_imo_osf(_itemDivestimentId._inventory_number_imo_osf);
    _set_invoice_number(_itemDivestimentId._invoice_number);
    _set_last_production_date(_itemDivestimentId._last_production_date);
    _set_model_project(_itemDivestimentId._model_project);
    _set_number_of_cycles_performed(_itemDivestimentId._number_of_cycles_performed);
    _set_part_number_direct(_itemDivestimentId._part_number_direct);
    _set_part_number_group(_itemDivestimentId._part_number_group);
    _set_part_number_parts_and_accessories(_itemDivestimentId._part_number_parts_and_accessories);
    _set_purchase_doc_number(_itemDivestimentId._purchase_doc_number);
    _set_residual_value(_itemDivestimentId._residual_value);
    _set_tool_dimensions_w_l_h(_itemDivestimentId._tool_dimensions_w_l_h);
    _set_tool_storage(_itemDivestimentId._tool_storage);
    _set_tool_weight_in_kg(_itemDivestimentId._tool_weight_in_kg);
  }, [])


  const uploadFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === 'success') {
      recFile(result)
    }
  }

  async function recFile(file: any) {
    const data = await postDivestimentRecDocuments(file.name);
    if (data?.status) {
      sendRecFile(data.result, file.uri)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  async function sendRecFile(url: string, file: any) {
    const data = await putDivestimentRecDocuments(url, file);
    if (data?.status) {
      const _indexOf = url.indexOf('?')
      const urlDiminuida = url.substring(0, _indexOf)
      _setFile(urlDiminuida)
    }
    setTimeout(() => {
      console.log('error');
    }, 1500);
  }

  function finishItensConsult() {
    const data = {
      asset_location: _asset_location,
      asset_number_duplicated: _asset_number_duplicated,
      inventory_number_imo_osf: _inventory_number_imo_osf,
      invoice_number: _invoice_number,
      last_production_date: _last_production_date,
      model_project: _model_project,
      number_of_cycles_performed: _number_of_cycles_performed,
      part_number_direct: _part_number_direct,
      part_number_group: _part_number_group,
      part_number_parts_and_accessories: _part_number_parts_and_accessories,
      purchase_doc_number: _purchase_doc_number,
      residual_value: _residual_value,
      tool_dimensions_w_l_h: _tool_dimensions_w_l_h,
      tool_storage: _tool_storage,
      tool_weight_in_kg: _tool_weight_in_kg,
      files: [_file],
    }
    _sendRecDivestiment(_itemDivestimentId.id, data)
  }


  return (
    <>
      <VStack flex={1} px={2} py={2} _web={{ px: 20, maxW: 1200 }} bg="#fff">
        <Stack>
          <Center pb={2} _web={{ pb: 5 }}>
            <Text>{`${_itemDivestimentId.asset_number}`}</Text>
            <Text>{`${_itemDivestimentId.asset_description}`}</Text>
          </Center>
          <Divider />
        </Stack>
        <ScrollView>
          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="39%">
              <Text>Valor Residual (Saldo) : </Text>
            </Box>
            <Box w="60%">
              <TextInput value={_asset_description} onChangeText={_set_asset_description} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Part Number Diretos</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_part_number_direct} onChangeText={_set_part_number_direct} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Part Number Conjunto</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_part_number_group} onChangeText={_set_part_number_group} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Part Number de P&A (reposição)</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_part_number_parts_and_accessories} onChangeText={_set_part_number_parts_and_accessories} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Modelo / Projeto</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_model_project} onChangeText={_set_model_project} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2}>
            <Box w="39%">
              <Text>Última data de Produção</Text>
            </Box>
            <Box w="59%">
              <InputDate placeholder="DD/MM/AA" width={172} value={_last_production_date} onChangeText={_set_last_production_date} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Armazenamento Ferramental</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_tool_storage} onChangeText={_set_tool_storage} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Nº Ciclos Realizados / Ciclo Montagem</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_number_of_cycles_performed} onChangeText={_set_number_of_cycles_performed} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Ferramenta / CAF Duplicado</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_asset_number_duplicated} onChangeText={_set_asset_number_duplicated} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Peso da Ferramenta Kg</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_tool_weight_in_kg} onChangeText={_set_tool_weight_in_kg} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Dimensões da Ferramenta (L - C - H)</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_tool_dimensions_w_l_h} onChangeText={_set_tool_dimensions_w_l_h} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Nº OSF (opcional)</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_inventory_number_imo_osf} onChangeText={_set_inventory_number_imo_osf} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Nº Nota Fiscal de Comodato</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_invoice_number} onChangeText={_set_invoice_number} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Localização (Ender) do Ativo</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_asset_location} onChangeText={_set_asset_location} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} alignItems='center'>
            <Box w="40%">
              <Text>Nº do Pedido de Compras</Text>
            </Box>
            <Box w="60%">
              <TextInput value={_purchase_doc_number} onChangeText={_set_purchase_doc_number} />
            </Box>
          </Stack>
          <Divider />

          <Stack
            _web={{ px: 10 }}
            direction="row"
            space={3}
            justifyContent="space-between"
            px={2}
            py={3}
          >
          </Stack>
          <Box px={2} py={2} _web={{ px: 10 }} >
            <Pressable onPress={uploadFile}>
              <VStack
                direction="row"
                space={2}
                justifyContent="space-between"
                alignItems='center'
              >
                <Stack direction="row" space={2} alignItems='center'>
                  <Text fontWeight='bold' w={240}>Anexo (Inserir Documento)</Text>
                </Stack>
                <Box>
                  <Icon
                    as={FontAwesome}
                    name="pencil-square-o"
                    size={8}
                    color={colors.success[50]}
                  />
                </Box>
              </VStack>
            </Pressable>
          </Box>

        </ScrollView>

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-between" }}
        >
          <Button
            _web={{ w: 500 }}
            w="48%"
            label="VOLTAR"
            onPress={() => navigateTo('DivestmentApprovedScreen')}
          />
          <Button
            _web={{ w: 500 }}
            w="48%"
            label="GRAVAR"
            onPress={() => finishItensConsult()}
          />
        </Stack>
      </VStack>
    </>
  );
};
