import { useNavigatorContext } from "../../../contexts";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { getDivestimentHeaderStatus, getDivestimentSelectItens, getDivestmentList, postDivestimentHeader } from "./../../../services/divestimentService";
import { useContext, useState } from "react";

export default function divestimentHook() {
  const { navigateTo } = useNavigatorContext();
  const { setDivestimentHeaderCDate, _setDivestimentHeaderId, addDivestimentSelectItens, _setDivestimentListSummary } = useGlobalContext()
  const [isLoading, setIsLoading] = useState(true);
  const [_headerStatus, _setHeaderStatus] = useState([]);
  const [_divestimentList, _setDivestimentList] = useState([])

  async function getHeaderStatusDivestiment() {
    const data = await getDivestimentHeaderStatus();
    if (data?.status) {
      _setHeaderStatus(data.result)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getListDivestiment(supplier_id: number,) {
    const data = await getDivestmentList(supplier_id);
    if (data?.status) {
      _setDivestimentList(data.result)

    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }


  async function postHeaderDivestiment(supplier_id: number,) {
    const data = await postDivestimentHeader(supplier_id);
    if (data?.status) {
      setDivestimentHeaderCDate({ "creation_date": (new Date()), "id": data.result.disinvestmentHeaderId, "status": "Em Preenchimento" })
      _setDivestimentHeaderId(data.result)
      getSelectItensDivestiment(supplier_id)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getSelectItensDivestiment(supplier_id: number,) {
    const data = await getDivestimentSelectItens(supplier_id);
    if (data?.status) {
      addDivestimentSelectItens(data.result)
      navigateTo('DivestmentItems')
      console.log(data)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }


  return {
    _headerStatus,
    getHeaderStatusDivestiment,
    _divestimentList,
    getListDivestiment,
    postHeaderDivestiment,
    getSelectItensDivestiment
  }
}