import { putInventoryClosureFinish } from "../../../services/inventoryService";

export default function inventoryClosureHook() {
  
  async function submitInventoryClosure(id: number) {
    const data = await putInventoryClosureFinish(id);
      if (data?.status) {
        console.log(data)
        }
      }

  return {
    submitInventoryClosure
  };
}
