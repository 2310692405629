import { useContext, useState } from "react";
import { putMovimentRec } from "./../../../services/movimentService";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { useNavigatorContext } from "./../../../contexts/navigatorContext";

export default function MovimentSummaryHook() {
  const { navigateTo } = useNavigatorContext();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { MovimentList1 } = useGlobalContext()

  async function _sendRecSummary(moviment_detail_id: any, file: any) {
    setIsLoading(true);
    const data = await putMovimentRec(moviment_detail_id, file);
    if (data?.status) {
      navigateTo("MovimentScreen");
      MovimentList1()
      setIsLoading(false);
    }
    setTimeout(() => {
      console.log('error3');
    }, 1500);
  }


  return {
    _sendRecSummary,
    isLoading
  }
}