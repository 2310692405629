import { Text, VStack, Stack, Box, useTheme, Center, FlatList, Checkbox, HStack, } from "native-base";
import { Divider, Button, TextInputSearch } from "../../../../components";
import { useCommunicationContext, useSupplierContext } from "../../../../contexts";
import { useEffect, useState } from "react";
import Spinner from "react-native-loading-spinner-overlay";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { getCommunicationSelectItens, postCommunicationAddItem } from "../../../../services/communicationService";
import { platformWeb } from "../../../../utils";

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase> }) => {

  const { colors } = useTheme();
  const { currentSupplier } = useSupplierContext();
  const { selectedCommunicationItens } = useCommunicationContext();
  const [data, setData] = useState([]);
  const [checkBoxValues, setCheckBoxValues] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { headerId } = route.params;

  const toggleProductSelection = (selectedCommunicationItens) => {
    setCheckBoxValues(selectedCommunicationItens.map((item) => item.asset_number));
  };

  const handleCheckboxChange = (itemId) => {
    setCheckBoxValues((prevCheckBoxValues) => {
      const isItemChecked = prevCheckBoxValues.includes(itemId);
      if (isItemChecked) {
        return prevCheckBoxValues.filter((id) => id !== itemId);
      } else {
        return [...prevCheckBoxValues, itemId];
      }
    });
  };

  async function getAssetListData(id: number, limit: number, offset: number) {
    const assetList = await getCommunicationSelectItens(id, limit, offset, searchTerm);
    if (assetList?.status) {
      return assetList.result;
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (currentSupplier) {
      loadApi();
    }
    toggleProductSelection(selectedCommunicationItens);
  }, [currentSupplier, searchTerm]);

  async function loadApi() {
    if (isLoading) return;
    setIsLoading(true);
    const response = await getAssetListData(currentSupplier, limit, offset);
    setData([...data, ...response]);

    if (response.length > 0) {
      setOffset(offset + 1);
    }

    setIsLoading(false);
  }

  function handleChangeInput(text: string) {
    setSearchInput(text);
  }

  function handleSearch() {
    setSearchTerm(searchInput);
    setOffset(0);
    setData([]);
  }

  async function AddItems() {
    setIsLoading(true);
    if (!headerId) return;

    const uncheckedValues = checkBoxValues.filter((value) => {
      const isItemAlreadySaved = selectedCommunicationItens.some((item) => item.asset_number === value);
      return !isItemAlreadySaved;
    });

    try {
      if (uncheckedValues.length > 0) {
        await postCommunicationAddItem(headerId, uncheckedValues);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    navigation.navigate("CommunicationItemListScreen", {
      headerId: headerId,
    });

  }

  const renderItem = ({ item }) => {
    return (
      <>
        <Box px={4} py={2} _web={{ px: 1 }}>
          <VStack direction="row" space={2}>
            <Box _web={{ w: "11%" }}>
              <Text>{item.asset_number}</Text>
            </Box>
            <Box w={200} _web={{ w: "75%" }}>
              <Text>{item.asset_description}</Text>
            </Box>
            <Center>
              <Box _web={{ w: "2%" }}>
                <Checkbox
                  value={item.asset_number}
                  isChecked={checkBoxValues.includes(item.asset_number)}
                  onChange={() => handleCheckboxChange(item.asset_number)}
                  accessibilityLabel="Selecione este item"
                />
              </Box>
            </Center>
          </VStack>
          <Box mt={3}>
            <Divider />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <VStack flex={1} _web={{ px: 10, w: "100%", mt: 50 }} bg="#fff">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={1}
          px={1}
          my={2}
        >
          <TextInputSearch
            value={searchInput}
            placeholder="Pesquisar"
            onChangeText={handleChangeInput}
          />
          <Button label="Pesquisar" onPress={handleSearch} />
        </Stack>
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          _web={{ mt: 0 }}
        >
          <Box minWidth={100} _web={{ w: "10%" }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={150} _web={{ w: "75%" }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
          <Box minWidth={10}>
            <Text color={colors.white}>Selecionar</Text>
          </Box>
        </Stack>

        <Box flex={1} _web={{ maxH: 'md', overflow: "hidden" }}>
          <FlatList
            style={{ flex: 1 }}
            nestedScrollEnabled
            data={data}
            renderItem={renderItem}
            onEndReached={loadApi}
            onEndReachedThreshold={platformWeb ? 0.1 : 0.1}
            ListFooterComponent={isLoading ? <Spinner visible={isLoading} /> : null}
          />
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          px={2}
          py={3}
          _web={{ justifyContent: "space-between" }}
        >
          <Button
            w="48%"
            _web={{ w: "48%" }}
            label="VER SOLICITAÇÃO"
            onPress={() => navigation.goBack()}
          />
          <Button
            w="48%"
            _web={{ w: "48%" }}
            label="INCLUIR ITENS"
            onPress={() => AddItems()}
          />
        </Stack>
      </VStack>
    </>
  );
};
