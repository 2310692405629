import { BottomNavigation, TemplateWebPages } from "../../components";
import { VStack } from "native-base";
import { platformWeb } from "../../utils";
import { Content } from "./components/Content";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";

export const InventoryClosureScreen = ({ navigation }: DrawerScreenProps<ParamListBase>) => {
  return (
    <>
      <VStack flex={1}>
        {platformWeb ? (
          <TemplateWebPages navigation={navigation}>
            <Content navigation={navigation}/>
          </TemplateWebPages>
        ) : (
          <>
            <Content navigation={navigation}/>
            <BottomNavigation navigation={navigation}/>
          </>
        )}
      </VStack>
    </>
  );
};
