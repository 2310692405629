import { NewDashboardScreen } from "../screens";
import { createStackNavigator } from "@react-navigation/stack";

const DashboardStack = createStackNavigator();

export const DashboardNavigator = () => {
    return (
        <DashboardStack.Navigator id="DashboardStack" screenOptions={{ headerShown: false, }}>
            <DashboardStack.Screen
                name="DashboardScreen"
                component={NewDashboardScreen}
            />
            {/* <DashboardStack.Screen
                name="DashboardDetail"
                component={NewDashboardDetail}
            /> */}

        </DashboardStack.Navigator>
    );
};