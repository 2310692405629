import React from "react";

interface IModalAlertContext {
  showModal: boolean | undefined;
  setShowModal: React.Dispatch<boolean>
}
const ModalAlertContext = React.createContext({} as IModalAlertContext);

const ModalAlertContextProvider = ({ children }: any) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <ModalAlertContext.Provider
      value={{
        showModal,
        setShowModal,
      }}
    >
      {children}
    </ModalAlertContext.Provider>
  );
};

const useModalAlertContext = () => {
  return React.useContext(ModalAlertContext);
};

export { ModalAlertContextProvider, useModalAlertContext };
