import { api } from "./api";

export const getDashboardList = async (supplier_id: string) => {
    try {
        const response = await api.get(`v1/dashboard?supplier_id=${supplier_id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
