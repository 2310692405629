import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  Center,
  FlatList,
  Checkbox,
  View,
} from "native-base";
import {
  Divider,
  Button,
  TextInputSearch,
  Pagination,
} from "../../../../components";
import { FontAwesome } from "@expo/vector-icons";
import { useFormContext, useNavigatorContext } from "../../../../contexts";
import { useContext, useEffect, useState } from "react";
import { platformWeb } from "../../../../utils";
import { useGlobalContext } from "./../../../../contexts/globalContext";
import divestimentItemsHook from "../../hook/useDivestimentItems";
import searchDivestmentOptions from "../../hook/searchDivestmentOptions";
import { Platform } from "react-native";

export const Content = () => {
  const { colors } = useTheme();
  const { navigateTo } = useNavigatorContext();
  const { setValue } = useFormContext();
  const { setResponseData, handleFilterData, responseData } = searchDivestmentOptions();
  const { _divestimentSelectItens, _divestimentHeaderCDate } = useGlobalContext();
  const { postAddItemDivestiment } = divestimentItemsHook()

  const [_checkBox, _setCheckBox] = useState([])

  useEffect(() => {
    setResponseData(_divestimentSelectItens);
  }, []);


  function addCheckBox(index: any) {
    const item = responseData[index].asset_number.toString()
    if (_checkBox.includes(item)) {
      // Valor já existe na array, remove ele
      _setCheckBox(prevState => prevState.filter(i => i !== item));
    } else {
      // Valor não existe na array, adiciona ele
      _setCheckBox(prevState => [...prevState, item]);
    }
  }

  function AddItems() {
    postAddItemDivestiment(_divestimentHeaderCDate.id, _checkBox)
  }

  return (
    <>
      <View flex={1} _web={{ px: 10, w: "100%", mt: 50 }} bg="#fff">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={1}
          px={1}
          my={2}
        >
          <TextInputSearch
            placeholder="Pesquisar"
            onChangeText={(value: string) =>
              setValue("searchDivestmentOptions", value.toUpperCase())
            }
          />
        </Stack>

        {Platform.OS === 'web' && (
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              space={2}
              px={2}
              py={3}
              _web={{ justifyContent: "space-between" }}
            >
              <Button
                w="48%"
                _web={{ w: "48%" }}
                label="VER SOLICITAÇÃO"
                onPress={() => navigateTo('DivestmentApprovedScreen')}
              />
              <Button
                w="48%"
                _web={{ w: "48%" }}
                label="INCLUIR ITENS"
                onPress={() => AddItems()}
              />
            </Stack>
          </Box>)}

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          _web={{ mt: 0 }}
        >
          <Box minWidth={100} _web={{ w: "10%" }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={150} _web={{ w: "80%" }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
          <Box minWidth={10}>
            <Text color={colors.white}>Selecionar</Text>
          </Box>
        </Stack>
        <FlatList
          nestedScrollEnabled
          data={responseData}
          contentContainerStyle={{
            marginBottom: !platformWeb ? 100 : 10,
          }}
          renderItem={({ item, index }) => {
            return (
              <Box px={4} py={2} _web={{ px: 1 }}>
                <VStack direction="row" space={2}>
                  <Box _web={{ w: "11%" }}>
                    <Text>{item?.asset_number}</Text>
                  </Box>
                  <Box w={200} _web={{ w: "80%" }}>
                    <Text>{item?.asset_description}</Text>
                  </Box>
                  <Center>
                    <Box _web={{ w: "2%" }}>
                      <Checkbox value={""} accessibilityLabel="Apenas um Checkbox" onChange={() => addCheckBox(index)} />
                    </Box>
                  </Center>
                </VStack>
                <Box mt={3}>
                  <Divider />
                </Box>
              </Box>
            );
          }}
        />
        {Platform.OS !== 'web' && (
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              space={2}
              px={2}
              py={3}
              _web={{ justifyContent: "space-between" }}
            >
              <Button
                w="48%"
                _web={{ w: "48%" }}
                label="VER SOLICITAÇÃO"
                onPress={() => navigateTo('DivestmentApprovedScreen')}
              />
              <Button
                w="48%"
                _web={{ w: "48%" }}
                label="INCLUIR ITENS"
                onPress={() => AddItems()}
              />
            </Stack>
          </Box>)}
      </View>
    </>
  );
};
