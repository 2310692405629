import { useContext, useState } from "react";
import { getRetoolingDetailId, putRetoolingReq } from "./../../../services/retoolingService";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { useNavigatorContext } from "../../../contexts";

export default function retoolingApprovedHook() {
  const [isLoading, setIsLoading] = useState(true);
  const { addItemRetoolingId, recRetoolingS } = useGlobalContext()
  const { navigateTo } = useNavigatorContext();

  async function getDetailIdRetooling(retooling_detail_id: number) {
    const data = await getRetoolingDetailId(retooling_detail_id);
    if (data?.status) {
      addItemRetoolingId(data.result)
      navigateTo('RetoolingDetailsScreen')
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function putReqRetooling(retooling_detail_id: number, body: any) {
    const data = await putRetoolingReq(retooling_detail_id, body);
    if (data?.status) {
      console.log('finalizado', data)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  return {
    getDetailIdRetooling,
    putReqRetooling
  }
}