import { useEffect, useState } from "react";
import { Alert, StyleSheet } from "react-native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";
import { HStack, ScrollView, Stack, Text, VStack, View } from "native-base";
import {
  Button,
  ButtonReadQrCode,
  CameraCustom,
  Divider,
} from "../../../../components";
import { SearchNumberCaf } from "../SearchNumberCaf";
import Spinner from "react-native-loading-spinner-overlay";
import { useInventoryContext } from "../../../../contexts";
import { getInventoryCodeService } from "../../../../services/inventoryService";
import { ItemForm } from "../ItemForm";
import { useCamera } from "../../hook/useCamera";
import { useItemForm } from "../../hook/useItemForm";
import { useSendInventory } from "../../hook/useSendInventory";
import { useCreateItem } from "../../hook/useCreateItem";

export interface IInventoryItem {
  asset_description: string;
  asset_id: number;
  asset_number: string;
  equipment_base_condition_id: any;
  inventory_number_imo_osf: any;
  number_of_cycles_performed: any;
  there_is_label_y_n: any;
  production_status: any;
  id: number;
  inventory_order_detail_status_id: number;
  info_asset_number: string;
}

export function Content({
  navigation,
  itemId,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
  itemId: string | undefined;
}) {
  const { inventoryData, updateInventoryData } = useInventoryContext();
  const [isLoading, setIsLoading] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const [assetNumber, setAssetNumber] = useState<string>();
  const [itemData, setItemData] = useState<IInventoryItem>();
  const itemFormData = useItemForm();
  const {
    openCamera,
    listPhotos,
    handleOpenCamera,
    handleSavePhotos,
    handleCloseCamera,
    handleClearPhotos,
  } = useCamera();
  const sendInventory = useSendInventory();
  const { createItem } = useCreateItem();

  function _every(currentvalue: any) {
    return currentvalue === true;
  }
  const allphotos = listPhotos
    .map((item: { uri: string }) => item.uri !== "")
    .every(_every);

  const clearForm = () => {
    setAssetNumber(undefined);
    setItemData(undefined);
    handleClearPhotos();
  };

  const setupForm = (itemData: any) => {
    itemFormData.handleBaseConditionId(itemData.equipment_base_condition_id);
    itemFormData.handleInventoryNumber(itemData.inventory_number_imo_osf);
    itemFormData.handleCyclesPerformed(itemData.number_of_cycles_performed);
    itemFormData.handleLabel(itemData.there_is_label_y_n);
    itemFormData.handleProductionStatus(itemData.production_status);
  };

  useEffect(() => {
    clearForm();
    setAssetNumber(itemId);
  }, [itemId]);

  useEffect(() => {
    async function getInventoryItem() {
      if (inventoryData && assetNumber) {
        setIsLoading(true);
        try {
          const result = await getInventoryCodeService(inventoryData.id, assetNumber);
          if (result?.status) {
            setItemData(result?.result);
            setupForm(result?.result);
          }
        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
      }
    }

    getInventoryItem();
  }, [assetNumber]);

  function handleAssetChange(value: string) {
    setAssetNumber(value);
  }

  function handleOpenScanner(value: boolean) {
    setOpenScanner(value);
  }

  function handleCreateAsset() {
    try {
      createItem(inventoryData.id, assetNumber);
      Alert.alert("CAF Criado", "O CAF foi criado com sucesso!", [
        { text: "OK", onPress: () => navigation.navigate("InventoryScreen") },
      ]);
    } catch (error: any) {
      Alert.alert("Erro!", error, [
        { text: "OK", onPress: () => console.log("OK Pressed") },
      ]);
    }
    clearForm();
    updateInventoryData();
  }

  async function handleSend() {
    setIsLoading(true);
    try {
      if (itemData && itemFormData) {
        const { lat, lng } = await sendInventory.getLocation();
        const data = {
          inventory_order_detail_status_id:
            itemData.inventory_order_detail_status_id,
          inventory_number_imo_osf: itemFormData.inventoryNumber,
          equipment_base_condition_id: itemFormData.baseConditionId,
          production_status: itemFormData.productionStatus,
          number_of_cycles_performed: itemFormData.cyclesPerformed,
          there_is_label_y_n: itemFormData.label,
          lat,
          lng,
        };
        if (listPhotos.length) {
          const urls = await sendInventory.getUrls(itemData?.id);
          const photosUrls = await Promise.all(
            urls.map(async (item: string, index: any) => {
              const url = await sendInventory.uploadPhoto(
                item,
                listPhotos[index].uri
              );
              return url;
            })
          );
          await sendInventory.updateItem(itemData.id, photosUrls, data);
        } else {
          await sendInventory.updateItem(itemData.id, undefined, data);
        }
      }
    } catch (error: any) {
      Alert.alert("Erro!", error, [
        { text: "OK", onPress: () => console.log("OK Pressed") },
      ]);
    }
    clearForm();
    updateInventoryData();
    setIsLoading(false);
    navigation.navigate("InventoryScreen");
  }

  if (openCamera) {
    return (
      <View flex={1} style={styles.camera}>
        <CameraCustom
          handleSavePhotos={handleSavePhotos}
          handleCloseCamera={handleCloseCamera}
        />
      </View>
    );
  }

  if (openScanner)
    return (
      <ButtonReadQrCode
        setValue={setAssetNumber}
        setOpenScanner={setOpenScanner}
      />
    );

  return (
    <VStack flex={1}>
      <ScrollView>
        <Stack flex={1} p={2}>
          <SearchNumberCaf
            handleAssetChange={handleAssetChange}
            handleOpenScanner={handleOpenScanner}
          />
          <Divider />
          {itemData ? (
            <ItemForm
              itemData={itemData}
              listPhotos={listPhotos}
              handleOpenCamera={handleOpenCamera}
              itemFormData={itemFormData}
            />
          ) : assetNumber ? (
            <Stack alignItems="center" my={2} space={1}>
              <Text fontSize={16}>CAF não Encontrado.</Text>
              <Button
                w="49%"
                py={1.5}
                px={5}
                onPress={() => handleCreateAsset()}
                label="Criar CAF"
              />
            </Stack>
          ) : (
            <Stack alignItems="center" my={2} space={1}>
              <Text fontSize={16}>Pesquise um CAF</Text>
            </Stack>
          )}
        </Stack>
      </ScrollView>
      <HStack space={2} justifyContent="space-between" p={2}>
        <Button
          ml={-2}
          w="49%"
          py={1.5}
          px={5}
          onPress={() => navigation.goBack()}
          variant="outline"
          label="Cancelar"
          borderColor="#008B9C"
          colorText="#008B9C"
        />
        <Button
          w="49%"
          py={1.5}
          px={5}
          onPress={() => handleSend()}
          label="Inventariar"
          isDisabled={!allphotos}
        />
      </HStack>
      <Spinner visible={isLoading} />
    </VStack>
  );
}

const styles = StyleSheet.create({
  camera: {
    zIndex: 10,
    ...StyleSheet.absoluteFillObject,
  },
});
