import { BottomNavigation, TemplateWebPages } from "../../components";
import { VStack } from "native-base";
import { platformWeb } from "../../utils";
import { Content } from "./Components/Content";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";

type ParamList = {
  EnterCodeScreen: { itemId: string } | undefined;
}

export const EnterCodeScreen = ({ route, navigation }: DrawerScreenProps<ParamList>) => {
  const itemId = route.params?.itemId;

  return (
    <>
      <VStack flex={1}>
        {platformWeb ? (
          <TemplateWebPages navigation={navigation}>
            <Content navigation={navigation} itemId={itemId} />
          </TemplateWebPages>
        ) : (
          <>
            <Content navigation={navigation} itemId={itemId} />
            <BottomNavigation navigation={navigation} />
          </>
        )}
      </VStack>
    </>
  );
};
