import { useEffect, useState } from "react";
import { Divider, TextInput } from "../../../../components";
import { HStack, Radio, Select, Stack, Text } from "native-base";
import { Photos } from "../Photos";

export interface IInventoryItem {
  asset_description: string;
  asset_id: number;
  asset_number: string;
  equipment_base_condition_id: any;
  inventory_number_imo_osf: any;
  number_of_cycles_performed: any;
  there_is_label_y_n: any;
  production_status: any;
  id: number;
  inventory_order_detail_status_id: number;
  info_asset_number: string;
}

export interface IListPhotos {
  uri: string;
  namePhoto: string;
}

export interface IItemProps {
  itemData: IInventoryItem,
  listPhotos: IListPhotos[],
  handleOpenCamera: (namePhoto: string) => void,
  itemFormData: any
}

export function ItemForm({ itemData, listPhotos, handleOpenCamera, itemFormData }: IItemProps) {
  return (
    <>
      <Stack alignItems="center" my={2} space={1}>
        <Text fontSize={13}>CAF - {itemData.asset_number === '000000000000' ? itemData.info_asset_number : itemData.asset_number }</Text>

        <Text fontSize={13}>{itemData.asset_description}</Text>
      </Stack>
      <Divider />
      {itemData.inventory_order_detail_status_id === 1 ||
        itemData.inventory_order_detail_status_id === 6 ? (
        <>
          <HStack alignItems="center" py={3} space={2}>
            <Text flex={1} fontWeight={700} fontSize={13}>
              Condição Base do Equipamento:
            </Text>
            <Select
              minWidth="200px"
              height={9}
              accessibilityLabel="Selecione um fornecedor"
              placeholder="Selecione"
              _selectedItem={{
                bg: "teal.600",
              }}
              selectedValue={itemFormData.baseConditionId?.toString()}
              onValueChange={(itemValue) => itemFormData.handleBaseConditionId(itemValue)}
            >
              <Select.Item label="Local coberto" value="1" />
              <Select.Item label="Local não coberto - Ao tempo" value="2" />
            </Select>
          </HStack>

          <Divider />

          <HStack alignItems="center" py={3} space={2}>
            <Text fontWeight={700} fontSize={13}>
              Nº Inventário / Imo / “incolor”
            </Text>
            <TextInput
              value={itemFormData.inventoryNumber?.toString()}
              keyboardType='numeric'
              fontSize={13}
              onChangeText={(itemValue) => itemFormData.handleInventoryNumber(itemValue)}
            />
          </HStack>

          <Divider />

          <HStack alignItems="center" py={3} space={2}>
            <Text fontWeight={700} fontSize={13}>
              Número de Golpes Atual
            </Text>
            <TextInput
              value={itemFormData.cyclesPerformed?.toString()}
              keyboardType='numeric'
              placeholder="Tooling Tracking"
              fontSize={13}
              onChangeText={(itemValue) => itemFormData.handleCyclesPerformed(itemValue)}
            />
          </HStack>

          <Divider />

          <Stack py={3} space={2}>
            <HStack alignItems="center" space={2}>
              <Text fontWeight={700} fontSize={13}>
                Tem Etiqueta?
              </Text>
              <Radio.Group
                name="label"
                value={itemFormData.label?.toString()}
                accessibilityLabel="Tem etiqueta?"
                onChange={(itemValue) => itemFormData.handleLabel(itemValue)}
              >
                <HStack ml={4} space={2} alignItems="center">
                  <Radio
                    value="1"
                    accessibilityLabel="Sim, tem etiqueta!"
                    size="sm"
                  >
                    Sim
                  </Radio>
                  <Radio
                    value="0"
                    accessibilityLabel="Não tem etiqueta!"
                    size="sm"
                  >
                    Não
                  </Radio>
                </HStack>
              </Radio.Group>
            </HStack>

            <HStack alignItems="center" space={2}>
              <Text fontWeight={700} fontSize={13}>
                Em Produção:
              </Text>
              <Radio.Group
                name="production"
                value={itemFormData.productionStatus}
                onChange={(itemValue) => itemFormData.handleProductionStatus(itemValue)}
                accessibilityLabel="Em produção"
              >
                <HStack ml={4} space={2} alignItems="center">
                  <Radio
                    value="yes"
                    accessibilityLabel="Sim, em produção!"
                    size="sm"
                    ml={"2px"}
                  >
                    Sim
                  </Radio>
                  <Radio
                    value="no"
                    accessibilityLabel="Não está em produção!"
                    size="sm"
                  >
                    Não
                  </Radio>
                  <Radio
                    value="pea"
                    accessibilityLabel="Não está em produção!"
                    size="sm"
                  >
                    PeA
                  </Radio>
                </HStack>
              </Radio.Group>
            </HStack>
          </Stack>
          <Divider />
          <Photos listPhotos={listPhotos} handleOpenCamera={handleOpenCamera} />
        </>
      ) : (
        <Stack alignItems="center" my={2} space={1}>
          <Text fontSize={13}>Item já inventariado</Text>
        </Stack>
      )}
    </>
  );
}
