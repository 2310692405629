import {
  Button,
  Divider,
  InputDate,
  TextInput,
} from "../../../components";
import {
  Box,
  Center,
  HStack,
  Image,
  ScrollView,
  Select,
  Stack,
  Text,
  VStack,
  View,
} from "native-base";
import { useEffect, useState } from "react";
import { CAM } from "../../../../assets/images";
import { useGlobalContext } from "../../../contexts/globalContext";
import { getAssetList } from "../../../services/activesService";

import { RadioButton } from "react-native-paper";
import ListActivesHook from "../../../screens/Actives/Components/useActivesHook";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";
import { platformWeb } from "../../../utils";
import moment from "moment";

export interface IListPhotos {
  uri: string;
  namePhoto: string;
  icon: any;
}

export const Content = ({
  navigation,
  route,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { putSendForm, getPNumber } = ListActivesHook();
  const { _assetList, _assetTypology, _equipment, _numberCaf }: any =
    useGlobalContext();

  const [listPhotos, setListPhotos] = useState<IListPhotos[]>([
    {
      namePhoto: "Foto 01",
      uri: "",
      icon: CAM,
    },
    {
      namePhoto: "Foto 02",
      uri: "",
      icon: CAM,
    },
    {
      namePhoto: "Foto 03",
      uri: "",
      icon: CAM,
    },
    {
      namePhoto: "Foto 04",
      uri: "",
      icon: CAM,
    },
  ]);
  const [_sendObject, _setSendObject] = useState({
    sublet_y_n: 0,
    asset_typology_id: "",
    equipment_base_condition_id: "",
    number_of_cycles_performed: 0,
    number_of_contracted_cycles: 0,
    acquisition_date: Date(),
    investment_order: "0",
  });
  const [_acquisition_date, _setAcquisition_date] = useState<any>();
  const [active_details, setActive_details] = useState<any>();

  useEffect(() => {
    console.log(active_details);
  }, [active_details]);

  useEffect(() => {
    getActiveDetail();
  }, [route?.params]);

  async function getActiveDetail() {
    const active_details = await getAssetList(_numberCaf);
    setActive_details(active_details.result);
    _setAcquisition_date(
      moment(active_details.result.acquisition_date)
        .utcOffset(0)
        .format("DD/MM/YYYY")
    );
    console.log(active_details);
  }

  function saveSend() {
    const _sendObject = {
      sublet_y_n: active_details.sublet_y_n,
      asset_typology_id: active_details.asset_typology_id,
      equipment_base_condition_id: active_details.equipment_base_condition_id,
      number_of_cycles_performed: active_details.number_of_cycles_performed,
      number_of_contracted_cycles: active_details.number_of_contracted_cycles,
      acquisition_date: moment(_acquisition_date, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      production_status: active_details.production_status,
      investment_order: active_details.investment_order,
    };
    putSendForm(_numberCaf, _sendObject, navigation);
  }

  function navPNumbers() {
    getPNumber(_numberCaf, navigation);
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, maxW: "100%" }} bg="#fff">
        <Stack>
          <Center pb={2} py={1} _web={{ pb: 5 }}>
            <Text>{_assetList.asset_number}</Text>
            <Text>{_assetList.asset_description}</Text>
          </Center>
        </Stack>
        <Divider />

        <ScrollView>
          <Box w="100%">
            <HStack alignItems="center" space={2} py={2}>
              <Box w="39%">
                <Text p={2} fontWeight={"bold"}>
                  Sublocado
                </Text>
              </Box>
              <Box w="60%">
                <View>
                  <RadioButton.Group
                    onValueChange={(value) =>
                      setActive_details((prev: any) => ({
                        ...prev,
                        sublet_y_n: parseInt(value),
                      }))
                    }
                    value={active_details?.sublet_y_n?.toString()}
                  >
                    <HStack alignItems="center" justifyContent="space-between">
                      <Box w="60%" display="flex" flexDirection="row">
                        <RadioButton.Item label="Não" value="0" />
                        <RadioButton.Item label="Sim" value="1" />
                      </Box>
                    </HStack>
                  </RadioButton.Group>
                </View>
              </Box>
            </HStack>
          </Box>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={1}
            py={2}
            alignItems="center"
          >
            <Box w="39%">
              <Text fontWeight="bold" p={2}>
                Tipologia do Ativo
              </Text>
            </Box>
            <Box w="60%" _web={{ minW: 500 }}>
              <Select
                selectedValue={active_details?.asset_typology_id.toString()}
                onValueChange={(value) =>
                  setActive_details((prev: any) => ({
                    ...prev,
                    asset_typology_id: parseInt(value),
                  }))
                }
              >
                {_assetTypology.map((item: { name: string; id: string }) => (
                  <Select.Item label={item.name} value={item.id.toString()} />
                ))}
              </Select>
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={1}
            py={2}
            alignItems="center"
          >
            <Box w="39%">
              <Text fontWeight="bold" p={2}>
                Condições Base do Equipamento
              </Text>
            </Box>
            <Box w="60%" _web={{ minW: 500 }}>
              <Select
                selectedValue={active_details?.equipment_base_condition_id.toString()}
                onValueChange={(value) =>
                  setActive_details((prev: any) => ({
                    ...prev,
                    equipment_base_condition_id: parseInt(value),
                  }))
                }
              >
                {_equipment.map((item: { name: string; id: string }) => (
                  <Select.Item label={item.name} value={item.id.toString()} />
                ))}
              </Select>
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={1}
            py={2}
            alignItems="center"
          >
            <Box w="39%">
              <Text p={2} fontWeight="bold">
                Nº Ciclos Realizados
              </Text>
            </Box>
            <Box w="60%">
              <TextInput
                value={active_details?.number_of_cycles_performed}
                onChangeText={(value) =>
                  setActive_details((prev: any) => ({
                    ...prev,
                    number_of_cycles_performed: parseInt(value),
                  }))
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={1}
            py={2}
            alignItems="center"
          >
            <Box w="39%">
              <Text p={2} fontWeight="bold">
                Nº Ciclos Contratados
              </Text>
            </Box>
            <Box w="60%">
              <TextInput
                value={active_details?.number_of_contracted_cycles}
                onChangeText={(value) =>
                  setActive_details((prev: any) => ({
                    ...prev,
                    number_of_contracted_cycles: parseInt(value),
                  }))
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            alignItems="center"
          >
            <Box w="39%">
              <Text p={2} fontWeight="bold">
                Data de Incorporação
              </Text>
            </Box>
            <Box w="60%">
              <InputDate
                placeholder="DD/MM/AA"
                width="97%"
                value={_acquisition_date}
                onChangeText={(value: string) => _setAcquisition_date(value)}
              />
            </Box>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={1}
            py={2}
            alignItems="center"
          >
            <Box w="39%">
              <Text p={2} fontWeight="bold">
                Pedido de Compra Atual
              </Text>
            </Box>
            <Box w="60%">
              <TextInput
                value={active_details?.investment_order?.toString()}
                onChangeText={(value) =>
                  setActive_details((prev: any) => ({
                    ...prev,
                    investment_order: parseInt(value),
                  }))
                }
              />
            </Box>
          </Stack>
          <Divider />

          <Box w="100%">
            <HStack alignItems="center" space={2} py={2}>
              <Box w="25%">
                <Text p={2} fontWeight={"bold"}>
                  Produção
                </Text>
              </Box>
              <Box w="75%">
                <View>
                  <RadioButton.Group
                    onValueChange={(value) =>
                      setActive_details((prev: any) => ({
                        ...prev,
                        production_status: value,
                      }))
                    }
                    value={active_details?.production_status}
                  >
                    <HStack alignItems="center" justifyContent="space-between">
                      <Box w="60%" flexDirection="row">
                        <RadioButton.Item label="Não" value="no" />
                        <RadioButton.Item label="Sim" value="yes" />
                        <RadioButton.Item label="PeA" value="pea" />
                      </Box>
                    </HStack>
                  </RadioButton.Group>
                </View>
              </Box>
            </HStack>
          </Box>
          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            p={1}
            py={2}
            alignItems="center"
          >
            {listPhotos?.map((item, index) => (
              <Box w="20%" key={index}>
                <Text p={2} fontWeight="bold" textAlign="center">
                  {item.namePhoto}{" "}
                </Text>
                <Box
                  backgroundColor="#BFBFBF"
                  alignItems="center"
                  alignSelf="center"
                  w={65}
                  h={65}
                >
                  <Image
                    source={item?.icon}
                    alt="Update provider icon"
                    marginTop={1}
                    w={55}
                    h={55}
                  />
                </Box>
              </Box>
            ))}
          </Stack>
        </ScrollView>

        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-around" }}
        >
          {platformWeb ? (
            <Button
              _web={{ w: "30%" }}
              w="30%"
              label="VOLTAR"
              onPress={() => navigation.navigate("ActivesScreen")}
            />
          ) : null}
          <Button
            _web={{ w: "30%" }}
            w="30%"
            label="SALVAR"
            onPress={() => saveSend()}
          />

          <Button
            _web={{ w: "30%" }}
            w="30%"
            label="LISTA DE P. NUMBERS"
            onPress={() => navPNumbers()}
          />
        </Stack>
      </VStack>
    </>
  );
};
