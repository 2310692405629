import { createInventoryItem } from "../../../services/inventoryService";

export function useCreateItem() {
  async function createItem(inventoryId: number | undefined, assetNumber: string | undefined) {
    if (!inventoryId) {
      throw new Error("ID do inventário não encontrado");
    }
    if (!assetNumber) {
      throw new Error("Asset number não encontrado");
    }
    try {
      const response = await createInventoryItem(inventoryId, assetNumber);
      return response;
    } catch (error) {
      throw new Error("Erro ao criar o item");
    }
  }

  return {
    createItem,
  };
}
