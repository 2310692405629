import { useFormContext } from "../../../contexts";
import { useContext, useEffect, useMemo, useState } from "react";
import { getInventoryClosure, getInventoryItemsService } from "../../../services/inventoryService";
import homeScreenHooks from "../../Home/hooks/homeScreen";
import { CloseInventoryContext } from '../../../contexts/closeInventoryContext'
import { useTabInventory } from "./useTabInventory";

interface IResponseData {
  caf: string;
  name: string;
  status: string;
  inventoryId: number;
  image?: string;
  asset_number: string;
  asset_description: string;
}

interface IEnterCode {
  id: number;
  asset_id: number;
  inventory_order_header_id: number
}

export default function inventoryHook() {
  const  {addFinishInventory}  = useContext(CloseInventoryContext)
  const { watchFields } = useFormContext();
  const {updateCount} = useTabInventory()

  const [currentTab, setCurrentTab] = useState(0);
  const [offset, setOffset] = useState(1);
  const [limitPages, setLimitPages] = useState(1);


  const [_inventoryData, _setInventoryData] = useState({performed: []});
  const [_inventoryPendingData, _setinventoryPendingData] = useState({pending: []})
  const [_inventoryAnalysisData, _setinventoryAnalysisData] = useState({analysis: []})

  
  const { inventoryData } = homeScreenHooks();
  const id = inventoryData?.id;

  const [isLoading, setIsLoading] = useState(true);
  const searchPerformedValue = watchFields["searchPerformed"];
  const searchPendingValue = watchFields["searchPending"];
  const searchAnalysisValue = watchFields["searchAnalysis"];


  useMemo(() => {
    if (currentTab >= 0) {
      setOffset(1);
    }
  }, [currentTab]);

  async function getInventoryPerformedData() {
    const getStatus = "done";
    const data = await getInventoryItemsService(id, getStatus, offset);

    if (data?.status) {
      _setInventoryData({
        ...inventoryData,
        performed: data?.result,
      });
      setLimitPages(data?.count);
      // setLimitPages(10);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }


  async function getCLInventory(id: number) {
    setIsLoading(true);
    const data = await getInventoryClosure(id);
      if (data?.status) {
        addFinishInventory(data.result)
        setIsLoading(false);
        }
      }


  async function getInventoryPendingData() {
    setIsLoading(true);
    const getStatus = "planned";
    const data = await getInventoryItemsService(id, getStatus, offset);

    if (data?.status) {
      _setinventoryPendingData({
        ...inventoryData,
        pending: data?.result,
      });
      setIsLoading(false);
      setLimitPages(data?.count);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getInventoryAnalysisData() {
    setIsLoading(true);
     const getStatus = "incomplete";
     const data = await getInventoryItemsService(id, getStatus, offset);
     if (data?.status) {
      _setinventoryAnalysisData({ ...inventoryData, analysis: data?.result });
      setIsLoading(false);
     }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  const filteredPerformedData =
    _inventoryData.performed && searchPerformedValue
      ? _inventoryData?.performed?.filter(
          (item: IResponseData) =>
            item?.asset_description.includes(searchPerformedValue) ||
            item?.asset_number.includes(searchPerformedValue)
        )
      : _inventoryData.performed;

  const filteredPendingData =
  _inventoryPendingData.pending && searchPendingValue
      ? _inventoryPendingData?.pending?.filter(
          (item: IResponseData) =>
          item?.asset_description.includes(searchPendingValue) ||
          item?.asset_number.includes(searchPendingValue)
        )
      : _inventoryPendingData.pending;

  const filteredAnalysisData =
  _inventoryAnalysisData.analysis && searchAnalysisValue
      ? _inventoryAnalysisData?.analysis?.filter(
          (item: IResponseData) =>
          item?.asset_description.includes(searchAnalysisValue) ||
          item?.asset_number.includes(searchAnalysisValue)
        )
      : _inventoryAnalysisData.analysis;

  function handleChangeOffset(value: number) {
    setOffset(value);
  }

  function handleNextPage() {
    if (offset < limitPages) {
      setOffset(offset + 1);
    }
  }

  function handlePrevPage() {
    if (limitPages < offset && offset > 0) {
      setOffset(offset - 1);
    } else {
      setOffset(1);
    }
  }

  function attStatus() {
    getInventoryPerformedData();
      getInventoryPendingData();
      getInventoryAnalysisData();
  }

  useEffect(() => {
    attStatus()
  }, [updateCount]);


  return {
    inventoryData,
    filteredPerformedData,
    filteredPendingData,
    filteredAnalysisData,
    getInventoryPerformedData,
    getInventoryPendingData,
    getInventoryAnalysisData,
    isLoading,
    setCurrentTab,
    handleNextPage,
    handlePrevPage,
    offset,
    limitPages,
    handleChangeOffset,
    setIsLoading,
    _inventoryData,
    getCLInventory,
    attStatus,
  };
}
