import {
  Button,
  CardItems,
  Divider,
  Loader,
  TextInputSearch,
} from "../../../../components";
import { Box, Center, FlatList, Image, Stack, Text, View } from "native-base";
import { useEffect, useState } from "react";
import { useFormContext, useInventoryContext } from "../../../../contexts";
import {
  ImageSourcePropType,
  Modal,
  Pressable,
  StyleSheet,
  VirtualizedList,
} from "react-native";
import inventoryHook from "../../hook/useInventory";
import {
  CafNotFound,
  CancelRight,
  Disinvestment,
  Duplicated,
  NotIsBase,
  Third,
  Transfer,
} from "../../../../../assets/images";
import { DrawerItem } from "@react-navigation/drawer";
import {
  getInventoryItemsService,
  putInventoryItemService,
} from "../../../../services/inventoryService";
import homeScreenHooks from "./../../../../screens/Home/hooks/homeScreen";
import Spinner from "react-native-loading-spinner-overlay";

interface IListItem {
  id: number;
  asset_number: string;
  asset_description: string;
  status: string;
}

const drawerOptions = [
  {
    label: "CAF não localizado",
    icon: CafNotFound,
    screenName: null,
  },
  {
    label: "CAF não está na base",
    icon: NotIsBase,
    screenName: null,
  },
  {
    label: "CAF duplicado",
    icon: Duplicated,
    screenName: null,
  },
  {
    label: "CAF Terceirizado",
    icon: Third,
    screenName: null,
  },
  {
    label: "Desinvestido no Ano",
    icon: Disinvestment,
    screenName: null,
  },
  {
    label: "Comodatário Transferido",
    icon: Transfer,
    screenName: null,
  },
];

export const Pending = () => {
  const { inventoryData: inventoryStatusData } = useInventoryContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [inventoryListData, setInventoryListData] = useState<IListItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);

  function openModal(id: number) {
    setModalVisible(true);
  }

  async function getInventoryItemListData(id: number, status: string, limit: number, offset: number) {
    const inventoryList = await getInventoryItemsService(
      id,
      status,
      limit,
      offset
    );
    if (inventoryList.status) {
      return inventoryList.result;
    } else {
      return null;
    }
  }

  async function getFirstPage() {
    setIsLoading(true);
    if (!inventoryStatusData) {
      setIsLoading(false);
      return;
    }
    const inventoryData = await getInventoryItemListData(
      inventoryStatusData.id,
      "planned",
      limit,
      0
    );
    setInventoryListData(inventoryData);
    setOffset(50);
    setIsLoading(false);
  }

  async function getNextPage() {
    if (isLoading) return;
    setIsLoading(true);
    const inventoryList = await getInventoryItemListData(
      inventoryStatusData.id,
      "planned",
      limit,
      offset
    );
    setInventoryListData([...inventoryListData, ...inventoryList]);
    setOffset(offset + 50);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    if (!inventoryStatusData) {
      setIsLoading(false);
      return;
    }
    getFirstPage();
  }, [inventoryStatusData]);

  const renderItem = ({ item }: { item: IListItem }) => {
    return (
      <>
        <Box px={4} py={2}>
          <CardItems {...item} openModal={() => openModal(item.id)} />
        </Box>
        <Divider />
      </>
    );
  };

  const ListEndLoader = () => {
    if (isLoading) {
      // Show loader at the end of list when fetching next page data.
      return <Spinner visible={isLoading} />;
    }
  };

  return (
    <View
      style={{
        backgroundColor: "#fff",
      }}
      flex={1}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        space={1}
        px={1}
        my={2}
      >
        <TextInputSearch
          placeholder="Pesquisar"
          onChangeText={(value: string) => console.log("changed", value)}
        />
      </Stack>
      <Divider />

      {inventoryStatusData !== null ? (
        <Box h={'lg'}>
          <FlatList
            data={inventoryListData}
            renderItem={renderItem}
            onEndReached={getNextPage}
            ListFooterComponent={
              isLoading ? <Spinner visible={isLoading} /> : null
            } // Loader when loading next page.
          />
        </Box>
      ) : (
        <Center flex={1}>
          <Text fontWeight="bold">
            Nenhuma informação de inventário encontrada!
          </Text>
        </Center>
      )}

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <Pressable
          style={styles.container}
          onPress={() => setModalVisible(false)}
        >
          <Pressable style={styles.subcontainer} onPress={() => { }}>
            {drawerOptions?.map((item, index) => (
              <DrawerItem
                key={index}
                label={() => (
                  <Text fontWeight="bold" fontSize={13}>
                    {item?.label}
                  </Text>
                )}
                icon={() => (
                  <Image
                    source={item?.icon}
                    alt="Update provider icon"
                    resizeMode="center"
                    w={30}
                    h={33}
                  />
                )}
                style={{
                  marginTop: -10,
                  marginRight: -25,
                }}
                onPress={() =>
                  console.log("inventoryId:", inventoryStatusData.id)
                }
              />
            ))}
          </Pressable>
        </Pressable>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  subcontainer: {
    width: 290,
    height: 340,
    backgroundColor: "#BFBFFF",
    display: "flex",
    justifyContent: "center",
    borderBottomLeftRadius: 22,
    borderTopLeftRadius: 22,
  },
  subcontainerinfos: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 30,
    alignItems: "center",
  },
  subcontainertext: {
    marginLeft: 9,
  },
  subtext: {
    fontWeight: "600",
    fontSize: 13,
  },
});
