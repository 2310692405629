import { getManagementList, getManagementSelectItens, postManagementHeader } from "./../../../services/managementService";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { useContext, useState } from "react";
import { useNavigatorContext } from "../../../contexts";

export default function managementHook() {
  const { navigateTo } = useNavigatorContext();
  const [isLoading, setIsLoading] = useState(true);
  const [_headerStatus, _setHeaderStatus] = useState([]);
  const [_managementList, _setManagementList] = useState([])
  const { setManagementHeaderCDate, _setManagementHeaderId, addManagementSelectItens } = useGlobalContext()


  async function getListManagement(supplier_id: number,) {
    const data = await getManagementList(supplier_id);
    if (data?.status) {
      _setManagementList(data.result)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }


  async function postHeaderManagement(supplier_id: number,) {
    const data = await postManagementHeader(supplier_id);
    if (data?.status) {
      console.log('header', data.result)
      setManagementHeaderCDate({ "creation_date": (new Date()), "id": data.result.communicationHeaderId, "status": "Em preenchimento" })
      _setManagementHeaderId(data.result)
      getSelectItensManagement(supplier_id)
      navigateTo("ManagementApprovedScreen")
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getSelectItensManagement(supplier_id: number,) {
    const data = await getManagementSelectItens(supplier_id);
    if (data?.status) {
      addManagementSelectItens(data.result)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  return {
    getListManagement,
    _managementList,
    postHeaderManagement,
    getSelectItensManagement,
  }
}