import React from "react";
import {
  Divider,
  Button,
  BottomNavigation,
  PieChart,
} from "../../../../components";
import { View, Text, VStack, Stack, Box, useTheme, Center } from "native-base";
import { formatDate, platformAndroid } from "../../../../utils";
import { Modal, Pressable, SafeAreaView, StyleSheet } from "react-native";

import { CloseInventoryContext } from "../../../../contexts/closeInventoryContext";
import { ScrollView } from "react-native-gesture-handler";
import inventoryClosureHook from "../../hooks/useInventoryClosure";
import { useNavigatorContext } from "../../../../contexts/navigatorContext";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";
import { getInventoryClosure } from "../../../../services/inventoryService";
import { useInventoryContext } from "../../../../contexts";
import Spinner from "react-native-loading-spinner-overlay";

export const Content = ({
  navigation,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { inventoryData, updateInventoryData } = useInventoryContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [inventorySummary, setInventorySummary] = React.useState<any>();
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const { submitInventoryClosure } = inventoryClosureHook();

  React.useEffect(() => {
    setIsLoading(true);
    async function getSumary() {
      const data = await getInventoryClosure(inventoryData.id);
      if (data?.status) {
        setInventorySummary(data.result);
      }
      setIsLoading(false);
    }

    getSumary();
  }, []);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  function handleSubmitModal() {
    submitInventoryClosure(inventorySummary.id);
    setIsOpenModal(false);
    updateInventoryData();
    navigation.navigate("HomeScreen");
  }

  const _soma =
    inventorySummary?.status_2 +
    inventorySummary?.status_3 +
    inventorySummary?.status_4 +
    inventorySummary?.status_5 +
    inventorySummary?.status_7 +
    inventorySummary?.status_8;

  const chartData = [
    {
      name: "Inventario realizado",
      quantity: +((inventorySummary?.status_2 / _soma) * 100).toFixed(2),
      color: "#4F81BD",
      legendColor: "#7F7F7F",
    },
    {
      name: "Não Localizado",
      quantity: +((inventorySummary?.status_3 / _soma) * 100).toFixed(2),
      color: "#C0504D",
    },
    {
      name: "Não está na base",
      quantity: +((inventorySummary?.status_4 / _soma) * 100).toFixed(2),
      color: "#8064A2",
    },
    {
      name: "Duplicado",
      quantity: +((inventorySummary?.status_5 / _soma) * 100).toFixed(2),
      color: "#9BBB59",
    },
    {
      name: "Desinvestido no ano",
      quantity: +((inventorySummary?.status_7 / _soma) * 100).toFixed(2),
      color: "#FFD800",
    },
    {
      name: "Transferido",
      quantity: +((inventorySummary?.status_8 / _soma) * 100).toFixed(2),
      color: "#0086FF",
    },
  ];

  const date = new Date();
  const _realizados = ((inventorySummary?.status_2 / _soma) * 100).toFixed(0);
  const _problems = ((inventorySummary?.total_problems / _soma) * 100).toFixed(
    0
  );
  const _total = ((inventorySummary?.total / _soma) * 100).toFixed(0);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {inventorySummary ? (
        <VStack flex={1} px={2} _web={{ px: 10 }}>
          <ScrollView>
            <Stack
              direction="row"
              space={2}
              alignItems="center"
              justifyContent="space-between"
              px={3}
              py={5}
            >
              <Text>
                Fornecedor: {"\n"}
                {inventorySummary?.supplier}
              </Text>
            </Stack>
            <Divider />

            <Stack
              direction="row"
              space={2}
              alignItems="center"
              justifyContent="space-between"
              px={3}
              py={5}
            >
              <Box w="45%">
                <Text textAlign="right" mb={5}>
                  Início: {formatDate(inventorySummary?.actual_start_date)}{" "}
                </Text>
                <Text textAlign="right">
                  Fim: {formatDate(date.toISOString())}
                </Text>
              </Box>
              <Box w="45%">
                <Text textAlign="left">
                  Inventário Id: {inventorySummary?.id}
                </Text>
              </Box>
            </Stack>

            <PieChart
              data={chartData}
              accessor="quantity"
              border
              title="Ferramentas Inventariadas"
              w={platformAndroid ? 240 : 180}
              h={platformAndroid ? 140 : 120}
            />

            <Center>
              <Box w="90%" mt={10}>
                <Stack direction="row" alignItems="flex-end" space={2}>
                  <Box w={230}>
                    <Text textAlign="right">
                      Inventário Realizado com Sucesso:
                    </Text>
                  </Box>
                  <Box w={10}>
                    <Text textAlign="right">{inventorySummary?.status_2}</Text>
                  </Box>
                  <Box w={10}>
                    <Text textAlign="right">{_realizados}%</Text>
                  </Box>
                </Stack>

                <Stack direction="row" alignItems="flex-end" space={2}>
                  <Box w={230}>
                    <Text textAlign="right">Problemas Documentados: </Text>
                  </Box>
                  <Box w={10}>
                    <Text textAlign="right">
                      {inventorySummary?.total_problems}
                    </Text>
                  </Box>
                  <Box w={10}>
                    <Text textAlign="right">{_problems}%</Text>
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="flex-end" space={2}>
                  <Box w={230}>
                    <Text textAlign="right">Total de Ferramentas: </Text>
                  </Box>
                  <Box w={10}>
                    <Text textAlign="right">{inventorySummary?.total}</Text>
                  </Box>
                  <Box w={10}>
                    <Text textAlign="right">{_total}%</Text>
                  </Box>
                </Stack>
                <Center py={2}>
                  <Text>Usuário: Andreza C. P. da S. NASCIMENTO</Text>
                </Center>
              </Box>
            </Center>

            <View px={2} py={1} _web={{ alignItems: "center", mt: 20 }}>
              <Button
                label="CONFIRMAR ENCERRAMENTO INVENTÁRIO"
                onPress={handleOpenModal}
                _web={{ w: "50%" }}
              />
            </View>
          </ScrollView>
        </VStack>
      ) : (
        <Spinner visible={isLoading} />
      )}

      <Modal
        animationType="slide"
        transparent={true}
        visible={isOpenModal}
        onRequestClose={() => {
          setIsOpenModal(!isOpenModal);
        }}
      >
        <View style={styles.modalIC} >
          <View style={styles.containerModalIC} _web={{ w: "30%" }}>
            <Text style={styles.ModalICTextRegular}>
              Ao encerrar o inventário
            </Text>
            <Text style={styles.ModalICTextRegular}>
              sua situação mudará para:
            </Text>
            <Text style={styles.ModalICTextLarge}>"EM CONCILIAÇÃO"</Text>
            <View style={styles.ModalICContainerButtom}>
              <Pressable
                onPress={() => setIsOpenModal(false)}
                style={styles.ModalICButtom}
              >
                <Text style={styles.ModalICButtomText}>Cancelar</Text>
              </Pressable>
              <Pressable
                onPress={handleSubmitModal}
                style={styles.ModalICButtom}
              >
                <Text style={styles.ModalICButtomText}>Confirmar</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  modalIC: {
    flex: 1,
    position: "relative",
    backgroundColor: "rgba(191, 191, 191, 0.5)",
    display: "flex",
    alignItems: "center",
  },
  containerModalIC: {
    height: 145,
    backgroundColor: "#fff",
    position: "absolute",
    bottom: 0,
    marginBottom: 100,
    opacity: 1,
    borderRadius: 12,
  },
  ModalICTextRegular: {
    fontWeight: "600",
    fontSize: 14,
    textAlign: "center",
    marginTop: 8,
  },
  ModalICTextLarge: {
    fontWeight: "600",
    fontSize: 16,
    textAlign: "center",
    marginTop: 8,
  },
  ModalICContainerButtom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    bottom: 0,
    marginTop: 16,
  },
  ModalICButtom: {
    width: "45%",
    backgroundColor: "#008b9c",
    height: 28,
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ModalICButtomText: {
    fontWeight: "600",
    fontSize: 16,
    color: "#fff",
    textTransform: "uppercase",
  },
});
