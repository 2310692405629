import { Button } from "../../../../components";
import { Text, VStack, Stack, } from "native-base";
import { Modal, Pressable, StyleSheet, View } from "react-native";
import { useCallback, useState } from "react";
import Spinner from 'react-native-loading-spinner-overlay';
import { useAuthContext, useSupplierContext } from "../../../../contexts";
import { MovementList } from "../MovementList";
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import moment from "moment";
import { SearchMovement } from "../SearchMovement";
import { getMovimentList, postMovimentHeader } from "../../../../services/movimentService";
import { ModalAlert } from "../../../../components/ModalAlert";
import { AxiosError } from "axios";

export interface IMovementItem {
  creation_date: string;
  status: string;
  qty: number;
  id: string;
  movement_order_header_status_id: number;
  onEndReached: () => void;
  onEndReachedThreshold: number;
}

export const Movement = ({
  route,
  navigation,
}: {
  route: RouteProp<ParamListBase, string>;
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {

  const { currentSupplier } = useSupplierContext();
  const { token } = useAuthContext();
  const [movementListData, setMovementListData] = useState<IMovementItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(15);
  const [lastOrder, setLastOrder] = useState<IMovementItem | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchStatus, setSearchStatus] = useState<string>();
  const [searchInitialDate, setSearchInitialDate] = useState<string>();
  const [searchFinalDate, setSearchFinalDate] = useState<string>();
  const [showAlert, setShowAlert] = useState(false);

  const handleCloseAlert = () => {
    setShowAlert(false);
    navigation.navigate("HomeScreen");
  };

  useFocusEffect(
    useCallback(() => {

      const getMovementList = async () => {
        setIsLoading(true);
        try {
          // if (currentSupplier) {
          //   loadApi();
          // }
          loadApi();
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      getMovementList();
    }, [currentSupplier, searchStatus, searchInitialDate, searchFinalDate, route])
  );

  async function getMovementListData(id: number, searchStatus: string, searchInitialDate: string, searchFinalDate: string, offset: number, limit: number) {
    try {
      const movementList = await getMovimentList(id, searchStatus, searchInitialDate, searchFinalDate, offset, limit);

      if (movementList?.status) {
        return movementList.result;
      } else {
        return [];
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 400 && !currentSupplier) {
        setShowAlert(true);
      }
      return [];
    }
  }

  async function loadApi() {
    if (isLoading) return;
    setIsLoading(true);
    const response = await getMovementListData(currentSupplier, searchStatus, searchInitialDate, searchFinalDate, offset, limit);
    if (movementListData) {
      setMovementListData([...movementListData, ...response]);
    }

    if (response.length > 0) {
      setOffset(offset + limit);
    }

    setIsLoading(false);
  }
  function handleSearch(
    status?: string,
    initialDate?: string,
    finalDate?: string
  ) {
    let iDate;
    let fDate;

    if (initialDate) {
      iDate = moment(initialDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    }
    if (finalDate) {
      fDate = moment(finalDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    }

    if (status === "0") {
      setSearchStatus(undefined);
    } else {
      setSearchStatus(status);
    }
    setSearchInitialDate(iDate);
    setSearchFinalDate(fDate);
    setOffset(0);
    setMovementListData([]);
  }

  function handleItemSelect(headerId: string) {
    navigation.navigate("MovementItemListScreen", { headerId: headerId });
  }

  async function handleRequestMovement() {
    const lastOrderWithStatusOne = movementListData?.reduce<IMovementItem | null>((prevOrder, currOrder) => {
      if (
        currOrder.movement_order_header_status_id === 1 &&
        (!prevOrder || currOrder.creation_date > prevOrder.creation_date)
      ) {
        return currOrder;
      }
      return prevOrder;
    }, null);

    if (lastOrderWithStatusOne) {
      setModalVisible(true);
      setLastOrder(lastOrderWithStatusOne);
    } else {
      createMovement();
    }
  }

  async function createMovement() {
    setModalVisible(false);
    if (currentSupplier) {
      const data = await postMovimentHeader(currentSupplier);
      if (data?.status) {
        const headerId = data.result.movementHeaderId;
        navigation.navigate("MovementItemListScreen", {
          headerId: headerId,
        });
      }
    }
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, w: "100%" }} bg="#fff">
        <SearchMovement handleSearch={handleSearch} />
        <MovementList
          movementListData={movementListData}
          handleItemSelect={handleItemSelect}
          onEndReached={loadApi}
          onEndReachedThreshold={0.1}
          isLoading={isLoading}
        />

        <Stack px={10} py={2} /*_web={{position: 'fixed', bottom: 0, left: '50%'}}*/>
          <Button
            label="SOLICITAR MOVIMENTAÇÃO"
            onPress={() => handleRequestMovement()}
          />
        </Stack>
      </VStack >

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Já existe uma solicitação</Text>
            <Text style={styles.modalText}>"EM PREENCHIMENTO".</Text>
            <Text style={styles.modalText}>Deseja criar uma nova?</Text>
            <View style={styles.buttons}>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => {
                  setModalVisible(false);
                  if (lastOrder) {
                    navigation.navigate("MovementItemListScreen", {
                      headerId: lastOrder.id,
                    });
                  }
                }
                }
              >
                <Text style={styles.textStyle}>Manter Atual</Text>
              </Pressable>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => createMovement()}
              >
                <Text style={styles.textStyle}>Criar Nova</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      <ModalAlert isVisible={showAlert} onClose={handleCloseAlert} />
      <Spinner visible={isLoading} />
    </>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    paddingLeft: 20,
    paddingRight: 20,
    padding: 10,
    elevation: 2,
    marginLeft: 10,
    marginRight: 10,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 10,
    textAlign: "center",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
  },
});