import React from "react";

interface ISupplierContext {
  currentSupplier: string | undefined;
  setCurrentSupplier: React.Dispatch<string>
}
const SupplierContext = React.createContext({} as ISupplierContext);

const SupplierContextProvider = ({ children }: any) => {
  const [currentSupplier, setCurrentSupplier] = React.useState<string>();


  return (
    <SupplierContext.Provider
      value={{
        currentSupplier,
        setCurrentSupplier,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};

const useSupplierContext = () => {
  return React.useContext(SupplierContext);
};

export { SupplierContextProvider, useSupplierContext };
