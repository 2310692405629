import { useEffect, useState } from "react";
import { Divider, CircleStatus, Button } from "../../../../components";
import {
  View,
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
  Icon,
} from "native-base";
import { Pressable } from "react-native";
import { useMovementContext, useSupplierContext } from "../../../../contexts";
import {
  deleteMovementItem,
  getMovementHeader,
  getMovimentDetailList,
} from "../../../../services/movimentService";
import Spinner from "react-native-loading-spinner-overlay/lib";
import moment from "moment";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
import { platformWeb } from "../../../../utils";

// interface IMovementHeader {
//   creation_date: string;
//   status: string;
//   id: string;
//   movement_order_header_status_id: number;
// }

// interface IMovementItem {
//   id: string;
//   asset_number: string;
//   asset_description: string;
//   movement_order_detail_status_id: number;
// }

export const Content = ({
  route,
  navigation,
}: {
  route: RouteProp<ParamListBase, string>;
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [movementHeaderInfo, setMovementHeaderInfo] = useState<any>();
  const { colors } = useTheme();
  const { currentSupplier } = useSupplierContext();
  const { selectedMovementItens, setSelectedMovementItens } =
    useMovementContext();
  const { headerId } = route.params;

  async function getMovementDetails() {
    if (headerId) {
      const headerData = await getMovementHeader(headerId);

      const listData = await getMovimentDetailList(headerId);
      if (headerData?.status) {
        setMovementHeaderInfo(headerData.result);
      }
      if (listData?.status) {
        setSelectedMovementItens(listData.result);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    getMovementDetails();
  }, [route]);

  async function handleDelete(itemId: string) {
    setIsLoading(true);
    await deleteMovementItem(itemId);
    await getMovementDetails();
    setIsLoading(false);
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, maxW: "100%" }} bg="#fff">
        {movementHeaderInfo ? (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={5}
          >
            <Text>
              {`${moment(movementHeaderInfo.creation_date).format(
                "DD/MM/YYYY"
              )}`}{" "}
              {`${movementHeaderInfo?.status}`}
            </Text>
            <Text>Id: {`${movementHeaderInfo?.id}`}</Text>
          </Stack>
        ) : (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={5}
          ></Stack>
        )}

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          justifyContent={"space-between"}
        >
          <Box w={110} _web={{ minWidth: 200 }}>
            <Text color={colors.white}>CAF</Text>
          </Box>
          <Box minWidth={"35%"} _web={{ minWidth: "55%" }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
          <Box minWidth={30} _web={{ minWidth: 20 }}>
            <Text color={colors.white}>Status</Text>
          </Box>
          {movementHeaderInfo?.status === "Em Preenchimento" && (
            <Box minWidth={30} _web={{ minWidth: 20 }}>
              <Text color={colors.white}>Remover</Text>
            </Box>
          )}
        </Stack>

        <Box flex={1} _web={{ maxH: "lg", overflow: "hidden" }}>
          <FlatList
            nestedScrollEnabled
            data={selectedMovementItens}
            contentContainerStyle={{
              marginBottom: 100,
            }}
            renderItem={({ item, index }) => {
              return (
                <Box px={4} py={1} _web={{ px: 1 }}>
                  <VStack
                    direction="row"
                    space={2}
                    alignItems="center"
                    justifyContent={"space-between"}
                  >
                    <Box minWidth={24} _web={{ minWidth: 200 }}>
                      <Text>{item?.asset_number}</Text>
                    </Box>
                    <Box w={"35%"} _web={{ minWidth: "55%" }}>
                      <Text>{item?.asset_description}</Text>
                    </Box>
                    <Box minWidth={30} _web={{ minWidth: 20 }}>
                      <CircleStatus
                        color={
                          movementHeaderInfo?.movement_order_header_status_id >=
                          2
                            ? colors.success[100]
                            : colors.warning[50]
                        }
                      />
                    </Box>
                    {movementHeaderInfo?.status === "Em Preenchimento" && (
                      <Box w={50} _web={{ w: 70 }} paddingLeft={3}>
                        <Pressable onPress={() => handleDelete(item.id)}>
                          <Icon
                            as={FontAwesome}
                            name="trash"
                            color={colors.primary[200]}
                            size={7}
                          />
                        </Pressable>
                      </Box>
                    )}
                  </VStack>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                </Box>
              );
            }}
          />
        </Box>

        {movementHeaderInfo?.status !== "Realizado" ? (
          <Stack
            direction="row"
            space={2}
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={2}
            _web={{
              mt: 4,
              justifyContent: "space-between",
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              background: "#fff",
            }}
          >
            {platformWeb ? (
              <Button
                label="Voltar"
                w="33%"
                onPress={() =>
                  navigation.navigate("MovementScreen", {
                    currentSupplier,
                  })
                }
              />
            ) : null}
            <Button
              label="SELECIONAR ITENS"
              w="49%"
              _web={{ w: "33%" }}
              isDisabled={movementHeaderInfo?.status !== "Em Preenchimento"}
              onPress={() =>
                navigation.navigate("MovementSelectItemListScreen", {
                  headerId,
                })
              }
            />
            {selectedMovementItens?.every(
              (d) => d.movement_order_detail_status_id !== 2
            ) ? (
              <Button
                label="VER SUMÁRIO"
                isDisabled={selectedMovementItens.length === 0}
                w="49%"
                _web={{ w: "33%" }}
                onPress={() =>
                  navigation.navigate("MovementItemSummaryScreen", {
                    headerId,
                  })
                }
              />
            ) : (
              <Button
                label="VER SUMÁRIO"
                backgroundColor="#bfbfbf"
                isDisabled={selectedMovementItens.length === 0}
                w="49%"
                _web={{ w: "33%" }}
                onPress={() =>
                  navigation.navigate("MovementItemSummaryScreen", {
                    headerId,
                  })
                }
              />
            )}
          </Stack>
        ) : (
          <VStack></VStack>
        )}
      </VStack>
      <Spinner visible={isLoading} />
    </>
  );
};
