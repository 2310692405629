import { useTheme } from 'native-base';
import { Modal, Text, Button, View } from 'react-native';

export const NotificationModal = ({ visible, onClose, notifications }) => {

  const { colors } = useTheme();

  return (
    <Modal
      visible={visible}
      animationType="slide"
      transparent={true}
      onRequestClose={onClose}
    >
      <View style={{ flex: 1, alignItems: 'flex-end', padding: 10 }}>
        <View style={{ backgroundColor: colors.primary[100], margin: 20, width: '30%' }}>
          <Text style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10, backgroundColor: colors.primary[600] }}>Notificações</Text>
          {notifications.map((notification, index) => (
            <Text key={index} style={{ marginBottom: 5 }}>{notification.message}</Text>
          ))}
          <Button title="Fechar" onPress={onClose} />
        </View>
      </View>
    </Modal>
  );
};
