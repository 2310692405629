import { Text } from "native-base";
import { Modal, StyleSheet, View } from "react-native";
import { Button } from "../Button";
import { useAuthContext, useModalAlertContext } from "../../contexts";

export const ModalAlert = ({ isVisible, onClose }) => {

    const { setShowModal } = useModalAlertContext();
    // const { isInvalidToken } = useAuthContext();

    // if (isInvalidToken) {
    //     return null; 
    // }

    const handleOk = () => {
        onClose();
    };

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={isVisible}
            onRequestClose={onClose}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.modalTitle}>Não há nenhum fornecedor selecionado</Text>
                    <Text style={styles.modalText}>Por favor, selecione um fornecedor antes de prosseguir.</Text>
                    <Button
                        label="OK"
                        style={styles.modalButton} onPress={handleOk} />

                </View>
            </View>
        </Modal >
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        width: '80%',
        padding: 16,
        borderRadius: 8,
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
        textAlign: 'center',
    },
    modalText: {
        fontSize: 16,
        marginBottom: 16,
        textAlign: 'center',
    },
    modalButton: {
        backgroundColor: '#2196F3',
        color: 'white',
        padding: 10,
        borderRadius: 4,
        alignSelf: 'center',
        width: '50%',
    },
    modalButtonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
});