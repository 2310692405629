import * as React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { accountTypeService, authService } from "../services/authService";

interface IAuthContext {
  signIn: (data: any) => void;
  signOut: () => void;
  getToken: () => Promise<string | null>;
  isLoggedUser: boolean;
  isInvalidUser: boolean | null;
  accountType: string | null;
  loggedUsername: string | null;
  token: string | null;
}
const AuthContext = React.createContext({} as IAuthContext);

const AuthContextProvider = ({ children }: any) => {
  const [isLoggedUser, setIsLoggeUser] = React.useState<boolean>(false);
  const [isInvalidUser, setIsInvalidUser] = React.useState<boolean | null>(
    null
  );
  const [accountType, setAccountType] = React.useState<string | null>(null);
  const [loggedUsername, setLoggedUsername] = React.useState<string | null>(null);
  const [token, setToken] = React.useState<string | null>(null);

  React.useEffect(() => {
    getToken();
    getAccountType();
  }, []);

  async function signIn(data: any) {
    const response = await authService({ data });

    if (response?.status === true) {
      await AsyncStorage.setItem("@Tool_control:token", response?.token);
      setIsLoggeUser(true);
      setIsInvalidUser(false);
      setLoggedUsername(data.email);
      await getAccountType();
    } else {
      setIsLoggeUser(false);
      setIsInvalidUser(true);
    }
  }

  async function signOut() {
    await AsyncStorage.removeItem("@Tool_control:token");
    setIsLoggeUser(false);
    setAccountType(null);
  }

  async function getToken() {
    const token = await AsyncStorage.getItem("@Tool_control:token");
    if (token) {
      setIsLoggeUser(true);
      setToken(token);
      return token;
    } else {
      setIsLoggeUser(false);
      return null;
    }
  }

  async function getAccountType() {
    const response = await accountTypeService();

    if (response?.status === true) {
      const { account_type } = response.result as { account_type: string };
      setAccountType(account_type);
    } else {
      console.log("erro ao pegar account type");
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        getToken,
        isLoggedUser,
        isInvalidUser,
        accountType,
        loggedUsername,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return React.useContext(AuthContext);
};

export { AuthContextProvider, useAuthContext };
