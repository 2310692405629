import React from "react";

export interface IListPhotos {
  uri: string;
  namePhoto: string;
}

export function useItemForm() {
  const [baseConditionId, setBaseConditionId] = React.useState('');
  const [inventoryNumber, setInventoryNumber] = React.useState('');
  const [cyclesPerformed, setCyclesPerformed] = React.useState('');
  const [label, setLabel] = React.useState('');
  const [productionStatus, setProductionStatus] = React.useState('');

  function handleBaseConditionId(value: any) {
    setBaseConditionId(value);
  }

  function handleInventoryNumber(value: any) {
    setInventoryNumber(value);
  }

  function handleCyclesPerformed(value: any) {
    setCyclesPerformed(value);
  }

  function handleLabel(value: any) {
    setLabel(value);
  }

  function handleProductionStatus(value: any) {
    setProductionStatus(value);
  }

  return {
    baseConditionId,
    inventoryNumber,
    cyclesPerformed,
    label,
    productionStatus,
    handleBaseConditionId,
    handleInventoryNumber,
    handleCyclesPerformed,
    handleLabel,
    handleProductionStatus
  };
}
