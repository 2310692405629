import { NewActivesListScreen, ActivesDetailScreen, NewActivesPartNumber } from "../screens";
import { createStackNavigator } from "@react-navigation/stack";

const ActivesListStack = createStackNavigator();

export const ActiveslistNavigator = () => {
  return (
    <ActivesListStack.Navigator id="ActivesListStack" screenOptions={{ headerShown: false, }}>
      <ActivesListStack.Screen
        name="activeDrawer"
        component={NewActivesListScreen}
      />
      <ActivesListStack.Screen
        name="activeDetail"
        component={ActivesDetailScreen}
      />
      <ActivesListStack.Screen
        name="activePartNumbers"
        component={NewActivesPartNumber}
      />

    </ActivesListStack.Navigator>
  );
};
