import {
  Divider,
  CircleStatus,
  Button,
} from "../../../../components";
import {
  View,
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
  Pressable,
} from "native-base";
import { platformWidth } from "../../../../utils";
import { useNavigatorContext, useSupplierContext } from "../../../../contexts";
import { useContext, useEffect } from "react";
import { useGlobalContext } from "./../../../../contexts/globalContext";
import movimentItemsHook from "./../../../../screens/MovimentItems/hook/useMovimentItems";
import homeScreenHooks from "./../../../../screens/Home/hooks/homeScreen";
import movimentHook from "./../../../../screens/MovimentScreen/hook/useMoviment";

export const Content = () => {
  const { colors } = useTheme();
  const { currentSupplier } = useSupplierContext();
  const { navigateTo } = useNavigatorContext();
  const { _movimentHeaderCDate, _flatListMoviment, _movimentListDetails, _setListMoviment } = useGlobalContext()
  const { getDetailListMoviment } = movimentItemsHook()
  const { getSelectItensMoviment } = movimentHook()

  const dateObject = new Date(_movimentHeaderCDate.creation_date);
  const dia = dateObject.getDate();
  const mes = dateObject.getMonth() + 1; // Adiciona 1 pois o mês começa em 0 (janeiro) em JavaScript
  const ano = dateObject.getFullYear();
  const dataFormatada = `${dia}/${mes}/${ano}`;

  useEffect(() => {
    console.log('Movement approved context -->', _movimentHeaderCDate);
    if (_movimentHeaderCDate.id) {
      console.log('Movement approved entrou no if');
      getDetailListMoviment(_movimentHeaderCDate.id);
    }

  }, [_movimentHeaderCDate, _flatListMoviment])


  function selectItems() {
    _setListMoviment(false)
    getSelectItensMoviment(currentSupplier)
  }

  return (
    <>
      {_movimentHeaderCDate.id ?
        (<>
          <VStack flex={1} _web={{ px: 20, w: '100%' }} bg="#fff">
            <Stack
              direction="row"
              space={2}
              alignItems="center"
              justifyContent="space-between"
              px={3}
              py={5}
            >
              <Text>{`${dataFormatada}`}  {`${_movimentHeaderCDate.status}`}</Text>
              <Text>Id: {`${_movimentHeaderCDate.id}`}</Text>
            </Stack>
            <Stack
              direction="row"
              space={2}
              alignItems="center"
              bg={colors.primary[50]}
              px={3}
              py={2}
            >
              <Box minWidth={24} _web={{ w: 200 }}>
                <Text color={colors.white}>CAF</Text>
              </Box>
              <Box minWidth={180} _web={{ w: 750 }}>
                <Text color={colors.white}>Descrição</Text>
              </Box>
            </Stack>
            <FlatList
              extraData={_flatListMoviment}
              nestedScrollEnabled
              data={_movimentListDetails}
              contentContainerStyle={{
                marginBottom: 100,
              }}
              renderItem={({ item, index }) => {
                return (
                  <View>
                    <Box px={4} py={1} _web={{ px: 1 }} >
                      <VStack direction="row" space={2} alignItems='center'>
                        <Box minWidth={24} _web={{ w: 210 }}>
                          <Text>{item?.asset_number}</Text>
                        </Box>
                        <Box w={240} _web={{ w: 756 }}>
                          <Text>{item?.asset_description}</Text>
                        </Box>
                      </VStack>
                      <Box mt={3}>
                        <Divider />
                      </Box>
                    </Box>
                  </View>
                );
              }}
            />
          </VStack>
          <Divider />


          <View maxWidth={platformWidth}>
            <Stack
              direction="row"
              space={2}
              alignItems="center"
              justifyContent="center"
              px={3}
              py={2}
              backgroundColor='#fff'
            >
              {_movimentHeaderCDate.status === "Em Preenchimento" ? (
                <>
                  <Button label="SELECIONAR ITENS" w="49%" onPress={() => selectItems()} />
                  <Button label="VER SUMÁRIO" w="49%" onPress={() => navigateTo("MovimentSummary")} />
                </>
              ) : (
                <Button label="VER SUMÁRIO" w="80%" onPress={() => navigateTo("MovimentSummary")} />
              )}

            </Stack>
          </View>

        </>) : (<Text> Carregando</Text>)}

    </>
  );
};

