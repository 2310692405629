import { Divider } from "../../../../components";
import { Text, VStack, Stack, Box, useTheme, FlatList, Image, Icon, View, Center, HStack } from "native-base";
import Spinner from "react-native-loading-spinner-overlay";
import { Alert, Pressable, StyleSheet, TextInput } from "react-native";
import { platformWeb } from "../../../../utils";
import { useCallback, useState } from "react";
import { getActivesList } from "../../../../services/activesService";
import { useSupplierContext } from "../../../../contexts";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native";
import { DetCAF, ListPNumbers } from "../../../../../assets/images";
import { FontAwesome } from "@expo/vector-icons";
import { Modal } from "react-native";
import { ModalAlert } from "../../../../components/ModalAlert";
import { SearchActivesList } from "../SearchActivesList";

interface IItem {
  id: string;
  asset_number: string;
  asset_description: string;
}

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase> }) => {

  const { colors } = useTheme();
  const { currentSupplier }= useSupplierContext();
  const [activesListData, setActivesListData] = useState<IItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchQuery, setAssetNumber] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [caf, setCaf] = useState("");
  const [assetId, setAssetId] = useState("");
  const [assetDescription, setAssetDescription] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  function openModal(item: IItem) {
    setCaf(item?.asset_number);
    setAssetId(item?.id);
    setAssetDescription(item?.asset_description);
    setModalVisible(true);
  }

  async function getAssetListData(id: string, limit: number, offset: number, searchQuery?: any) {
    try {
      const assetList = await getActivesList(currentSupplier, limit, offset, searchQuery);

      if (assetList?.status) {
        return assetList.result;
      } else {
        return [];
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 400 && !currentSupplier) {
        setShowAlert(true);
      }
      return [];
    }
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
    navigation.navigate("HomeScreen");
  };

  useFocusEffect(
    useCallback(() => {
      loadApi(searchQuery, true);
    }, [currentSupplier, route.params, searchQuery])
  );

  async function loadApi(searchQuery = "", resetList = false) {
    if (isLoading) return;
    setIsLoading(true);

    const newOffset = resetList ? 0 : offset;
    const response = await getAssetListData(currentSupplier, limit, newOffset, searchQuery);

    if (resetList) {
      setActivesListData(response);
    } else {
      setActivesListData((prevData) => [...prevData, ...response])
    }

    setOffset(newOffset + limit);
    setIsLoading(false);
  }

  function handleSearch(AssetNumber?: string) {
    if (AssetNumber) {
      setAssetNumber(AssetNumber);
    }
    else {
      setAssetNumber('');
    }
  }

  const handleLoadMore = () => {
    loadApi(searchQuery, false);
  };

  const renderFooter = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <Spinner visible={isLoading} />
    );
  };


  return (
    <>

      <VStack flex={1} _web={{ px: 10, w: "full", mt: 5 }} bg="#fff">

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={1}
          px={1}
          my={2}
        >
          < SearchActivesList handleSearch={handleSearch} />
          {/* <TextInputSearch
            placeholder="Pesquisar"
            value={searchQuery}
            onChangeText={handleSearch}
          /> */}
        </Stack>

        {activesListData !== null ? (
          <>
            <Stack
              direction="row"
              space={2}
              alignItems="center"
              bg={colors.primary[50]}
              px={3}
              py={2}
              _web={{ w: "full" }}
              justifyContent="space-between"
            >
              <Box minWidth={32} _web={{ minWidth: 90 }}>
                <Text color={colors.white}>CAF</Text>
              </Box>
              <Box minWidth={150} _web={{ minWidth: 640 }}>
                <Text color={colors.white}>Descrição</Text>
              </Box>
              <Box minWidth={25} _web={{ minWidth: 100 }}>
                <Text color={colors.white}>Ações</Text>
              </Box>
            </Stack>
            <Box _web={{ w: "full", h: "lg", maxH: "2xl" }}>
              <FlatList
                showsVerticalScrollIndicator={true}
                data={activesListData}
                keyExtractor={(item) => item?.asset_number?.toString()}
                onEndReached={handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={renderFooter}
                contentContainerStyle={{ marginBottom: !platformWeb ? 10 : 100 }}
                renderItem={({ item }) => {
                  return (
                    <Pressable onPress={() => navigation.navigate("activeDetail", {
                      caf: item?.asset_number,
                    })}
                      style={{ justifyContent: "space-around" }}
                    >
                      <Box px={4} py={2} _web={{ px: 1 }}>
                        <VStack direction="row" space={2} justifyContent={"space-between"}>
                          <Box minWidth={32} _web={{ minWidth: 90 }}>
                            <Text>{item?.asset_number}</Text>
                          </Box>
                          <Box w={150} _web={{ minWidth: 640 }}>
                            <Text>{item?.asset_description}</Text>
                          </Box>
                          <Box minWidth={25} _web={{ minWidth: 100 }}>
                            <Pressable style={{ paddingLeft: 10 }} onPress={() => openModal(item)}>
                              <Icon
                                as={FontAwesome}
                                name="ellipsis-v"
                                color={colors.primary[200]}
                                size={7}
                                alignItems="center"
                              />
                            </Pressable>
                          </Box>
                        </VStack>
                        <Box mt={3}>
                          <Divider />
                        </Box>
                      </Box>
                    </Pressable>
                  );
                }}
              />
            </Box>
          </>
        ) : (
          <Center flex={1}>
            <Text fontWeight="bold">
              Nenhuma informação de inventário encontrada!
            </Text>
          </Center>
        )}

        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert('Modal has been closed.');
            setModalVisible(!modalVisible);
          }}
        >
          <Pressable style={styles.centeredView} onPress={() => setModalVisible(!modalVisible)} >
            <View style={styles.modalView}>
              <Text style={styles.modalText}>NAVEGAÇÃO</Text>
              <Pressable
                onPress={() => {
                  navigation.navigate("activeDetail", {
                    caf: caf,
                  });
                  setModalVisible(false);
                }}
                style={styles.itemContainer}
              >
                <View style={styles.itemContent}>
                  <HStack alignItems="center" space={2}>
                    <Image
                      source={DetCAF}
                      alt="Update provider icon"
                      resizeMode="center"
                      style={styles.iconImage}
                    />
                    <Text style={styles.textStyle}>Detalhe do CAF</Text>
                  </HStack>
                </View>
              </Pressable>
              <Pressable
                onPress={() => {
                  navigation.navigate("activePartNumbers", {
                    id: assetId,
                    caf: caf,
                    asset_description: assetDescription
                  });
                  setModalVisible(false);
                }}
                style={styles.itemContainer}
              >
                <View style={styles.itemContent}>
                  <HStack alignItems="flex-end" space={2}>
                    <Image
                      source={ListPNumbers}
                      alt="Update provider icon"
                      resizeMode="center"
                      style={styles.iconImage}
                    />
                    <Text style={styles.textStyle}>Lista de Part Numbers</Text>
                  </HStack>
                </View>
              </Pressable>
            </View>
          </Pressable>
        </Modal>
      </VStack >

      <ModalAlert isVisible={showAlert} onClose={handleCloseAlert} />
      <Spinner visible={isLoading} />
    </>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: 22,
  },
  modalView: {
    justifyContent: 'center',
    borderRadius: 20,
    padding: 35,
    height: 300,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    backgroundColor: "#D9D9D9"
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  textStyle: {
    color: '#000000',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  itemContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconImage: {
    width: 30,
    height: 33,
  },
});