import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  View,
  ScrollView,
  Select,
  Icon,
  Pressable,
  Spinner,
} from "native-base";
import { Divider, Button, TextInput } from "../../../../components";
import { useAuthContext, useSupplierContext } from "../../../../contexts";
import { useEffect, useState } from "react";
import { ParamListBase, RouteProp } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { FontAwesome } from "@expo/vector-icons";
import {
  getCommunicationHeader,
  getCommunicationRecDocuments,
  getCommunicationSummarySelect,
  postCommunicationRecDocuments,
  putCommunicationFinish,
  putCommunicationRecDocuments,
} from "../../../../services/communicationService";
import * as DocumentPicker from "expo-document-picker";
import moment from "moment";
import { platformWeb } from "../../../../utils";
import { Linking } from "react-native";
interface ICommunicationHeader {
  id: number;
  communication_order_header_status_id: number;
  supplier_id: number;
  message: string;
  creation_date: string;
  completion_date: Date;
  classification_id: number;
  classification: string;
  communication_order_header_classification_id: number;
  acronym: string;
  status: string;
}

export const Content = ({
  route,
  navigation,
}: {
  route: RouteProp<ParamListBase, string>;
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { currentSupplier } = useSupplierContext();
  const { accountType } = useAuthContext();
  const { colors } = useTheme();
  const [summaryDetail, setSummaryDetail] = useState<any>();
  const [isLoading, setIsloading] = useState(false);
  const [classificationOptions, setClassificationOptions] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    setIsloading(true);
    async function getSummaryDetail() {
      if (route.params?.headerId) {
        try {
          const data = await getCommunicationHeader(route.params.headerId);
          if (data?.status) {
            setSummaryDetail(data.result);
            if (data.result?.status !== "Em preenchimento") {
              const res = await getCommunicationRecDocuments(
                route.params.headerId
              );
              if (res?.status) {
                setSummaryDetail({
                  ...data.result,
                  files: [res?.result],
                });
              }
            }
          }
          handleGetItemFile(data.result.id);
        } catch (e) {
          console.log(e.response);
        }
      }
    }
    async function getClassificationOptions() {
      try {
        const data = await getCommunicationSummarySelect();
        if (data?.status) {
          setClassificationOptions(data.result);
        }
      } catch (e) {
        console.log(e);
      }
    }
    getSummaryDetail();
    getClassificationOptions();
  }, []);

  const handleGetItemFile = async (id: number) => {
    const Urls = await getCommunicationRecDocuments(id);
    if (Urls?.status) {
      const docs = Urls.result.map((item: any, index: any) => ({
        name: "filename",
        uri: item,
      }));
      setFiles(docs);
    }
  };

  async function requestCommunication() {
    const body = {
      communication_order_header_status_id: 2,
      communication_order_header_classification_id:
        summaryDetail.communication_order_header_classification_id,
      message: summaryDetail.message,
    };
    if (files.length) {
      await postCommunicationRecDocuments(summaryDetail.id, files[0].name)
        .then(async (res: any) => {
          const url = res.result;
          await putCommunicationRecDocuments(url, files[0]);
          const indexOf = url.indexOf("?");
          const reducedUrl = url.substring(0, indexOf);
          body.file = reducedUrl;
        })
        .catch((err: any) => console.log(err));
    }
    putCommunicationFinish(route.params?.headerId, body)
      .then((res: any) => {
        console.log("Atualizado:", route.params?.headerId, body);
      })
      .catch((error: any) => console.log(error))
      .finally(() => {
        navigation.navigate("CommunicationScreen1", {
          currentSupplier,
        });
      });
  }

  async function updateCommunicationHeader(statusId: number) {
    const body = {
      communication_order_header_status_id: statusId,
    };
    const data = await putCommunicationFinish(route.params?.headerId, body);
    if (data?.status) {
      navigation.navigate("CommunicationScreen1", {
        currentSupplier,
      });
    }
  }

  async function saveFile(file: any) {
    postCommunicationRecDocuments(summaryDetail.id, file.name)
      .then(async (res: any) => {
        await putCommunicationRecDocuments(res.result, file);
      })
      .catch((err: any) => console.log(err));
  }

  const uploadFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === "success") {
      const file = {
        ...result,
        name:
          summaryDetail.id +
          "_" +
          moment().format("YYYYMMDDHmmss") +
          "_" +
          result.name,
      };
      // const concat = files.concat([file])
      setFiles([file]);
    }
  };

  const handleRemoveFile = (index: number) => {
    console.log("Remove file:", index);
    setFiles([]);
  };

  const downloadFile = (fileUrl: string) => {
    Linking.openURL(fileUrl).catch((error) => {
      console.error("Error opening URL:", error);
    });
  };

  return (
    <>
      <View flex={1} _web={{ px: 10, w: "100%", mt: 25 }} bg="#fff">
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
        >
          <Text color="#fff">Área:</Text>
          <Select
            selectedValue={summaryDetail?.communication_order_header_classification_id?.toString()}
            minWidth="200"
            fontSize={14}
            placeholder="Selecione uma Área"
            _selectedItem={{
              bg: "teal.600",
            }}
            color={platformWeb ? colors.gray[600] : "#fff"}
            isDisabled={summaryDetail?.status !== "Em preenchimento"}
            onValueChange={(value: string) =>
              setSummaryDetail((prev: any) => ({
                ...prev,
                communication_order_header_classification_id: value,
              }))
            }
          >
            {classificationOptions &&
              classificationOptions.map((item: any) => (
                <Select.Item
                  key={item.id}
                  label={item.name}
                  value={item.id.toString()}
                />
              ))}
          </Select>
        </Stack>
        <ScrollView>
          <Stack
            direction="column"
            justifyContent="space-between"
            p={2}
            space={1}
            flex={1}
          >
            <Box w="45%">
              <Text>Descrição:</Text>
            </Box>
            <Box w="100%">
              <TextInput
                isDisabled={summaryDetail?.status !== "Em preenchimento"}
                multiline={true}
                fontSize={"13px"}
                textAlign={"left"}
                textAlignVertical={"top"}
                h={250}
                value={summaryDetail?.message}
                onChangeText={(value: string) =>
                  setSummaryDetail((prev: any) => ({ ...prev, message: value }))
                }
              />
            </Box>
          </Stack>
          <Divider />

          {(summaryDetail?.files?.length > 0 ||
            summaryDetail?.status === "Em preenchimento") && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                p={2}
                space={1}
                alignItems="center"
              >
                <Box w="45%">
                  <Text>Anexo: </Text>
                </Box>
                {summaryDetail?.status === "Em preenchimento" && (
                  <Box>
                    <Pressable
                      onPress={uploadFile}
                      disabled={summaryDetail?.status !== "Em preenchimento"}
                    >
                      <Icon
                        as={FontAwesome}
                        name="pencil-square-o"
                        size={8}
                        color={colors.success[50]}
                      />
                    </Pressable>
                  </Box>
                )}
              </Stack>
              {files?.map((file: any, index) => (
                <>
                  <Divider />
                  <VStack
                    direction="row"
                    space={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" space={2} alignItems="center">
                      <Text w={240}>{file.name}</Text>
                    </Stack>
                    <Pressable onPress={() => handleRemoveFile(index)}>
                      <Icon
                        as={FontAwesome}
                        name="trash"
                        size={8}
                        color={colors.red[600]}
                      />
                    </Pressable>
                  </VStack>
                </>
              ))}
              {summaryDetail?.files?.map((fileUrl: string) => (
                <>
                  <Divider />
                  <VStack
                    direction="row"
                    space={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" space={2} alignItems="center">
                      <Text w={240}>
                        {fileUrl.split("?")[0].split("/").pop()}
                      </Text>
                    </Stack>
                    <Pressable onPress={() => downloadFile(fileUrl)}>
                      <Icon
                        as={FontAwesome}
                        name="download"
                        size={8}
                        color={colors.success[50]}
                      />
                    </Pressable>
                  </VStack>
                </>
              ))}
              <Divider />
            </>
          )}
        </ScrollView>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-between" }}
        >
          {summaryDetail?.status !== "Em preenchimento" ? (
            <>
              <Button
                _web={{ w: "50%" }}
                w="48%"
                label="VOLTAR"
                onPress={() => navigation.goBack()}
              />
              <Button
                _web={{ w: "50%" }}
                w="48%"
                isDisabled={
                  accountType !== summaryDetail?.acronym ||
                  summaryDetail?.status !== "Enviada"
                }
                label="Marcar como lida"
                onPress={() => updateCommunicationHeader(4)}
              />
            </>
          ) : (
            <>
              <Button
                _web={{ w: "50%" }}
                w="48%"
                label="VOLTAR"
                onPress={() => navigation.goBack()}
              />
              <Button
                _web={{ w: "50%" }}
                w="48%"
                label="COMUNICAR"
                disabled={summaryDetail?.status !== "Em preenchimento"}
                onPress={() => requestCommunication()}
              />
            </>
          )}
        </Stack>
      </View>
    </>
  );
};
