import { View, StyleSheet, Dimensions } from "react-native";
import { Text, VStack, useTheme } from "native-base";
import { ProgressChart } from "react-native-chart-kit";
import { platformAndroid } from "../../utils";

export const ProgressChartComponent = ({ percentage }) => {

  const screenWidth = Dimensions.get('window').width;
  const chartWidth = platformAndroid ? screenWidth * 0.8 : screenWidth * 0.20;

  const data = {
    data: [percentage / 100],
  };

  return (
    <VStack style={styles.container}>
      <ProgressChart
        data={data}
        width={chartWidth}
        height={200}
        strokeWidth={30}
        radius={80}
        chartConfig={{
          backgroundColor: 'white',
          backgroundGradientFrom: 'white',
          backgroundGradientTo: 'white',
          color: (opacity = 1) => `rgba(22, 160, 133, ${opacity})`,
        }}
        hideLegend={true}
      />
      <View style={styles.percentageContainer}>
        <Text style={styles.percentageText}>{percentage}%</Text>
      </View>
    </VStack>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  percentageContainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    width: 160,
    height: 160,
    borderRadius: 80,
    backgroundColor: "white",
  },
  percentageText: {
    fontSize: 18,
    fontWeight: "bold",
  },
});