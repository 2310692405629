import { Button, Divider } from "../../../components";
import {
  Box,
  FlatList,
  Input,
  Pressable,
  Stack,
  Text,
  useTheme,
  VStack,
} from "native-base";
import { useState } from "react";
import { platformWeb } from "../../../utils";
import { useGlobalContext } from "../../../contexts/globalContext";
import { Modal, StyleSheet, View } from "react-native";
import ListActivesHook from "../../../screens/Actives/Components/useActivesHook";
import { ParamListBase } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";


export interface IListPNumbers {
  pNumber: number;
  description: string;
}

export const Content = ({
  navigation,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  const { _partNumbers, _listActivesOne, _numberCaf, _assetList } =
    useGlobalContext();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { putSendPartNumber, getPNumber, getCLInventory } = ListActivesHook();
  const { colors } = useTheme();
  const [_inputPN, _setInputPN] = useState({
    part_number: "",
    part_number_description: "",
  });

  const _cloneInput = _inputPN;

  const _attNPN = (value: any) => {
    _cloneInput.part_number = value;
    _setInputPN(_cloneInput);
  };
  const _attDesc = (value: any) => {
    _cloneInput.part_number_description = value;
    _setInputPN(_cloneInput);
  };

  async function _sendPartNumber() {
    await putSendPartNumber(_numberCaf, _inputPN);
    getPNumber(_numberCaf, _assetList);
    setIsOpenModal(false);
  }

  function detalhCaf() {
    getCLInventory(_numberCaf, navigation);
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, maxW: "100%" }} bg="#fff">
        <Stack>
          {_listActivesOne && (
            <Box w="100%" alignItems="center" marginTop={2} marginBottom={2}>
              <Text>{_listActivesOne.asset_number}</Text>
              <Text>{_listActivesOne.asset_description}</Text>
            </Box>
          )}
        </Stack>

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          _web={{ w: "100%" }}
        >
          <Box minWidth={24} _web={{ w: 140 }}>
            <Text color={colors.white}>Part Number</Text>
          </Box>
          <Box minWidth={220} _web={{ w: 640 }}>
            <Text color={colors.white}>Descrição</Text>
          </Box>
        </Stack>

        <FlatList
          nestedScrollEnabled
          data={_partNumbers}
          contentContainerStyle={{
            marginBottom: !platformWeb ? 100 : 10,
          }}
          renderItem={({ item }: any) => {
            return (
              <Pressable>
                <Box px={4} py={2} _web={{ px: 1 }}>
                  <VStack direction="row" space={2}>
                    <Box minWidth={20} _web={{ w: 110 }}>
                      <Text>{item?.part_number}</Text>
                    </Box>
                    <Box style={{ flex: 1 }} minWidth={150} _web={{ w: 640 }}>
                      <Text>{item?.part_number_description}</Text>
                    </Box>
                  </VStack>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                </Box>
              </Pressable>
            );
          }}
        />

        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          bg={colors.white}
          space={2}
          py={2}
          px={4}
        >
          {platformWeb ? (
            <Button
            _web={{ w: "30%" }}
              w="30%"
              label="VOLTAR"
              onPress={() => navigation.navigate("ActivesScreen")}
            />
          ) : null}
          <Button
            _web={{ w: "30%" }}
            w="30%"
            alignSelf="center"
            fontWeight="bold"
            label="DETALH. DO CAF"
            onPress={() => detalhCaf()}
          />

          <Button
            _web={{ w: "30%" }}
            w="30%"
            variant="outline"
            colorText="#008B9C"
            alignSelf="center"
            label="+ PART NUMBER"
            fontWeight="bold"
            onPress={() => setIsOpenModal(true)}
          />
        </Stack>
      </VStack>

      <Modal
        animationType="slide"
        transparent={true}
        visible={isOpenModal}
        onRequestClose={() => {
          setIsOpenModal(!isOpenModal);
        }}
      >
        <View style={styles.modalIC}>
          <View style={styles.containerModalIC}>
            <Text style={styles.ModalICTextTitle}>Adicionar Part Number</Text>
            <View style={styles.ModalInput}>
              <Input
                placeholder="Número do Part Number"
                onChangeText={(value) => _attNPN(value)}
              />
            </View>
            <View style={styles.ModalInput}>
              <Input
                placeholder="Descrição do Part Number"
                onChangeText={(value) => _attDesc(value)}
              />
            </View>
            <View style={styles.ModalICContainerButtom}>
              <Pressable
                onPress={() => setIsOpenModal(false)}
                style={styles.ModalICButtom}
              >
                <Text style={styles.ModalICButtomText}>Cancelar</Text>
              </Pressable>
              <View style={{ width: 12 }} />
              <Pressable
                onPress={() => _sendPartNumber()}
                style={styles.ModalICButtom}
              >
                <Text style={styles.ModalICButtomText}>Confirmar</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  modalIC: {
    flex: 1,
    position: "relative",
    backgroundColor: "rgba(191, 191, 191, 0.5)",
    display: "flex",
    alignItems: "center",
  },
  containerModalIC: {
    width: "90%",
    backgroundColor: "#fff",
    position: "absolute",
    bottom: 0,
    marginBottom: 100,
    opacity: 1,
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    padding: 12,
  },
  ModalInput: {
    width: "80%",
    marginBottom: 18,
  },
  ModalICTextTitle: {
    fontWeight: "600",
    fontSize: 18,
    textAlign: "center",
    marginTop: 8,
    marginBottom: 18,
  },
  ModalICTextRegular: {
    fontWeight: "600",
    fontSize: 14,
    textAlign: "center",
    marginTop: 8,
  },
  ModalICTextLarge: {
    fontWeight: "600",
    fontSize: 16,
    textAlign: "center",
    marginTop: 8,
  },
  ModalICContainerButtom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    bottom: 0,
    marginTop: 16,
  },
  ModalICButtom: {
    width: "45%",
    backgroundColor: "#008b9c",
    height: 28,
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ModalICButtomText: {
    fontWeight: "600",
    fontSize: 16,
    color: "#fff",
    textTransform: "uppercase",
  },
});
