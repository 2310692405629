import { Button, Divider } from "../../../../components";
import { Box, FlatList, Input, Pressable, Stack, Text, useTheme, VStack, } from "native-base";
import { useCallback, useState } from "react";
import { platformWeb } from "../../../../utils";
import { Modal, StyleSheet, View } from "react-native";
import { getPartNumber, putNewPartNumber } from "../../../../services/activesService"
import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";

export interface IListPNumbers {
  pNumber: number;
  description: string;
}

export const Content = ({ route, navigation }: { route: RouteProp<ParamListBase, string>; navigation: DrawerNavigationProp<ParamListBase> }) => {
  const { id, caf, asset_description } = route.params;
  const { colors } = useTheme();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [partNumbersList, setPartNumbersList] = useState<any>([]);
  const [itemDetails, setItemDetails] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  async function saveDetails() {
    await putNewPartNumber(id, itemDetails);
    setIsOpenModal(false);
  }

  useFocusEffect(
    useCallback(() => {

      const getRetoolingsList = async () => {

        setIsLoading(true);
        try {

          const data = await getPartNumber(id);
          if (data?.status) {
            setPartNumbersList(data.result);
          }
        } catch (error) {
          console.error('Erro ao carregar dados:', error);
        }

        setIsLoading(false);
      }

      getRetoolingsList();
    }, [route.params, isOpenModal])
  );

  return (
    <>
      <VStack flex={1} _web={{ px: 20, width: "full" }} bg="#fff">
        <Stack>
          {partNumbersList && (
            <Box w="100%" alignItems="center" marginTop={2} marginBottom={2}>
              <Text>{caf}</Text>
              <Text>{asset_description}</Text>
            </Box>
          )}
        </Stack>

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          _web={{ w: "full" }}
          justifyContent="space-around"
        >
          <Box >
            <Text color={colors.white}>Part Number</Text>
          </Box>
          <Box >
            <Text color={colors.white}>Descrição</Text>
          </Box>
        </Stack>

        <FlatList
          nestedScrollEnabled
          data={partNumbersList}
          contentContainerStyle={{
            marginBottom: !platformWeb ? 100 : 10,
          }}
          renderItem={({ item }: any) => {
            return (
              <Box px={4} py={2} _web={{ px: 1 }}>
                <VStack direction="row" space={2} style={{ justifyContent: 'space-around', alignItems: 'center' }}>
                  <VStack alignSelf="flex-start" flex={1}>
                    <Text>{item?.part_number}</Text>
                  </VStack>
                  <VStack alignSelf="center" flex={1}>
                    <Text>{item?.part_number_description}</Text>
                  </VStack>
                </VStack>
                <Box mt={3}>
                  <Divider />
                </Box>
              </Box >
            );
          }}
        />

        <Stack 
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          bg={colors.white}
          space={2}
          py={2}
          px={4}
        >
          {
            platformWeb ? (
              <Button
                _web={{ w: "30%" }}
                w="30%"
                label="VOLTAR"
                onPress={() => navigation.navigate("activeDrawer")}
              />
            ) : null}
          <Button
            _web={{ w: "30%" }}
            w="45%"
            alignSelf="center"
            fontWeight="bold"
            label="DETALH. DO CAF"
            onPress={() => navigation.navigate("activeDetail", {
              id: id,
              caf: caf,
              asset_description: asset_description,
            })}
          />

          <Button
            _web={{ w: "30%" }}
            w="45%"
            variant="outline"
            colorText="#008B9C"
            alignSelf="center"
            label="+ PART NUMBER"
            fontWeight="bold"
            onPress={() => setIsOpenModal(true)}
          />
        </Stack >
      </VStack >

      <Modal
        animationType="slide"
        transparent={true}
        visible={isOpenModal}
        onRequestClose={() => {
          setIsOpenModal(!isOpenModal);
        }}
      >
        <View style={styles.modalContainer}>
          <View style={styles.background}>
            <View style={styles.modalContent}>
              <Text style={styles.ModalICTextTitle}>Adicionar Part Number</Text>
              <View style={styles.ModalInput}>
                <Input
                  placeholder="Número do Part Number"
                  onChangeText={(value: string) => setItemDetails({ ...itemDetails, part_number: Number(value) })}
                />
              </View>
              <View style={styles.ModalInput}>
                <Input
                  placeholder="Descrição do Part Number"
                  onChangeText={(value: string) => setItemDetails({ ...itemDetails, part_number_description: value })}
                />
              </View>
              <View style={styles.ModalICContainerButtom}>
                <Button
                  _web={{ w: "50%" }}
                  w="35%"
                  backgroundColor="#008B9C"
                  borderRadius={8}
                  alignSelf="center"
                  label="Cancelar"
                  fontWeight="bold"
                  onPress={() => setIsOpenModal(false)}
                />
                <View style={{ width: 12 }} />
                <Button
                  _web={{ w: "50%" }}
                  w="35%"
                  backgroundColor="#008B9C"
                  borderRadius={8}
                  alignSelf="center"
                  label="Confirmar"
                  fontWeight="bold"
                  onPress={() => saveDetails()}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal >
    </>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: "90%",
    maxWidth: 1700,
    height: 300,
    backgroundColor: "#FFFF",
    borderRadius: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  ModalInput: {
    width: "80%",
    marginBottom: 18,
  },
  ModalICTextTitle: {
    fontWeight: "600",
    fontSize: 18,
    textAlign: "center",
    marginTop: 8,
    marginBottom: 18,
  },
  ModalICTextRegular: {
    fontWeight: "600",
    fontSize: 14,
    textAlign: "center",
    marginTop: 8,
  },
  ModalICTextLarge: {
    fontWeight: "600",
    fontSize: 16,
    textAlign: "center",
    marginTop: 8,
  },
  ModalICContainerButtom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    bottom: 0,
    marginTop: 16,
  },
  ModalICButtomText: {
    fontWeight: "600",
    fontSize: 16,
    color: "#fff",
    textTransform: "uppercase",
  },
});
