import { getManagementDetailList, postManagementAddItem } from "./../../../services/managementService";
import { useGlobalContext } from "./../../../contexts/globalContext";
import { getMovimentDetailList, postMovimentAddItem } from "./../../../services/movimentService";
import { useContext, useState } from "react";
import { useNavigatorContext } from "./../../../contexts/navigatorContext";

export default function managementItemsHook() {
  const [isLoading, setIsLoading] = useState(true);
  const { addItemsMoviment, _managementHeaderId, addItemsManagement } = useGlobalContext();
  const { navigateTo } = useNavigatorContext();

  console.log(_managementHeaderId)

  async function postAddItemManagement(management_header_id: number, asset_numbers: any) {
    const data = await postManagementAddItem(management_header_id, asset_numbers);
    if (data?.status) {
      console.log('post', data)
      getDetailListManagement(management_header_id)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  async function getDetailListManagement(retooling_header_id: number) {
    console.log('getDetailListManagement id', retooling_header_id);
    const data = await getManagementDetailList(retooling_header_id);
    if (data?.status) {
      console.log('getDetailListManagement DATA', data)
      addItemsManagement(data.result)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }

  return {
    postAddItemManagement,
    getDetailListManagement
  }
}