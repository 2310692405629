import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  Center,
  FlatList,
  Checkbox,
  View,
  ScrollView,
  Icon,
  Select,
  Image,
} from "native-base";
import {
  Divider,
  Button,
  TextInputSearch,
  TextInput,
  InputDate,
} from "../../../../components";
import { useAuthContext, useFormContext, useNavigatorContext } from "../../../../contexts";
import { useContext, useEffect, useState } from "react";
import { platformWeb } from "../../../../utils";

import { FontAwesome } from "@expo/vector-icons";
import { Pressable, Switch } from "react-native";
import { postMovimentRecDocuments, putMovimentRec, putMovimentRecDocuments } from "./../../../../services/movimentService";
import * as DocumentPicker from 'expo-document-picker';
import MovimentSummaryHook from "../../hook/useMovimentSummary";
import { useGlobalContext } from "./../../../../contexts/globalContext";
import { Union } from "./../../../../../assets/images";
import Spinner from 'react-native-loading-spinner-overlay';

export const Content = () => {
  const { accountType } = useAuthContext();
  const { colors } = useTheme();
  const { navigateTo } = useNavigatorContext();
  const [_sublocado, _setSublocado] = useState<boolean>(false)
  const [_sublocado2, _setSublocado2] = useState<boolean>(false)
  const [_image, _setImage] = useState(null);
  const [ativarBotao, setAtivarBotao] = useState<boolean>(true);
  const { _sendRecSummary, isLoading } = MovimentSummaryHook();
  const { _movimentHeaderCDate } = useGlobalContext()

  const [do_not_communicate_before_date, set_do_not_communicate_before_date] = useState<string>('');
  const [expected_return_date, set_expected_return_date] = useState<string>('');
  const [sublet_y_n, set_sublet_y_n] = useState(false);
  const [destination_supplier, set_destination_supplier] = useState<string>('');
  const [destination_sublet_y_n, set_destination_sublet_y_n] = useState(false);
  const [technical_contact_name, set_technical_contact_name] = useState<string>('');
  const [contact_email, set_contact_email] = useState<string>('');
  const [contact_phone, set_contact_phone] = useState<string>('');
  const [destination_tax_id_number, set_destination_tax_id_number] = useState<string>('');
  const [destination_razao_social, set_destination_razao_social] = useState<string>('');
  const [destination_city, set_destination_city] = useState<string>('');
  const [destination_state_or_province, set_destination_state_or_province] = useState<string>('');
  const [reason, set_reason] = useState<string>('');
  const [justification, set_justification] = useState<string>('');
  const [files, set_files] = useState<string>('');

  console.log(files)

  const uploadFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (result.type === 'success') {
      console.log('arquivo', result)
      recDoc(result)
    }
  }

  useEffect(() => {
    set_do_not_communicate_before_date('')
    set_expected_return_date('')
    set_sublet_y_n(false)
    set_destination_supplier('')
    set_destination_sublet_y_n(false)
    set_technical_contact_name('')
    set_contact_email('')
    set_contact_phone('')
    set_destination_tax_id_number('')
    set_destination_razao_social('')
    set_destination_city('')
    set_destination_state_or_province('')
    set_reason('')
    set_justification('')
    set_files('')
  }, [])

  console.log(_movimentHeaderCDate)

  async function recDoc(file: any) {
    const data = await postMovimentRecDocuments(file.name);
    if (data?.status) {
      sendRec(data.result, file.uri)
    }
    setTimeout(() => {
      console.log('error1');
    }, 1500);
  }

  async function sendRec(url: string, file: any) {
    const data = await putMovimentRecDocuments(url, file);
    if (data?.status) {
      const _indexOf = url.indexOf('?')
      const urlDiminuida = url.substring(0, _indexOf)
      set_files(urlDiminuida)
    }
    setTimeout(() => {
      console.log('error2');
    }, 1500);
  }

  function finishSummary() {
    const data = {
      movement_order_header_status_id: 2,
      do_not_communicate_before_date: do_not_communicate_before_date,
      expected_return_date: expected_return_date,
      sublet_y_n: sublet_y_n,
      destination_supplier: destination_supplier,
      destination_sublet_y_n: destination_sublet_y_n,
      technical_contact_name: technical_contact_name,
      contact_email: contact_email,
      contact_phone: contact_phone,
      destination_tax_id_number: destination_tax_id_number,
      destination_razao_social: destination_razao_social,
      destination_city: destination_city,
      destination_state_or_province: destination_state_or_province,
      reason: reason,
      justification: justification,
      files: [files],
    }
    _sendRecSummary(_movimentHeaderCDate.id, data)
  }

  function updateMovementHeader(statusId: number) {
    const data = {
      movement_order_header_status_id: statusId,
    }
    _sendRecSummary(_movimentHeaderCDate.id, data)
  }

  const _options = [
    {
      label: "Troca de Fornecedor",
      value: "Troca de Fornecedor",
    },
    {
      label: "Troca de Localidade",
      value: "Troca de Localidade",
    },
    {
      label: "Retooling",
      value: "Retooling",
    },
    {
      label: "Manutenção Corretiva",
      value: "Manutenção Corretiva",
    },
    {
      label: "Manutenção Preventiva",
      value: "Manutenção Preventiva",
    },
    {
      label: "Modificação",
      value: "Modificação",
    },
    {
      label: "Insourcing",
      value: "Insourcing",
    },
  ]


  return (
    <>
      <View flex={1} _web={{ px: 10, w: "100%", mt: 50 }} bg="#fff">

        <ScrollView>

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="39%">
              <Text>Não Comunicar Antes de:</Text>
            </Box>
            <Box w="54%">
              <InputDate isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? true : true} placeholder="DD/MM/AA" width={160} onChangeText={set_do_not_communicate_before_date} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="39%">
              <Text>Data Prevista de Retorno:</Text>
            </Box>
            <Box w="54%">
              <InputDate placeholder="DD/MM/AA" width={160} onChangeText={set_expected_return_date} />
            </Box>
          </Stack>
          <Divider />


          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>Fornecedor Destino: </Text>
            </Box>
            <Box w="54%">
              <TextInput isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true} fontSize={'13px'} textAlign={'right'} onChangeText={set_destination_supplier} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>Sublocado:</Text>
            </Box>
            <Box w="54%" >
              <Switch
                trackColor={{ false: '#7F7F7F', true: '#008B9C' }}
                value={destination_sublet_y_n}
                onValueChange={() => set_destination_sublet_y_n(!destination_sublet_y_n)}
                disabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true}
              />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>Nome do Contato Técnico:</Text>
            </Box>
            <Box w="54%">
              <TextInput isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true} fontSize={'13px'} textAlign={'right'} onChangeText={set_technical_contact_name} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>e-mail do Contato: </Text>
            </Box>
            <Box w="54%">
              <TextInput isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true} fontSize={'13px'} textAlign={'right'} onChangeText={set_contact_email} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>Telefone do Contato: </Text>
            </Box>
            <Box w="54%">
              <TextInput isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true} fontSize={'13px'} textAlign={'right'} onChangeText={set_contact_phone} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>CNPJ do Destino: </Text>
            </Box>
            <Box w="54%">
              <TextInput isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true} fontSize={'13px'} textAlign={'right'} onChangeText={set_destination_tax_id_number} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>Razão Social: </Text>
            </Box>
            <Box w="54%">
              <TextInput isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true} fontSize={'13px'} textAlign={'right'} onChangeText={set_destination_razao_social} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>Cidade: </Text>
            </Box>
            <Box w="54%">
              <TextInput isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true} fontSize={'13px'} textAlign={'right'} onChangeText={set_destination_city} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>Estado: </Text>
            </Box>
            <Box w="54%">
              <TextInput isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true} fontSize={'13px'} textAlign={'right'} onChangeText={set_destination_state_or_province} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
            <Box w="45%">
              <Text>Motivo:</Text>
            </Box>
            <Box w="54%" _web={{ minW: 500 }}>
              <Select
                selectedValue={reason}
                minWidth="150"
                placeholder="Todos"
                _selectedItem={{
                  bg: "teal.600",
                }}
                onValueChange={(itemValue) => set_reason(itemValue)}
                isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true}
              >
                {_options.map(item => (
                  <Select.Item key={item.value} label={item.label} value={item.value} />
                ))}
              </Select>
            </Box>
          </Stack>
          <Divider />

          <Stack direction="column" justifyContent="space-between" p={2} space={1} >
            <Box w="45%">
              <Text>Justificativa: </Text>
            </Box>
            <Box w="100%">
              <TextInput isDisabled={_movimentHeaderCDate.status === "Em Preenchimento" ? false : true} multiline={true} fontSize={'13px'} textAlign={'left'} textAlignVertical={'top'} h={140} onChangeText={set_justification} />
            </Box>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>

            <Box w="45%">
              <Text>Anexo: </Text>
            </Box>
            {files ? <Image
              source={Union}
              alt="Update provider icon"
              resizeMode="contain"
              size='15px'
            /> : ''}
            <Box>
              <Pressable onPress={uploadFile}>
                <Icon
                  as={FontAwesome}
                  name="pencil-square-o"
                  size={8}
                  color={colors.success[50]}
                />
              </Pressable>
            </Box>


          </Stack>
          {_image && (
            <Stack direction="row" justifyContent="center" p={2} space={1} alignItems='center'>
              <Box w="45%">
                <Image source={{ uri: _image.uri }} style={{ width: 200, height: 200 }} />
              </Box>
            </Stack>

          )}
          <Divider />


        </ScrollView>


        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-between" }}
        >
          {
            accountType === "ga" && _movimentHeaderCDate.status === "Solicitado" ? (
              <>
                <Button
                  _web={{ w: 400 }}
                  w="48%"
                  label="VER SOLICITAÇÃO"
                  onPress={() => navigateTo('MovimentApprovedScreen')}
                />
                <Button
                  _web={{ w: 400 }}
                  w="48%"
                  label="Autorizar"
                  onPress={() => updateMovementHeader(3)}
                />
                <Button
                  _web={{ w: 400 }}
                  w="48%"
                  label="Não Autorizar"
                  onPress={() => updateMovementHeader(5)}
                />
              </>
            ) : _movimentHeaderCDate.status === "Em Preenchimento" ? (
              <>
                <Button
                  _web={{ w: 500 }}
                  w="48%"
                  label="VER SOLICITAÇÃO"
                  onPress={() => navigateTo('MovimentApprovedScreen')}
                />
                <Button
                  _web={{ w: 500 }}
                  w="48%"
                  label="GRAVAR"
                  onPress={() => finishSummary()}
                />
              </>
            ) : (
              <>
                <Button
                  _web={{ w: 500 }}

                  w="48%"
                  label="VER SOLICITAÇÃO"
                  onPress={() => navigateTo('MovimentApprovedScreen')}
                />
                <Button
                  _web={{ w: 500 }}
                  isDisabled
                  w="48%"
                  label="GRAVAR"
                  onPress={() => { }}
                />
              </>
            )
          }
        </Stack>
        <Spinner visible={isLoading} />
      </View>
    </>
  );
};
