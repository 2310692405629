import React from "react";
import { Divider, TemplateWebPages } from "../../components";
import { Box, Center, Stack, Text, VStack, useTheme } from "native-base";
import { platformWeb } from "../../utils";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { ParamListBase } from "@react-navigation/native";
import { ReportSelect } from "./components/ReportSelect";

export const ReportsScreen = ({ navigation }: DrawerScreenProps<ParamListBase>) => {
  const { colors } = useTheme();
  const [currentReport, setCurrentReport] = React.useState<string>();

  return (
    <VStack flex={1}>
      <TemplateWebPages navigation={navigation}>
        <Center>
          <VStack
            px={4}
            bg="#fff"
            _web={{
              display: "flex",
              bg: colors.primary[100],
              w: "80%",
              px: 10,
              py: 50,
              borderRadius: 10,
            }}
          >
            <Stack direction="row" pb={2} space={3} alignItems="center">
              <Box>
                <Text>Selecione um relatório:</Text>
              </Box>
              <Box
                maxW={platformWeb ? "60%" : "240"}
                minW={platformWeb ? "60%" : "240"}
              >
                <ReportSelect report={currentReport} setReport={setCurrentReport} />
              </Box>
            </Stack>
            <Box pb={2}>
              <Divider />
            </Box>
          </VStack>
        </Center>
      </TemplateWebPages>
    </VStack>
  );
};