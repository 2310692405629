export enum Pages {
    Inventario = "Inventario",
    EvolucaoDoInventario = "Evolução do Inventário",
    EstadoAtualDoInventario = "Estado Atual do Inventário",
}

export enum StatusColors {
    CAF_Duplicado = "CAF duplicado",
    CAF_NaoEstaNaBase = "CAF não está na base",
    CAF_NaoLocalizado = "CAF não localizado",
    CAF_Terceirizado = "CAF terceirizado",
    ComodatarioTransferido = "Comodatário transferido -",
    DesinvestidoNoAno = "Desinvestido no ano",
    Planejado = "Planejado",
    Realizado = "Realizado",
    Autorizado = "Autorizado",
    EmPreenchimento = "Em Preenchimento",
    NaoAutorizado = "Não Autorizado",
    Solicitado = "Solicitado",
    Completo = "Completo",
    Pendente = "Pendente",
    Enviada = "Enviada",
    Lida = "Lida",
}

export enum ChartTitles {
    Inventory = "inventory",
    Movement = "movement",
    Disinvestment = "disinvestiment",
    Communication = "communication",
    Retooling = "retooling",
}