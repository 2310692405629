import {
  Divider,
  InputDate,
  Button,
} from "../../../../components";
import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  FlatList,
  Select,
} from "native-base";


import { Modal, Pressable, StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { formatDate, platformWeb } from "../../../../utils";
import { useContext, useEffect, useState } from "react";
import movimentHook from "../../hook/useMoviment";
import homeScreenHooks from "./../../../../screens/Home/hooks/homeScreen";
import moment from "moment";
import { useGlobalContext } from "./../../../../contexts/globalContext";
import Spinner from 'react-native-loading-spinner-overlay';
import { useNavigatorContext } from "./../../../../contexts/navigatorContext";
import { useSupplierContext } from "../../../../contexts";


export const Content = () => {
  const { colors } = useTheme();
  const { navigateTo } = useNavigatorContext();
  const { getHeaderStatusMoviment, _headerStatus, getListMoviment, _movimentList, postHeaderMoviment, isLoading } = movimentHook()
  const [status, setStatus] = useState<string>('');
  const { currentSupplier } = useSupplierContext();
  const { UpdateMovimentHeaderHeaderCDate, _flatListMoviment, _setListMoviment, _movimentHeaderCDate, _flatListMoviment1 } = useGlobalContext();
  const _selectOptions = [{ id: 0, name: 'Todos' }, ..._headerStatus]

  const [solicitando, setSolicitando] = useState<boolean>(true)
  const [modalVisible, setModalVisible] = useState(false);
  const [_selectStatus, _setSelectStatus] = useState<any>('Todos');
  const [_selectStatusSet, _setSelectStatusSet] = useState<any>({ "id": 0, "name": "Todos" });
  const [_initialDate, _setInitialDate] = useState();
  const [_finalDate, _setFinalDate] = useState();
  const [_movimentFiltered, _setMovimentFiltered] = useState();

  useEffect(() => {
    getHeaderStatusMoviment();
    getListMoviment(currentSupplier);
  }, [currentSupplier, _flatListMoviment1])

  console.log(_flatListMoviment1)

  function attSelectStatus(item: any) {
    _setSelectStatusSet(_selectOptions[item])
    _setSelectStatus(_selectOptions[item].id)
  }

  function handleRedirectPage(item: any) {
    console.log('Item --->', item);
    UpdateMovimentHeaderHeaderCDate(item);
    return navigateTo("MovimentApprovedScreen");
  }

  function filterByDate(_movimentList: any, _initialDate: any, _finalDate: any) {
    if (!_initialDate && !_finalDate) {
      return _movimentList;
    }
    if (!_initialDate || !_finalDate) {
      return _movimentList;
    }
    const initialDate = moment(_initialDate, 'DD/MM/YYYY');
    const finalDate = moment(_finalDate, 'DD/MM/YYYY');
    const filteredRecords = _movimentList.filter(record => {
      const date = moment(record.creation_date);
      const isAfterInitial = date.isSameOrAfter(initialDate.startOf('day'));
      const isBeforeFinal = date.isSameOrBefore(finalDate.endOf('day'));
      return isAfterInitial && isBeforeFinal;
    });
    return filteredRecords;
  }

  function filterByStatus(_movimentList: any, _selectStatusSet: any) {
    if (_selectStatusSet.name === "Todos") {
      return _movimentList;
    } else {
      const filteredRecords = _movimentList.filter(record => {
        return record.status === _selectStatusSet.name;
      });
      return filteredRecords;
    }
  }

  function intersection(array1: any, array2: any) {
    return array1.filter(value => array2.includes(value));
  }

  function _searchMoviment() {
    const filteredRecordsDate = filterByDate(_movimentList, _initialDate, _finalDate);
    const filteredRecordsStatus = filterByStatus(_movimentList, _selectStatusSet);

    const result = intersection(filteredRecordsStatus, filteredRecordsDate);
    _setMovimentFiltered(result);
  }

  function requestMoviment() {

    if (_movimentList.some(item => item.movement_order_header_status_id === 1)) {
      setModalVisible(true)
    } else {
      postHeaderMoviment(currentSupplier)
      navigateTo("MovimentApprovedScreen");
    }
  }

  function modalManter() {
    setModalVisible(false)

  }

  function modalCriar() {
    setModalVisible(false)
    postHeaderMoviment(currentSupplier)
  }

  return (
    <>
      <VStack flex={1} _web={{ px: 20, w: "100%" }} bg="#fff">
        <Stack
          direction="row"
          space={1}
          alignItems="center"
          px={1}
          py={1}
          _web={{ pt: 20, pb: 5, w: '100%', justifyContent: 'space-around' }}
        >

          <Stack direction="column" _web={{ direction: 'row' }}>
            <Stack
              direction="row"
              space={1}
              alignItems="center"
            >
              <Text w={20} _web={{ w: 40 }} >Selecione o Status:</Text>

              <Select
                _web={{ w: '150%' }}
                selectedValue={_selectStatus}
                minWidth="150"
                placeholder="Todos"
                _selectedItem={{
                  bg: "teal.600",
                }}
                onValueChange={(itemValue) => attSelectStatus(itemValue)}
              >
                {_selectOptions.map(item => (
                  <Select.Item key={item.id} label={item.name} value={item.id} />
                ))}

              </Select>

            </Stack>

            <Stack
              direction="row"
              space={2}
              pt={3}
              alignItems="center"
              _web={{ justifyContent: "space-between" }}
            >
              <Box w={120}>
                <InputDate width={80} name="initialDate" placeholder="DD/MM/AA" />
              </Box>
              <Box w={120}>
                <InputDate width={80} name="finalDate" placeholder="DD/MM/AA" />
              </Box>
            </Stack>
          </Stack>

          <Box>
            <Button
              label="Pesquisar"
              onPress={() => _searchMoviment()}
            />
          </Box>

        </Stack>

        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          _web={{ w: "100%" }}
        >
          <Box minWidth={20} _web={{ w: 100 }}>
            <Text color={colors.white}>Data</Text>
          </Box>
          <Box minWidth={160} _web={{ w: 60 }}>
            <Text color={colors.white}>Status</Text>
          </Box>
          <Box minWidth={38} _web={{ w: 100 }}>
            <Text color={colors.white}>Qtd</Text>
          </Box>
          <Box minWidth={35} _web={{ w: 100 }}>
            <Text color={colors.white}>Id</Text>
          </Box>
        </Stack>
        <FlatList
          extraData={_flatListMoviment1}
          nestedScrollEnabled
          data={_movimentFiltered ? _movimentFiltered : _movimentList}
          contentContainerStyle={{
            marginBottom: !platformWeb ? 100 : 10,
          }}
          renderItem={({ item, index }) => {
            return (
              <Pressable onPress={() => handleRedirectPage(item)}>
                <Box px={4} py={2} _web={{ px: 1 }}>
                  <VStack direction="row" space={2}>
                    <Box minWidth={20} _web={{ w: 110 }}>
                      <Text>{formatDate(item?.creation_date)}</Text>
                    </Box>
                    <Box minWidth={160} _web={{ w: 640 }}>
                      <Text>{item?.status}</Text>
                    </Box>
                    <Box minWidth={30} _web={{ w: 90 }}>
                      <Text>{item?.qty}</Text>
                    </Box>
                    <Box minWidth={45} _web={{ w: 90 }}>
                      <Text>{item?.id}</Text>
                    </Box>
                  </VStack>
                  <Box mt={3}>
                    <Divider />
                  </Box>
                </Box>
              </Pressable>
            );
          }}
        />

        <Stack px={10} py={2} /*_web={{position: 'fixed', bottom: 0, left: '50%'}}*/>
          <Button
            label="SOLICITAR MOVIMENTAÇÃO"
            onPress={() => requestMoviment()}
          />
        </Stack>
      </VStack>


      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Já existe uma solicitação</Text>
            <Text style={styles.modalText}>"EM PREENCHIMENTO".</Text>
            <Text style={styles.modalText}>Deseja criar uma nova?</Text>
            <View style={styles.buttons}>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => modalManter()}>
                <Text style={styles.textStyle}>Manter Atual</Text>
              </Pressable>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => modalCriar()}>
                <Text style={styles.textStyle}>Criar Nova</Text>
              </Pressable>
            </View>

          </View>
        </View>
      </Modal>
      <Spinner visible={isLoading} />
    </>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    paddingLeft: 20,
    paddingRight: 20,
    padding: 10,
    elevation: 2,
    marginLeft: 10,
    marginRight: 10,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  }
});