import { MovementSelectScreen, NewMovementItemListScreen, NewMovementItemSummaryScreen, NewMovementScreen } from "../screens";
import { createStackNavigator } from "@react-navigation/stack";

const MovementStack = createStackNavigator();

export const MovementNavigator = () => {
  return (
    <MovementStack.Navigator id="MovementStack" screenOptions={{ headerShown: false, }}>
      <MovementStack.Screen
        name="MovementScreen"
        component={NewMovementScreen}
      />
      <MovementStack.Screen
        name="MovementItemListScreen"
        component={NewMovementItemListScreen}
      />
      <MovementStack.Screen
        name="MovementSelectItemListScreen"
        component={MovementSelectScreen}
      />

      {/* <MovementStack.Screen
        name="MovementItemDetailScreen"
        component={NewMovementItemDetailScreen}
      /> */}
      <MovementStack.Screen
        name="MovementItemSummaryScreen"
        component={NewMovementItemSummaryScreen}
      />
    </MovementStack.Navigator>
  );
};
