import { FormControl, HStack, Input, Pressable, Text, Toast, VStack, View, useTheme } from "native-base";
import { useState } from "react";
import { Modal } from "react-native";
import { Button } from "../Button";
import { StyleSheet } from "react-native";
import { resetPassword } from "../../services/authService";
import Spinner from "react-native-loading-spinner-overlay";

interface IForgotPasswordModalProps {
  visible: boolean;
  onClose: () => void;
  initialEmail?: string;
}

export const ForgotPasswordModal = ({ visible, onClose, initialEmail }: IForgotPasswordModalProps) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { colors } = useTheme();

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      await resetPassword(email);
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
    Toast.show({
      title: "E-mail de recuperação enviado",
      variant: "solid",
      backgroundColor: colors.primary[50],
      style: { padding: 30 },
      description: "Um e-mail com instruções foi enviado. Por favor, verifique sua caixa de entrada.",
      placement: "top"
    })

    onClose();
  };

  return (
    <>
      <Modal visible={visible} animationType="slide" transparent>
        {/* <Pressable onPress={onClose} style={{ flex: 1 }}> */}
        <VStack style={styles.modalContainer}>
          <VStack
            backgroundColor={'white'}
            padding={5}
            borderRadius={10}
            width='80%'
            justifyContent='center'
            alignItems='center'
            space={2}
            height={300}
            _web={{ w: '40%' }}
          >
            <Text
              fontSize={18}
              fontWeight='bold'
              textAlign='center'
              marginBottom={10}
              width='100%'
            >
              Esqueci Minha Senha
            </Text>

            <FormControl mb={3}>
              <FormControl.Label _text={{ color: colors.primary[50], fontWeight: 700, fontSize: 20 }}>E-mail</FormControl.Label>
              <Input
                placeholder="Digite seu e-mail"
                onChangeText={(text) => setEmail(text)}
                color={colors.primary[50]}
                fontWeight={700}
                fontSize={16}
                borderRadius={10}
                w={'100%'}
                mb={3}
                defaultValue={initialEmail}
              />
            </FormControl>

            <HStack alignItems={'center'} justifyContent={'space-between'} flex={1} w={'100%'}>
              <Button label='CANCELAR' onPress={onClose} w={'45%'}
              />
              <Button
                label={'ENVIAR'}
                w={'45%'}
                onPress={handleResetPassword} />
            </HStack>

          </VStack>
        </VStack>
        {/* </Pressable> */}
      </Modal >

      <Spinner visible={loading} />
    </>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
  },
});