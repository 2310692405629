import { ReactNode, createContext, useContext, useState } from "react";

interface IselectedRetoolingItens {
    id: number;
    asset_number: string;
    asset_description: string;
}

interface ItensListProviderProps {
    children: ReactNode;
}

const RetoolingContext = createContext({} as any);

const RetoolingContextProvider = ({ children }: ItensListProviderProps) => {
    const [selectedRetoolingItens, setSelectedRetoolingItens] = useState<IselectedRetoolingItens[]>([]);

    return (
        <RetoolingContext.Provider value={{ selectedRetoolingItens, setSelectedRetoolingItens }}>
            {children}
        </RetoolingContext.Provider>
    )
}

const useRetoolingContext = () => {
    return useContext(RetoolingContext);
}

export { RetoolingContextProvider, useRetoolingContext }