import React from "react";
import { Select } from "native-base";
import { IReportItem, getReportsService } from "../../../../services/reportService";

interface IReportSelectProps { 
  report: string | undefined, 
  setReport: React.Dispatch<React.SetStateAction<string | undefined>> 
}

export const ReportSelect = ({ report, setReport }: IReportSelectProps) => {
  const [reportList, setReportList] = React.useState<IReportItem[]>();

  React.useEffect(() => {
    async function getReportList() {
      const data = await getReportsService();
      if (data?.status) setReportList(data?.result);
    }

    getReportList();
  }, []);

  return (
    <Select
      selectedValue={report}
      minWidth="150"
      height={8}
      accessibilityLabel="Selecione um fornecedor"
      placeholder="Selecione"
      _selectedItem={{
        bg: "teal.600",
      }}
      mt={1}
      onValueChange={(itemValue) => setReport(itemValue)}
    >
      {reportList?.map((item) => (
        <Select.Item
          key={item?.id}
          label={item?.name}
          value={`${item?.id}`}
        />
      ))}
    </Select>
  );
};
