import { BottomNavigation, TemplateWebPages } from "../../components";
import { Center, VStack } from "native-base";
import { platformWeb } from "../../utils";
import { Content } from "./Components";
import { FormContextProvider, NavigatorContextProvider } from "../../contexts";
import { ParamListBase } from "@react-navigation/native";
import { DrawerNavigationProp, DrawerScreenProps } from "@react-navigation/drawer";

export const ActivesDetails = ({
  navigation,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  return (
    <>
      <VStack flex={1}>
        {platformWeb ? (
          <TemplateWebPages navigation={navigation}>
            <Center>
              <Content navigation={navigation}/>
            </Center>
          </TemplateWebPages>
        ) : (
          <>
            <Content navigation={navigation}/>
            <BottomNavigation navigation={navigation}/>
          </>
        )}
      </VStack>
    </>
  );
};

export const ActivesDetailsScreen = ({ navigation }: DrawerScreenProps<ParamListBase>) => {
  return (
      <FormContextProvider>
        <ActivesDetails navigation={navigation} />
      </FormContextProvider>
  );
};
