import Axios from "axios";
import { api } from "./api";
import * as FileSystem from "expo-file-system";
export const getInventoryService = async (supplier_id: any) => {
  return api
    .get(`/v1/inventory_status?supplier_id=${supplier_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("erro na chamada", error);
      throw error;
    });
};

export const getInventoryItemsService = async (
  id: number,
  status: string,
  limit: number,
  offset?: number,
  searchTerm?: string
) => {
  try {
    const response = await api.get(`/v1/inventory/${id}?status=${status}&limit=${limit}&offset=${offset}&filter=${searchTerm}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putInventoryItemService = (id: number, status_id: number) => {
  return api
    .put(`/v1/inventory_item/${id}`, {
      inventory_order_detail_status_id: status_id,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const putInventoryClosureFinish = (id: number) => {
  return api
    .put(`/v1/inventory/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
};

export async function putInventoryClose(id: number) {
  return api
    .put(`/v1/inventory_close/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function createInventoryItem(
  inventoryId: number,
  assetNumber: string
) {
  return api
    .post(`/v1/inventory_item`, {
      inventory_order_header_id: inventoryId,
      asset_number: assetNumber,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function deleteInventoryItem(inventoryItemId: number) {
  return api
    .delete(`/v1/inventory_item/${inventoryItemId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export const getInventoryClosure = (_id: number) => {
  return api
    .get(`v1/inventory/summary/${_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getInventoryCodeService = (
  inventory_id: number,
  asset_number: string
) => {
  return api
    .get(`v1/inventory/${inventory_id}/${asset_number}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postInventoryGenerateUrls = (item_id: number) => {
  return api
    .post(`v1/inventory_item/generate_signed_urls`, {
      inventory_order_detail_id: item_id,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getInventoryGenerateUrls = (item_id: number, asset_id: number) => {
  return api
    .post(`v1/inventory_item/get_signed_urls`, {
      inventory_order_detail_id: item_id,
      asset_id: asset_id
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const uploadFile = async (presignedUrl: string, file: any) => {
  const result = await FileSystem.uploadAsync(presignedUrl, file, {
    fieldName: "file",
    httpMethod: "PUT",
    uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
  });
  return result;
};

export const putInventorySend = (
  _inventoryEnterCode: any,
  photos: any,
  data: any
) => {
  const config = {
    inventory_order_detail_status_id: 2,
    inventory_number_imo_osf: data.inventory_number_imo_osf,
    equipment_base_condition_id: data.equipment_base_condition_id,
    production_status: data.production_status,
    number_of_cycles_performed: data.number_of_cycles_performed,
    there_is_label_y_n: data.there_is_label_y_n,
    lat: data.lat,
    lng: data.lng,
    photos: photos,
  };

  console.log("fotos", config);
  console.log("fotos", _inventoryEnterCode);
  return api
    .put(`v1/inventory_item/${_inventoryEnterCode}`, config)
    .then((response) => {
      console.log("finaly", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
