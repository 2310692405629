import { BottomNavigation, TemplateWebPages } from "../../components";
import { VStack } from "native-base";
import { platformWeb } from "../../utils";
import { Content } from "./Components/Content";
import { FormContextProvider, NavigatorContextProvider } from "../../contexts";
import { ParamListBase } from "@react-navigation/native";
import { DrawerNavigationProp, DrawerScreenProps } from "@react-navigation/drawer";

export const RetoolingDetails = ({
  navigation,
}: {
  navigation: DrawerNavigationProp<ParamListBase>;
}) => {
  return (
    <>
      <VStack flex={1}>
        {platformWeb ? (
          <TemplateWebPages navigation={navigation}>
            <Content />
          </TemplateWebPages>
        ) : (
          <>
            <Content />
            <BottomNavigation navigation={navigation} />
          </>
        )}
      </VStack>
    </>
  );
};

export const RetoolingDetailsScreen = ({ navigation }: DrawerScreenProps<ParamListBase>) => {
  return (
    <NavigatorContextProvider>
      <FormContextProvider>
        <RetoolingDetails navigation={navigation} />
      </FormContextProvider>
    </NavigatorContextProvider>
  );
};
