import { Divider } from "../../../../components";
import { Box, Center, FlatList, Image, Stack, Text, View } from "native-base";
import { useCallback, useState } from "react";
import { useInventoryContext, useSupplierContext } from "../../../../contexts";
import { Modal, Pressable, StyleSheet } from "react-native";
import {
  CafNotFound,
  CancelRight,
  Disinvestment,
  Duplicated,
  NotIsBase,
  Third,
  Transfer,
} from "../../../../../assets/images";
import { DrawerItem, DrawerNavigationProp } from "@react-navigation/drawer";
import { getInventoryItemsService, putInventoryItemService } from "../../../../services/inventoryService";
import Spinner from "react-native-loading-spinner-overlay";

import { ParamListBase, RouteProp, useFocusEffect } from "@react-navigation/native";
import { CardItems } from "../CardItems";
import { SuccessModal } from "../../../../components/SuccessModal";
import { ModalAlert } from "@/components/ModalAlert";

interface IListItem {
  id: number;
  asset_number: string;
  asset_description: string;
  info_asset_number: string;
  property_of_origin: string;
  status: string;
}

const drawerOptions = {
  done: [
    {
      label: "Cancelar Registro de Inventário",
      icon: CancelRight,
      statusId: 1,
    },
  ],
  planned: [
    {
      label: "CAF não localizado",
      icon: CafNotFound,
      statusId: 3,
    },
    {
      label: "CAF duplicado",
      icon: Duplicated,
      statusId: 5,
    },
    {
      label: "CAF Terceirizado",
      icon: Third,
      statusId: 6,
    },
    {
      label: "Desinvestido no Ano",
      icon: Disinvestment,
      statusId: 7,
    },
    {
      label: "Comodatário Transferido",
      icon: Transfer,
      statusId: 8,
    },
  ],
  incomplete: [
    {
      label: "Voltar para Planejado",
      icon: CancelRight,
      statusId: 3,
    },
    {
      label: "CAF não localizado",
      icon: CafNotFound,
      statusId: 3,
    },
    {
      label: "CAF duplicado",
      icon: Duplicated,
      statusId: 5,
    },
    {
      label: "Desinvestido no Ano",
      icon: Disinvestment,
      statusId: 7,
    },
    {
      label: "Comodatário Transferido",
      icon: Transfer,
      statusId: 8,
    },
  ],
}

export const ItemList = ({ route, status, searchTerm, handleItemPress }: { route: RouteProp<ParamListBase, string>; status: 'done' | 'planned' | 'incomplete', searchTerm: string, handleItemPress: any }) => {
  const { inventoryData: inventoryStatusData, updateInventoryData, showAlert, setShowAlert } = useInventoryContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [inventoryListData, setInventoryListData] = useState<IListItem[]>([]);
  const { currentSupplier } = useSupplierContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showFinished, setShowFinished] = useState(false);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState<number>();

  function openModal(id: number) {
    setSelectedItemId(id);
    setModalVisible(true);
  }

  async function getInventoryItemListData(
    id: number,
    status: string,
    limit: number,
    offset: number
  ) {

    try {
      const inventoryList = await getInventoryItemsService(
        id,
        status,
        limit,
        offset,
        searchTerm
      );
      if (inventoryList.status) {
        return inventoryList.result;
      } else {
        return null;
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 400 && !currentSupplier) {
        setShowAlert(true);
      }
      return [];
    }
  }

  async function getFirstPage() {
    setIsLoading(true);
    const inventoryData = await getInventoryItemListData(
      inventoryStatusData?.id,
      status,
      limit,
      0
    );
    if (inventoryData) {
      setInventoryListData(inventoryData);
      setOffset(50);
      setIsLoading(false);
    }
  }

  async function getNextPage() {
    if (isLoading) return;
    setIsLoading(true);
    const inventoryList = await getInventoryItemListData(
      inventoryStatusData?.id,
      status,
      limit,
      offset
    );
    setInventoryListData([...inventoryListData, ...inventoryList]);
    setOffset(offset + limit);
    setIsLoading(false);
  }

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      getFirstPage();
    }, [inventoryStatusData, searchTerm, route])
  );

  const renderItem = ({ item }: { item: IListItem }) => {
    return (
      <>
        <Box px={4} py={2}>
          <CardItems {...item} openModal={() => openModal(item.id)} handleItemPress={handleItemPress} />
        </Box>
        <Divider />
      </>
    );
  };

  async function handleUpdateItem(statusId: number) {
    if (!selectedItemId) return;
    setIsLoading(true);
    const data = await putInventoryItemService(selectedItemId, statusId);
    if (data?.status) {
      setModalVisible(false);
      updateInventoryData();
    }
    setShowFinished(true);
    setIsLoading(false);
  }

  return (
    <View
      style={{
        backgroundColor: "#fff",
      }}
      flex={1}
    >
      <Divider />
      {inventoryStatusData !== null ? (
        <Box _web={{ h: 'lg' }} >
          <FlatList
            data={inventoryListData}
            renderItem={renderItem}
            onEndReached={getNextPage}
            onEndReachedThreshold={0.1}
            ListFooterComponent={isLoading ? <Spinner visible={isLoading} /> : null}
          />
        </Box>
      ) : (
        <Center flex={1}>
          <Text fontWeight="bold">
            Nenhuma informação de inventário encontrada!
          </Text>
        </Center>
      )}

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <Pressable
          style={styles.container}
          onPress={() => setModalVisible(false)}
        >
          <Pressable style={styles.subcontainer} onPress={() => { }}>
            {drawerOptions[status]?.map((item, index) => (
              <DrawerItem
                key={index}
                label={() => (
                  <Text fontWeight="bold" fontSize={13}>
                    {item?.label}
                  </Text>
                )}
                icon={() => (
                  <Image
                    source={item?.icon}
                    alt="Update provider icon"
                    resizeMode="center"
                    w={30}
                    h={30}
                  />
                )}
                onPress={() => handleUpdateItem(item.statusId)}
              />
            ))}
          </Pressable>
        </Pressable>
      </Modal>

      <SuccessModal isVisible={showFinished} onClose={() => setShowFinished(false)} />

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  subcontainer: {
    width: 290,
    minHeight: 160,
    backgroundColor: "#BFBFFF",
    display: "flex",
    justifyContent: "center",
    borderBottomLeftRadius: 22,
    borderTopLeftRadius: 22,
  },
  subcontainerinfos: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 30,
    alignItems: "center",
  },
  subcontainertext: {
    marginLeft: 9,
  },
  subtext: {
    fontWeight: "600",
    fontSize: 13,
  },
});
