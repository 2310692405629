import {
  Text,
  VStack,
  Stack,
  Box,
  useTheme,
  Center,
  FlatList,
  Checkbox,
  View,
  ScrollView,
} from "native-base";
import {
  Divider,
  Button,
  TextInputSearch,
  TextInput,
} from "../../../../components";
import { useAuthContext, useFormContext, useNavigatorContext } from "../../../../contexts";
import { useContext, useEffect, useState } from "react";
import { platformWeb } from "../../../../utils";
import { useGlobalContext } from "./../../../../contexts/globalContext";
import divestimentSummaryHook from "../../hook/useDivestimentSummary";

export const Content = () => {
  const { accountType } = useAuthContext();
  const { colors } = useTheme();
  const { navigateTo } = useNavigatorContext();
  const [contato, setContato] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [ativarBotao, setAtivarBotao] = useState<boolean>(true)
  const { _divestimentHeaderCDate } = useGlobalContext()
  const { getSummaryDivestiment, _itensSummary, putFinishDivestiment } = divestimentSummaryHook()
  const [_contato, _setContato] = useState<string>('')
  const [_email, _setEmail] = useState<string>('')

  useEffect(() => {
    if (_contato && _email) {
      setAtivarBotao(false)
    } else {
      setAtivarBotao(true)
    }
  }, [_contato, _email]);

  useEffect(() => {
    getSummaryDivestiment(_divestimentHeaderCDate.id)
  }, [])

  console.log(_itensSummary)

  function _finish() {
    const body = {
      contact_name: _contato,
      contact_email: _email,
      disinvestment_order_header_status_id: 2
    }
    putFinishDivestiment(_divestimentHeaderCDate.id, body)
  }

  function updateDivestmentHeader(statusId: number) {
    const body = {
      disinvestment_order_header_status_id: statusId,
    }
    putFinishDivestiment(_divestimentHeaderCDate.id, body)
  }


  const dateObject = new Date(_divestimentHeaderCDate.creation_date);
  const dia = dateObject.getDate();
  const mes = dateObject.getMonth() + 1; // Adiciona 1 pois o mês começa em 0 (janeiro) em JavaScript
  const ano = dateObject.getFullYear();
  const dataFormatada = `${dia}/${mes}/${ano}`;

  return (
    <>
      <View flex={1} _web={{ px: 10, w: "100%", mt: 50 }} bg="#fff">
        <Stack
          direction="row"
          space={2}
          alignItems="center"
          justifyContent="space-between"
          px={3}
          py={5}
        >
          <Text>{`${dataFormatada}`}  {`${_divestimentHeaderCDate.status}`}</Text>
          <Text>Id: {`${_divestimentHeaderCDate.id}`}</Text>
        </Stack>


        <Stack
          direction="row"
          space={2}
          alignItems="center"
          bg={colors.primary[50]}
          px={3}
          py={2}
          _web={{ mt: 50 }}
        >
          <Box minWidth={100} _web={{ w: "100%" }}>
            <Text color={colors.white}>Resumo da Ordem</Text>
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
          <Box w="45%">
            <Text>Valor Residual (Saldo) de Peças Total: </Text>
          </Box>
          <Box w="54%">
            <TextInput isDisabled fontSize={'13px'} textAlign={'right'} value={`R$ ${_itensSummary?.residual.toString()}`} />
          </Box>
        </Stack>
        <Divider />

        <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
          <Box w="45%">
            <Text>Quantidade total de itens na ordem: </Text>
          </Box>
          <Box w="54%">
            <TextInput isDisabled fontSize={'13px'} textAlign={'right'} value={`${_itensSummary?.total.toString()}`} />
          </Box>
        </Stack>
        <Divider />

        <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
          <Box w="45%">
            <Text>Contato para Acompanhar a Retirada: </Text>
          </Box>
          <Box w="54%">
            <TextInput fontSize={'13px'} textAlign={'right'} isRequired onChangeText={_setContato} />
          </Box>
        </Stack>
        <Divider />

        <Stack direction="row" justifyContent="space-between" p={2} space={1} alignItems='center'>
          <Box w="45%">
            <Text>E-mail do Contato Técnico: </Text>
          </Box>
          <Box w="54%">
            <TextInput fontSize={'13px'} textAlign={'right'} placeholder={"contato.tecnico@gmail.com"} onChangeText={_setEmail} isRequired />
          </Box>
        </Stack>
        <Divider />

        <ScrollView>

        </ScrollView>


        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          space={2}
          py={2}
          _web={{ justifyContent: "space-between" }}
        >
          {
            accountType === "ga" && _divestimentHeaderCDate.status === "Solicitado" ? (
              <>
                <Button
                  _web={{ w: 400 }}
                  w="48%"
                  label="VER SOLICITAÇÃO"
                  onPress={() => navigateTo('DivestmentApprovedScreen')}
                />
                <Button
                  _web={{ w: 400 }}
                  w="48%"
                  label="Autorizar"
                  onPress={() => updateDivestmentHeader(3)}
                />
                <Button
                  _web={{ w: 400 }}
                  w="48%"
                  label="Não Autorizar"
                  onPress={() => updateDivestmentHeader(5)}
                />
              </>
            ) : _divestimentHeaderCDate.status === "Em Preenchimento" ? (
              <>
                <Button
                  _web={{ w: 500 }}
                  w="48%"
                  label="VER SOLICITAÇÃO"
                  onPress={() => navigateTo('DivestmentApprovedScreen')}
                />
                <Button
                  _web={{ w: 500 }}
                  w="48%"
                  label="GRAVAR"
                  onPress={() => _finish()}
                />
              </>
            ) : (
              <>
                <Button
                  _web={{ w: 500 }}

                  w="48%"
                  label="VER SOLICITAÇÃO"
                  onPress={() => navigateTo('DivestmentApprovedScreen')}
                />
                <Button
                  _web={{ w: 500 }}
                  isDisabled
                  w="48%"
                  label="GRAVAR"
                  onPress={() => { }}
                />
              </>
            )
          }
        </Stack>
      </View>
    </>
  );
};

